(function () {
    'use strict';

    bindhq.nsIn('search.parser', {
        parseData: function (response) {
            if (response.hits) {
                return {
                    status: 'ok',
                    total: response.hits.total,
                    results: _.map(response.hits.hits, '_source'),
                };
            }

            return response;
        },
    });
})();
