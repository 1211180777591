(function () {
    'use strict';

    bindhq.nsIn('forms.files', {
        /**
         * @param {jQuery} container
         */
        addFile: function (container) {
            if (!container.attr('disabled')) {
                const inputs = $('.inputs', container);
                const id = $('input', inputs).length;
                const html = container
                    .data('prototype')
                    .replace(/__name__/g, id + 1);
                const item = $(html).removeAttr('required');

                inputs.append(item);
            }
        },

        /**
         * @param {jQuery.Event} evt
         */
        onDeleteClick: function (evt) {
            $(evt.currentTarget).parents('.file-input').fadeAndRemove();
        },

        /**
         * @param {jQuery} placeholder
         */
        onFileUploadError: function (placeholder) {
            const message =
                '<div class="alert alert-error">File upload failed</div>';

            placeholder
                .removeClass('loading')
                .html(message)
                .fadeAndRemoveTimed(3000);
        },

        /**
         * @param {jQuery} form
         *
         * @return {jQuery}
         */
        tagsFrom: function (form) {
            return $(form)
                .find('.select-tag')
                .map(function () {
                    return $(this).val();
                })
                .get();
        },

        /**
         * @param {jQuery} form
         *
         * @return {jQuery}
         */
        ownersFrom: function (form) {
            return $('.owners', form).val();
        },

        /**
         * @param {jQuery} form
         *
         * @return {String}
         */
        fileNameFrom: function (form) {
            const name = $(form).find('#file_name').val();

            const extension = $(form).find('#file_extension').html();

            return name + extension;
        },

        /**
         * @param {jQuery} container
         *
         * @return {jQuery}
         */
        filesFrom: function (container) {
            return container.hasClass('files')
                ? container
                : $('.files', container);
        },

        /**
         * @param {jQuery} container
         * @param {jQuery} form
         */
        onUploadSubmitted: function (container, form, modal) {
            if (form.valid()) {
                const files = this.filesFrom(container);
                const fileName = this.fileNameFrom(form);
                const tags = this.tagsFrom(form);

                $(form).find('#file_name').val(fileName);
                $(form).find('input[name="tags"]').val(tags.toString());

                $(modal).modal('hide');

                const filesContainer = container.hasClass('files')
                    ? container
                    : $('.files:first', container);

                const placeholder = bindhq.files.loaderFor(
                    filesContainer,
                    fileName,
                );

                const config = {
                    success: _.partial(
                        bindhq.files.uploadComplete,
                        placeholder,
                    ),
                    error: _.partial(this.onFileUploadError, placeholder),
                };

                files.append(placeholder).find('.empty, .no-results').remove();

                form.ajaxSubmit(config);

                this.reset(form);
            }
        },

        /**
         * @param {jQuery} form
         * @param {jQuery} modal
         * @param {jQuery} dropzone
         * @param {Object} file
         */
        onDropUploadSubmitted: function (form, modal, dropzone, file) {
            if (form.valid() && modal.length) {
                $(modal).modal('hide');

                const container = dropzone.hasClass('files')
                    ? dropzone
                    : $('.files:first', dropzone);

                const files = this.filesFrom(container);
                const fileName = this.fileNameFrom(form);
                const tags = this.tagsFrom(form);
                let owners = dropzone.data('owners');
                if (this.ownersFrom(form)) {
                    owners = owners.concat(',', this.ownersFrom(form));
                }

                const folder = $(form).find('select').val();
                tags.push(folder);

                const loader = bindhq.files
                    .loaderFor(container, fileName)
                    .appendTo(container);

                const config = {
                    action: dropzone.data('uploadurl'),
                    name: fileName,
                    owners: owners,
                    tags: tags,
                    template: dropzone.data('template'),
                    complete: function (xhr) {
                        bindhq.files.uploadComplete(loader, xhr.responseText);
                    },
                };

                files.find('.empty, .no-results').remove();

                bindhq.files.uploadFile(config, file);

                this.reset(form);
            }
        },

        /**
         * @param {String} form
         *
         * @return {String}
         */
        fileNameFor: function (form) {
            if ($('input[type="file"]', form).length) {
                const filePath = $('input[type="file"]', form).val();
                const lastSlashIndex = filePath.lastIndexOf('\\');

                return filePath.substring(lastSlashIndex + 1);
            }

            return '';
        },

        /**
         * @param {jQuery} form
         * @param {jQuery} tags
         * @param {jQuery} modal
         * @param {jQuery} dropFileName
         */
        showAttachForm: function (form, tags, modal, dropFileName) {
            const fileName = dropFileName || this.fileNameFor(form);
            const lastDotIndex = fileName.lastIndexOf('.');
            const extension = fileName.substr(lastDotIndex);
            const name = fileName.substr(0, lastDotIndex);

            $('#file_name', form).val(name);
            $('#file_extension', form).html(extension);

            $(modal).modal('show');
        },

        /**
         * @param {jQuery} container
         * @param {jQuery} form
         * @param {jQuery} modal
         */
        onAttachClick: function (container, form, modal) {
            modal
                .off('click')
                .on(
                    'click',
                    '#form-submit',
                    _.partial(this.onUploadSubmitted, container, form, modal),
                );

            $('[type="file"]', form).trigger('click');
        },

        /**
         * @param {jQuery} container
         * @param {jQuery} dropzone
         * @param {Object} file
         */
        onFileDrop: function (container, dropzone, file, modal) {
            const tags = container.data('tags') || '';
            const form = $('form', modal);

            bindhq.forms.files.showAttachForm(form, tags, modal, file.name);

            modal
                .off('click')
                .on('click', '[data-dismiss="modal"]', function () {
                    modal.modal('hide');
                })
                .on(
                    'click',
                    '#form-submit',
                    _.partial(
                        this.onDropUploadSubmitted,
                        form,
                        modal,
                        dropzone,
                        file,
                    ),
                );
        },

        /**
         * @param {jQuery} container
         */
        initAttachFile: function (container) {
            const wrapper = container.closest('.file-attach-wrapper');
            const owners = container.data('owners');
            const tags = container.data('tags') || '';
            const url = container.data('file-attach-url');
            const files = container.hasClass('files')
                ? container
                : $('.files', container);
            const placeAfter = $('.attach-area', container).length
                ? $('.attach-area', container)
                : files;
            const modalAttachFiles = $(
                '.modal-attach-files',
                wrapper.get(0) || container.parent(),
            );
            const modal = modalAttachFiles.closest('.modal');
            const form = $('form', modal).attr('action', url);

            $('.select-folder', form).select2({
                placeholder: 'Select a folder...',
                allowClear: true,
            });
            $('select.select-types', form).select2({
                placeholder: 'Select tags...',
                allowClear: true,
            });

            $('<input type="file" name="file" class="hidden" />').appendTo(
                form,
            );

            $(
                '<input type="hidden" name="owners" value="' + owners + '" />',
            ).appendTo(form);

            $('<input type="hidden" name="tags" />').appendTo(form);

            $(
                '<input type="hidden" name="template" value="' +
                    container.data('template') +
                    '" />',
            ).appendTo(form);

            $('<a></a>')
                .addClass('btn btn-primary btn-attach')
                .html('<i class="fa fa-upload"></i> Upload | Drop here')
                .click(_.partial(this.onAttachClick, container, form, modal))
                .insertAfter(placeAfter)
                .wrapAll('<div class="btn-attach-wrap"></div>');

            form.on(
                'change',
                '[type="file"]',
                _.partial(this.showAttachForm, form, tags, modal, null),
            );
        },

        /**
         * @param {jQuery} container
         */
        updateAttachFileOwners: function (container) {
            const wrapper = container.closest('.file-attach-wrapper');
            const owners = container.data('owners');
            const modalAttachFiles = $(
                '.modal',
                wrapper.get(0) || container.parent(),
            );
            const modal = modalAttachFiles.closest('.modal');
            const form = $('form', modal);

            $('input[name="owners"]', form).val(owners);
        },

        /**
         * @param {jQuery} container
         */
        initAddFile: function (container) {
            if (container.attr('disabled')) {
                return;
            }

            const onClick = _.partial(this.addFile, container);

            $('<a></a>')
                .addClass('btn btn-add-file fileviewer-add')
                .addClass(container.data('add-file-classes') || 'btn-small')
                .html(container.data('add-file-text') || 'Add File')
                .click(onClick)
                .insertAfter(container)
                .wrapAll('<div class="btn-add-file-wrap"></div>');

            container.on(
                'click',
                '.delete',
                bindhq.util.noDefault(this.onDeleteClick),
            );
        },

        /**
         * @param {jQuery} container
         */
        addTaggableFile: function (container) {
            if (!container.attr('disabled')) {
                const inputs = $('.inputs', container);
                const id = $('.file', inputs).length;
                const html = container
                    .data('prototype')
                    .replace(/__name__/g, id + 1);
                const item = $(html).removeAttr('required');

                inputs.append(item);

                $('select.select-folder', container).select2({
                    placeholder: 'Select a folder...',
                    allowClear: true,
                });
                $('select.select-types', container).select2({
                    placeholder: 'Select tags...',
                    allowClear: true,
                });

                container.trigger('files:collection:row_added');
            }
        },

        /**
         * @param {jQuery} form
         */
        reset: function (form) {
            form.get(0).reset();
            form.find('select').select2('val', null);
        },

        /**
         * @param {jQuery} container
         */
        initAddTaggableFile: function (container) {
            if (container.attr('disabled')) {
                return;
            }

            const onClick = _.partial(this.addTaggableFile, container);

            $('<a></a>')
                .addClass('btn btn-small btn-add-file fileviewer-add')
                .html('Add File')
                .click(onClick)
                .insertAfter(container)
                .wrapAll('<div class="btn-add-file-wrap"></div>');

            container.on(
                'click',
                '.delete',
                bindhq.util.noDefault(this.onDeleteClick),
            );
        },
    });
})();
