(function () {
    'use strict';

    bindhq.nsIn('scroller', {
        /**
         * @param {jQuery} container
         */
        initListener: function (container) {
            let initial = null;
            let initialWidth = null;
            let initialHeight = null;
            const scroller = container.is('thead')
                ? this.makeTableScroller(container)
                : container;
            const $window = $(window);

            const onScroll = function () {
                const scrollTop = $(document).scrollTop();

                initial = initial || container.offset();

                if (scrollTop + 60 > initial.top) {
                    initialWidth = initialWidth || container.width();
                    initialHeight = initialHeight || container.height();

                    let scrollableHeight;

                    // make sure that there is enough room for the scrollable
                    const verticalSpaceInView = $window.height() - 60;
                    if (initialHeight > verticalSpaceInView) {
                        scrollableHeight = verticalSpaceInView;
                    } else {
                        scrollableHeight = initialHeight;
                    }

                    if (initialHeight > 0) {
                        const heightCss = { height: scrollableHeight + 'px' };

                        scroller.css(heightCss);

                        if (!scroller.is('table')) {
                            scroller.parent().css(heightCss);
                        }
                    }

                    if (initialWidth > 0) {
                        const widthCss = { width: initialWidth + 'px' };

                        scroller.css(widthCss);
                    }

                    scroller.addClass('scroller-scrolling');
                } else {
                    scroller
                        .removeClass('scroller-scrolling')
                        .css({ width: null });
                }
            };

            $window.scroll(onScroll);

            if (container.is(':visible')) {
                onScroll();
            }
        },

        /**
         * @param {jQuery} container
         *
         * @return {jQuery}
         */
        makeTableScroller: function (container) {
            const scroller = container
                .clone()
                .find('tr')
                .each(function (trIndex, tr) {
                    $('td', tr).each(function (tdIndex, td) {
                        const nthTrIndex = trIndex + 1;
                        const nthTdIndex = tdIndex + 1;
                        const mirrored = $(
                            'tr:nth-child(' +
                                nthTrIndex +
                                ') td:nth-child(' +
                                nthTdIndex +
                                ')',
                            container,
                        );

                        $(td).width(mirrored.width());
                    });
                })
                .end();

            return $('<table></table>')
                .addClass('scroller-table')
                .append(scroller)
                .insertBefore(container.closest('table'));
        },

        /**
         * @param {jQuery} container
         */
        init: function (container) {
            this.initListener(container);
        },
    });
})();
