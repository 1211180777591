(function () {
    'use strict';

    bindhq.ns('forms.email');

    bindhq.forms.email.onKeyup = function (e) {
        const target = e.currentTarget;
        const a = $(target).parent().find('a.email');

        a.attr('href', 'mailto:' + target.value);
    };
})();
