(function () {
    'use strict';

    bindhq.ns('tabs');

    /**
     * @param {String} tabId
     *
     * @return {boolean}
     */
    bindhq.tabs.isVisible = function (tabId) {
        return $('#' + tabId + ':visible').length > 0;
    };

    /**
     * @param {String} tabId
     *
     * @return {jQuery}
     */
    bindhq.tabs.anchorFor = function (tabId) {
        return $('[href="#' + tabId + '"]');
    };

    /**
     * @param {jQuery} anchor
     *
     * @return {boolean}
     */
    bindhq.tabs.isLoaded = function (anchor) {
        return anchor.data('contentloaded');
    };

    /**
     * @param {String} tabId
     *
     * @return {boolean}
     */
    bindhq.tabs.exists = function (tabId) {
        return bindhq.tabs.anchorFor(tabId).length > 0;
    };

    /**
     * @param {String} tabId
     *
     * @return {Function}
     */
    bindhq.tabs.refresher = function (tabId) {
        return function () {
            const anchor = bindhq.tabs.anchorFor(tabId);

            if (bindhq.tabs.isLoaded(anchor)) {
                bindhq.tabs.refresh(tabId);
            }
        };
    };

    /**
     * @param {String} tabId
     */
    bindhq.tabs.refresh = function (tabId) {
        const anchor = bindhq.tabs.anchorFor(tabId);
        const contentUrl = anchor.data('contenturl');

        bindhq.tabs.loadTabFor(anchor, contentUrl);
    };

    /**
     * @param {Array} tabIds
     */
    bindhq.tabs.refreshAll = function (tabIds) {
        _.each(tabIds, bindhq.tabs.refresh);
    };

    /**
     * @param {jQuery} content
     */
    bindhq.tabs.extractButtons = function (content) {
        const buttons = $('.btn-nav-tabs', content).detach();

        if (buttons.length > 0) {
            content.closest('.tab-pane').data('buttons', buttons);
        }
    };

    /**
     * @param {jQuery} content
     */
    bindhq.tabs.updateButtons = function (content) {
        const pane = content.hasClass('tab-pane')
            ? content
            : content.closest('.tab-pane');

        if (pane.is(':visible')) {
            const buttons = pane.data('buttons');

            const container = pane.find('.nav-tabs-buttons-dynamic');

            container.find('> *').detach();

            container.append(buttons);
        }
    };

    /**
     * @param {jQuery} content
     * @param {Function} callback
     * @param {String} html
     */
    bindhq.tabs.onContentSuccess = function (content, callback, html) {
        const element = $('<span>' + html + '</span>');

        content.empty().append(element);

        bindhq.initContainer(content);

        bindhq.tabs.extractButtons(element);
        bindhq.tabs.updateButtons(element);

        if (callback) {
            callback(content);
        }
    };

    /**
     * @param {jQuery} content
     */
    bindhq.tabs.onContentError = function (content, xhr) {
        if (xhr.status === 403) {
            location.reload(); // user has been logged out
        } else {
            const message =
                '<div class="tab-content-failed">' +
                '<div class="alert alert-error">' +
                'Sorry, but there was an error loading this tab. Refresh the page to try again.' +
                '</div>' +
                '</div>';

            content.html(message);
        }
    };

    /**
     * @param {jQuery} content
     * @param {jQuery} anchor
     */
    bindhq.tabs.onContentComplete = function (content, anchor) {
        content.removeClass('tab-content-loading');

        anchor.data('contentloaded', !anchor.data('refreshcontent'));
    };

    /**
     * @param {jQuery} anchor
     */
    bindhq.tabs.getDataIfForm = function (anchor) {
        const form = anchor.closest('form');
        if (form.hasClass('form-tabs-dynamic')) {
            return form.serialize();
        } else {
            window.location.search.substring(1);
        }
    };

    /**
     * @param {jQuery} anchor
     * @param {String} contentUrl
     * @param {Function} callback
     */
    bindhq.tabs.loadTabFor = function (anchor, contentUrl, callback) {
        const self = bindhq.tabs;
        const hash = anchor.attr('href');
        const content = $(hash + ' .tab-content-dynamic');
        const config = {
            type: anchor.data('requesttype') || 'GET',
            url: contentUrl,
            data: bindhq.tabs.getDataIfForm(anchor),
            success: _.partial(self.onContentSuccess, content, callback),
            error: _.partial(self.onContentError, content),
            complete: _.partial(self.onContentComplete, content, anchor),
        };

        content
            .parent()
            .find('[class*="intab-"]')
            .remove()
            .end()
            .find('> *')
            .show();

        content
            .addClass('tab-content-loading')
            .empty()
            .html('Loading content...');

        $.ajax(config);
    };

    /**
     * @param {jQuery} element
     * @param {Function} callback
     */
    bindhq.tabs.showFor = function (element, callback) {
        const anchor = $(element);
        const href = anchor.attr('href');

        if (undefined === href || '#' !== href.substring(0, 1)) {
            return;
        }

        const content = $(href);
        const contentUrl = anchor.data('contenturl');

        $(anchor).tab('show');

        if (!contentUrl && content) {
            content.siblings().removeClass('active');
            content.addClass('active');
        }

        if (contentUrl && !bindhq.tabs.isLoaded(anchor)) {
            bindhq.tabs.loadTabFor(anchor, contentUrl, callback);
        } else if (callback) {
            bindhq.tabs.extractButtons(content);

            callback(content);
        } else {
            bindhq.tabs.extractButtons(content);
        }

        bindhq.tabs.updateButtons(content);
    };

    /**
     * @param {String} tabId
     * @param {Function} callback
     */
    bindhq.tabs.show = function (tabId, callback) {
        const self = bindhq.tabs;
        const element = self.anchorFor(tabId);

        self.showFor(element, callback);
    };

    bindhq.tabs.resize = function (container) {
        const totalSize = $(container).outerWidth();
        const items = $('li', container).not('.tab-sublist');
        const itemsWidth = items.outerWidth(true);
        const sublistTab = $('li.tab-sublist', container).get(0);

        if ($(container).width() > itemsWidth || sublistTab) {
            const tabs = bindhq.tabs.generateTree(items, totalSize);
            const html = bindhq.tabs.generateHtml(tabs);

            $(container).html(html);
            $(container).addClass('refactored-tabs');
            bindhq.tabs.initAnchors(container);
        }
    };

    bindhq.tabs.generateTree = function (items, totalSize) {
        let acc = 0;
        const more = [];
        const defaultWidth = 150;

        return _.reduce(
            items,
            function (tabs, item) {
                acc += $(item).outerWidth(true) || defaultWidth;
                if (more.length) {
                    more.push(item);
                } else if (acc + defaultWidth > totalSize) {
                    more.push(item);
                    tabs.push(more);
                } else {
                    tabs.push(item);
                }
                return tabs;
            },
            [],
        );
    };

    bindhq.tabs.generateHtml = function (tabs) {
        return _.map(tabs, function (item) {
            if ($.isArray(item) && item.length) {
                const more = $('<li></li>')
                    .addClass('tab-sublist')
                    .append(
                        $('<div></div>')
                            .addClass('btn-group')
                            .append(
                                $('<a></a>')
                                    .addClass('dropdown-toggle')
                                    .attr({ 'data-toggle': 'dropdown' })
                                    .html(
                                        'More <i class="fa fa-chevron-down"></i>',
                                    ),
                            )
                            .append($('<ul></ul>').addClass('dropdown-menu')),
                    );

                _.each(item, function (subitem) {
                    $('ul', more).append(subitem);

                    if ($(subitem).find('a').hasClass('active')) {
                        $(subitem).closest('.tab-sublist').addClass('active');
                    }
                });

                return more;
            } else {
                return item;
            }
        });
    };

    bindhq.tabs.initAnchors = function (container) {
        const items = $('li', container).not('.tab-sublist');
        $('a', items).click(function (e) {
            const element = $(e.currentTarget);
            const tabId = element.attr('href').substring(1);

            if (bindhq.tabs.exists(tabId)) {
                bindhq.tabs.show(tabId);
                bindhq.util.setHash(element.get(0).hash);

                e.preventDefault();
            }
        });

        $('a', items).on(bindhq.tabs.tabShownEvent(), function (e) {
            const tab = $(e.target);

            if (tab.closest('.refactored-tabs').length) {
                if (tab.closest('.tab-sublist').length) {
                    $('.tab-sublist li a').removeClass('active');
                    tab.addClass('active');
                    tab.closest('li.tab-sublist').addClass('active');
                } else {
                    $('.tab-sublist').removeClass('active');
                    $('.tab-sublist li a').removeClass('active');
                    tab.addClass('active');
                }
            }
        });
    };

    /**
     * @param {jQuery} container
     * @param {String} id
     * @param {Array} parts
     */
    bindhq.tabs.loadPart = function (container, id, parts) {
        const initial = $('li:visible a[href="' + id + '"]', container)
            .add('a:first', container)
            .last();

        bindhq.tabs.showFor(initial, function (content) {
            if (parts.length !== 0) {
                bindhq.tabs.loadPart(content, '#' + parts[0], parts.slice(1));
            }
        });
    };

    bindhq.tabs.activeTabSelector = function () {
        return 'li a.active';
    };

    bindhq.tabs.tabShownEvent = function () {
        return 'shown.bs.tab';
    };

    bindhq.tabs.initGroupTab = function (container) {
        $(container).on(bindhq.tabs.tabShownEvent(), function (e) {
            $('li.active', container)
                .removeClass('active')
                .find('a')
                .removeClass('active');
            $(e.target).parent().addClass('active');
        });
    };

    /**
     * @param {JQuery} container
     */
    bindhq.tabs.initFromHash = function (container) {
        const hash = window.location.hash;

        if (!hash || hash.substring(1).split('/').length < 1) {
            return false;
        }

        const parts = hash.substring(1).split('/');

        if (!bindhq.tabs.exists(parts[0])) {
            return false;
        }

        bindhq.tabs.loadPart(container, '#' + parts[0], parts.slice(1));
        return true;
    };

    bindhq.tabs.init = function () {
        const container = this;

        bindhq.tabs.initAnchors(container);

        try {
            const active = $(bindhq.tabs.activeTabSelector(), container);
            active.parent().removeClass('active');
            active.removeClass('active');

            if (!bindhq.tabs.initFromHash(container)) {
                if (active.length > 0) {
                    bindhq.tabs.showFor(active);
                } else {
                    bindhq.tabs.loadPart(container, null, []);
                }
            }
        } catch (e) {
            console.error(e);
        }

        if (!$(container).hasClass('no-resize')) {
            bindhq.tabs.resize(container);
            const onResize = _.partial(bindhq.tabs.resize, container);
            $(window).on('resize', _.debounce(onResize, 200));
        }
    };
})();
