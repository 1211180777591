(function () {
    'use strict';

    bindhq.nsIn('forms.addresslookup', {
        /**
         * @param {jQuery} container
         */
        init: function (container) {
            bindhq.forms.ajaxselect.initContainer(container);

            container.on(
                'change',
                _.partial(this.onAddressSelected, container),
            );
        },

        /**
         * @param {jQuery} container
         * @param {Object} address
         */
        onSuccess: function (container, address) {
            container.trigger('address-details-found', [address]);
        },

        /**
         * @param {Object} jqXHR
         * @param {String} textStatus
         * @param {Error} errorThrown
         */
        onError: function (container, jqXHR, textStatus, errorThrown) {
            container.trigger('address-details-not-found', [
                jqXHR,
                textStatus,
                errorThrown,
            ]);

            console.error(jqXHR);
        },

        /**
         * @param {jQuery} container
         * @param {jQuery.Event} evt
         */
        onAddressSelected: function (container, evt) {
            const addressDetailUrl = container.data('address-detail-url');
            const item = evt.added;

            if (item && item.id) {
                $.ajax({
                    type: 'GET',
                    url: addressDetailUrl,
                    data: { placeId: item.id },
                    success: _.partial(this.onSuccess, container),
                    error: _.partial(this.onError, container),
                });
            } else {
                throw new Error('Expected response to contain address ID');
            }
        },
    });
})();
