class Affix {
    /**
     *
     * @param {jQuery} container
     */
    constructor(container, window) {
        this.container = container;
    }

    /**
     * @param {jQuery} affixElement
     * @param {Float} elemTop
     * @param {Float} elemLeft
     * @param {Float} elemWidth
     * @param {jQuery} scrollElem
     */
    toggle(affixElement, elemTop, elemLeft, elemWidth, scrollElem) {
        if (scrollElem.scrollTop() >= elemTop) {
            affixElement
                .addClass('affix')
                .width(elemWidth)
                .css({ left: elemLeft });
        } else {
            affixElement.removeClass('affix');
        }
    }

    init() {
        const self = this;

        $('[data-toggle="affix"]', this.container).each(function () {
            const elem = $(this);
            const elemTop = elem.offset().top - $('.navbar-fixed-top').height();
            const elemLeft = elem.offset().left;
            const elemWidth = elem.width();

            $(window).on('scroll resize', function () {
                self.toggle(elem, elemTop, elemLeft, elemWidth, $(this));
            });

            self.toggle(elem, elemTop, elemLeft, elemWidth, $(window));
        });
    }
}

module.exports = { Affix };
