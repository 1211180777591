(function () {
    'use strict';

    bindhq.nsIn('abacus.audit', {
        /**
         * @param {int}
         */
        MIN_RESULTS: 20,

        /**
         * @param {int}
         */
        ITEMS_PER_PAGE: 100,

        /**
         * @param {int}
         */
        MAX_RELOADS: 30,

        loadMoreItems: function (container, config) {
            if (config.reloadsNum <= this.MAX_RELOADS) {
                const visibleItems = $('.iscroll-item-content', container);
                const hiddenItems = $('.iscroll-item', container);
                const iscrollContainer = $('.iscroll', container);

                if (
                    hiddenItems.length >= this.ITEMS_PER_PAGE &&
                    visibleItems.length < this.MIN_RESULTS
                ) {
                    iscrollContainer.trigger('iscroll:nextPage');
                    config.reloadsNum++;
                }
            }
        },

        initContainer: function (container) {
            const config = {};
            config.reloadsNum = 0;

            const onItemsLoaded = _.partial(
                this.loadMoreItems,
                container,
                config,
            );
            container.on('scroll:dataLoaded', onItemsLoaded);

            onItemsLoaded();
        },
    });
})();
