(function () {
    'use strict';

    bindhq.nsIn('generalSettings', {
        /**
         * @param {jQuery} container
         * @param {jQuery.Event} evt
         */
        onChangeFeature: function (container, evt) {
            const element = $(evt.target);

            this.updateDependent(element, container);
        },

        onDisplayAlert: function (container, evt) {
            const element = $(evt.target);

            if ($(element).data('alert') !== '') {
                swal({
                    title: 'Are you sure?',
                    text: $(element).data('alert'),
                    type: 'info',
                });
            }
        },

        onInitDependents: function (container) {
            const elements = $('*[data-feature]', container);

            _.each(
                elements,
                function (element) {
                    this.updateDependent(element, container);
                }.bind(this),
            );
        },

        updateDependent: function (element, container) {
            const feature = $(element).data('feature');

            if (feature) {
                const dependents = $(
                    '*[data-dependent-feature="' + feature + '"]',
                    container,
                );

                _.each(dependents, function (dependent) {
                    const value = $(dependent).data('value');
                    const inverseMatch =
                        'yes' ===
                        dependent.dataset.dependentFeatureInverseMatch;

                    let active = !value
                        ? $(element).prop('checked')
                        : $(element).val() === value;

                    if (inverseMatch) {
                        active = !active;
                    }

                    if (active) {
                        $(dependent).fadeIn();
                    } else {
                        $(dependent).fadeOut();
                    }
                });
            }
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            const actions = $('[data-feature]', container);
            const alerts = $('[data-alert]', container);
            const onChangeFeature = _.partial(this.onChangeFeature, container);
            const onDisplayAlert = _.partial(this.onDisplayAlert, container);

            actions.on('change', onChangeFeature);
            alerts.on('change', onDisplayAlert);

            this.onInitDependents(container);
        },
    });
})();
