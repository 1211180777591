const numeral = require('numeral');
const { default: PQueue } = require('p-queue');

(function () {
    'use strict';

    /**
     * @param {Object}
     */
    const statusCache = {};

    bindhq.nsIn('abacus.payment', {
        /**
         * @param {String}
         */
        DISPLAY_FORMAT: '(0,0.00)',

        /**
         * @param {String}
         */
        INPUT_FORMAT: '0.00',

        /**
         * @param {TransactionTable}
         */
        table: null,

        /**
         * @param {PQueue}
         */
        queue: new PQueue({ concurrency: 1 }),

        /**
         * @param {jQuery} container
         * @param {Array} defaultIds
         * @param {Object} config
         * @param {jQuery} item
         */
        onInitItem: function (container, defaultIds, config, item) {
            const checkbox = $('.entry-apply', item);
            const itemId = checkbox.data('detail-id');

            if (_.contains(defaultIds, itemId)) {
                const hiddens = $('input[type=hidden]', item);
                const input = $('input[type=text]', item);
                const due = numeral($('.entry-amount-due', item).data('value'));
                const toPay = numeral(
                    $('.entry-amount-to-pay', item).data('value'),
                );
                const payValue = toPay.value() !== 0 ? toPay : due;

                checkbox.prop('checked', true);
                input.add(hiddens).prop('disabled', false);

                input.val(numeral(payValue).format(this.INPUT_FORMAT));
                this.updatePolicyInformation(checkbox);
            }
        },

        /**
         * @param {jQuery} container
         * @param {jQuery} item
         *
         * @return {Numeral}
         */
        totalDueFor: function (container, item) {
            const total = $('.total-payment', container);

            if (
                total.length === 0 ||
                total.data('autofilled') ||
                item.isCredit
            ) {
                return numeral(item.amountDue);
            } else {
                const unapplied = this.calculateUnApplied(container);

                if (unapplied.format('0.00') !== '0.00') {
                    const result =
                        item.amountDue >= unapplied
                            ? unapplied
                            : item.amountDue;

                    return numeral(result);
                } else {
                    return numeral(0);
                }
            }
        },

        /**
         * @param {jQuery} container
         * @param {jQuery.Event} evt
         */
        onApply: function (container, evt) {
            let finalValue = numeral(0);
            const checkbox = $(evt.target);
            const entry = checkbox.closest('tr');
            const input = $('input[type=text]', entry);
            const hiddens = $('input[type=hidden]', entry);
            const item = this.table.itemForEntry(entry);

            if (checkbox.is(':checked')) {
                finalValue = this.totalDueFor(container, item);

                input
                    .val(finalValue.format(this.INPUT_FORMAT))
                    .add(hiddens)
                    .prop('disabled', false);
            } else {
                input
                    .val('')
                    .add(hiddens)
                    .prop('disabled', true)
                    .removeClass('error-value');

                input.parent().siblings('.warning-message').text('');
            }

            this.updatePolicyInformation(checkbox);
            this.updateTotals(finalValue, entry, container);
            this.updateSummary(container);
        },

        /**
         */
        markItem: function (item, value, container) {
            const entry = item.element;
            const checkbox = $('[type=checkbox]', entry);
            const input = $('input[type=text]', entry);
            const hiddens = $('input[type=hidden]', entry);

            checkbox.prop('checked', true);

            input
                .add(hiddens)
                .prop('disabled', false)
                .val(value.format(this.INPUT_FORMAT));

            this.updateTotals(numeral(value), entry, container);
        },

        /**
         */
        updateTotals: function (value, entry, container) {
            const total = $('.total-payment', container);

            this.table.indexItem(entry);

            if (total.data('autofilled')) {
                const credit = this.calculateCredit();
                const regular = this.calculateRegular();
                let difference = numeral(0).add(regular).subtract(credit);

                difference = difference.value() > 0 ? difference : numeral(0);

                total.val(difference.format(this.INPUT_FORMAT));
            }
        },

        /**
         * @param {jQuery} container
         * @param {jQuery.Event} evt
         */
        onTotalUpdate: function (container, evt) {
            const input = $('.total-payment', container);

            input.data('autofilled', false);

            this.updateSummary(container);
        },

        /**
         * @param {jQuery} container
         * @param {jQuery.Event} evt
         */
        onEntryUpdate: function (container, evt) {
            const input = $(evt.target);
            const entry = input.closest('tr');
            const item = this.table.indexItem(entry);
            const msgArea = input.parent().siblings('.warning-message');

            input.removeClass('error-value');

            if (item.marked && this.isZero(item.amount)) {
                msgArea.text('The amount cannot be zero');
                msgArea.removeClass('over-applied');
                msgArea.removeClass('under-applied');

                input.addClass('error-value');
            } else if (
                item.marked &&
                this.isOverApplied(item.amount, item.amountDue)
            ) {
                msgArea.text('This item is over-applied');
                msgArea.addClass('over-applied');
            } else if (
                item.marked &&
                this.isUnderApplied(item.amount, item.amountApplied)
            ) {
                msgArea.text('This item is under-applied');
                msgArea.addClass('under-applied');
            } else {
                msgArea.text('');
                msgArea.removeClass('over-applied');
                msgArea.removeClass('under-applied');
            }

            this.updateSummary(container);
        },

        /**
         * @param {Function} f
         *
         * @return {Function}
         */
        complement: function (f) {
            return function () {
                return !f.apply(null, arguments);
            };
        },

        /**
         * @return {Function}
         */
        isRegular: function () {
            return this.complement(this.isCredit());
        },

        /**
         * @return {Function}
         */
        isCredit: function () {
            return function (item) {
                return item.isCredit;
            };
        },

        /**
         * @return {Function}
         */
        isMarked: function () {
            return function (item) {
                return item.marked;
            };
        },

        /**
         * @param {Array} filters
         *
         * @return {Integer}
         */
        calculateCount: function (filters) {
            return _.chain(this.table.getItems())
                .filter(function (item) {
                    return _.every(filters, function (f) {
                        return f(item);
                    });
                })
                .size()
                .value();
        },

        /**
         * @param {Function} filter
         *
         * @return {Numeral}
         */
        calculateTotal: function (filter) {
            const toTotal = function (acc, item) {
                return acc.add(item.amount);
            };

            return _.chain(this.table.getItems())
                .filter(filter)
                .reduce(toTotal, numeral(0))
                .value();
        },

        /**
         * @param {jQuery} container
         *
         * @return {Numeral}
         */
        calculateUnApplied: function (container) {
            const total = $('.total-payment', container);

            if (total.length === 0) {
                return numeral(0);
            } else {
                const value = total.val();
                const amount = numeral(value);
                const paid = numeral(container.data('amount-paid') || 0);
                const regular = this.calculateRegular();
                const credit = this.calculateCredit();

                return amount.add(paid).subtract(regular).add(credit);
            }
        },

        /**
         * @return {Numeral}
         */
        calculateRegular: function () {
            return this.calculateTotal(this.isRegular());
        },

        /**
         * @return {Numeral}
         */
        calculateCredit: function () {
            return this.calculateTotal(this.isCredit());
        },

        /**
         * @param {jQuery} container
         */
        updateSummary: function (container) {
            const inputTotal = $('.amount-applied', container);
            const inputUnApplied = $('.amount-unapplied', container);
            const inputRegular = $('.amount-regular', container);
            const inputCredit = $('.amount-credit', container);
            const inputSelectItemsRegular = $(
                '.selected-items-regular',
                container,
            );
            const inputSelectItemsCredit = $(
                '.selected-items-credit',
                container,
            );
            const submitBtn = $('.submit-button', container);
            const submitMsg = $('.submit-message', container);
            const totalMsg = $('.total-message', container);

            const credit = this.calculateCredit();
            const regular = this.calculateRegular();
            const unapplied = this.calculateUnApplied(container);
            const difference = numeral(0).add(regular).subtract(credit);

            const inputError = $('.payment-entries tr input').hasClass(
                'error-value',
            );

            const itemsRegular = this.calculateCount([this.isRegular()]);
            const selectedItemsRegular = this.calculateCount([
                this.isRegular(),
                this.isMarked(),
            ]);
            const itemsCredit = this.calculateCount([this.isCredit()]);
            const selectedItemsCredit = this.calculateCount([
                this.isCredit(),
                this.isMarked(),
            ]);

            const total = $('.total-payment', container);
            const totalValue = numeral(total.val());
            const warnings = $('.payment-entries', container)
                .find('.warning-message')
                .text();

            submitMsg.text('');
            totalMsg.text('');
            inputTotal.text(difference.format(this.DISPLAY_FORMAT));
            inputRegular.text(regular.format(this.DISPLAY_FORMAT));
            inputCredit.text(credit.format(this.DISPLAY_FORMAT));
            inputUnApplied.text(
                unapplied >= 0 ? unapplied.format(this.DISPLAY_FORMAT) : '0.00',
            );
            inputSelectItemsRegular.text(
                selectedItemsRegular + ' of ' + itemsRegular,
            );
            inputSelectItemsCredit.text(
                selectedItemsCredit + ' of ' + itemsCredit,
            );

            if (warnings) {
                submitMsg.text(
                    'Some of your selected transactions have warnings, make sure you review them before continuing',
                );
            }

            // If used is higher than the applied,
            if (this.greaterThan(credit, regular)) {
                submitMsg.text(
                    'The total applied credit amounts cannot be greater than the total invoices amounts',
                );
                submitBtn.prop('disabled', true);
            } else if (inputError) {
                submitBtn.prop('disabled', true);

                // If sum of payments is higher than the total amount, display number in red
            } else if (
                total.length !== 0 &&
                this.greaterThan(difference, totalValue)
            ) {
                totalMsg.text('Cannot be greater than the total amount');
                submitBtn.prop('disabled', true);
            } else {
                submitBtn.prop('disabled', false);
            }
        },

        /**
         * @param {Numeral} amount
         *
         * @return {Boolean}
         */
        isZero: function (amount) {
            return amount.value() === 0;
        },

        /**
         * @param {Numeral} amount
         * @param {Numeral} amountDue
         *
         * @return {Boolean}
         */
        isOverApplied: function (amount, amountDue) {
            return amount.value() > amountDue.value();
        },

        /**
         * @param {Numeral} amount
         * @param {Numeral} amountApplied
         *
         * @return {Boolean}
         */
        isUnderApplied: function (amount, amountApplied) {
            const amountTotal = numeral(amountApplied.value()).add(amount);

            return amountTotal.value() < 0;
        },

        /**
         * @param {Numeral} a
         * @param {Numeral} b
         *
         * @return {Boolean}
         */
        greaterThan: function (a, b) {
            const val = function (c) {
                return parseFloat(c.value().toFixed(2));
            };

            return val(a) > val(b);
        },

        /**
         * @param {jQuery} container
         * @param {jQuery.Event} e
         */
        onSubmit: function (container, e) {
            if (container.get(0).checkValidity()) {
                e.preventDefault();

                const items = _.filter(this.table.getItems(), this.isMarked());
                const total = $('.total-payment', container);

                if (total.length > 0 || items.length > 0) {
                    const markedRows = _.chain(this.table.getItems())
                        .filter(this.isMarked())
                        .filter(function (item) {
                            return !document.contains(item.element.get(0));
                        })
                        .pluck('element')
                        .map(function (row) {
                            return row.addClass('hidden');
                        })
                        .value();

                    $('.payment-entries tbody', container).append(markedRows);

                    // workaround for javascript bug where checked items remain disabled.
                    $('.payment-entries input:checked').each(function () {
                        $(this)
                            .closest('tr')
                            .find('[disabled]')
                            .removeAttr('disabled');
                    });

                    const submitBtn = $('.submit-button', container);
                    submitBtn.progress('start');
                    e.target.submit();
                } else {
                    swal({
                        title: 'Hey!',
                        text: 'Please, select at least one item',
                        type: 'warning',
                    });
                }
            }

            return false;
        },

        /**
         * @param {jQuery} container
         */
        refreshSelectAll: function (container) {
            const checkbox = $('.select-all', container);
            const isChecked = checkbox.prop('checked');
            const allItems = this.table.getItems();
            const markedItems = _.filter(allItems, this.isMarked());

            if (
                (isChecked && allItems.length !== markedItems.length) ||
                (!isChecked && markedItems.length > 0)
            ) {
                checkbox.addClass('partial');
            } else {
                checkbox.removeClass('partial');
            }
        },

        /**
         * @param {jQuery} container
         * @param {jQuery.Event} evt
         */
        onSelectAllClick: function (container, evt) {
            const checkbox = $(evt.currentTarget);
            let isChecked = checkbox.prop('checked');
            const total = $('.total-payment', container);

            if (checkbox.hasClass('partial') && !checkbox.prop('checked')) {
                isChecked = false;
            }

            if (!isChecked || total.length === 0) {
                $('tr [type=checkbox]', container)
                    .prop('checked', isChecked)
                    .trigger('change');

                this.updateSummary(container);
            } else {
                const items = this.table.getCurrentPageItems();
                const apply = function (item) {
                    $(item.element)
                        .find('[type=checkbox]')
                        .prop('checked', true)
                        .trigger('change');
                };

                if (total.val() === '') {
                    total.val(0).data('autofilled', true);
                }

                _.chain(items).filter(this.isCredit()).each(apply);

                _.chain(items)
                    .filter(this.isRegular())
                    .each(
                        function (item) {
                            if (
                                this.totalDueFor(container, item).format(
                                    '0.00',
                                ) !== '0.00'
                            ) {
                                apply(item);
                            }
                        }.bind(this),
                    );
            }

            this.refreshSelectAll(container);
        },

        updatePolicyInformation: function (item) {
            const statusUrl = item.data('policy-status-url');

            if (statusUrl) {
                const policyId = item.data('policy-id');
                const policy = item.parent().siblings().first();
                const msgArea = policy.find('.warning-message');
                const cacheId = 'policy-status-' + policyId;

                if (item.is(':checked')) {
                    const onSuccess = function (data) {
                        if (data.status !== 0) {
                            const msg =
                                'This policy is currently ' +
                                data.label +
                                '. Please review before applying the receipt to this item';
                            msgArea.text(msg);
                        }

                        statusCache[cacheId] = data;
                    };

                    const onComplete = function () {
                        policy.find('.fa-sync').remove();
                    };

                    policy
                        .find('a')
                        .append('&nbsp;<i class="fas fa-sync soft"></i>');

                    const cached = statusCache[cacheId];

                    if (cached) {
                        onSuccess(cached);
                        onComplete();
                    } else {
                        this.queue.add(() =>
                            $.ajax({
                                type: 'GET',
                                url: statusUrl,
                                success: onSuccess,
                                complete: onComplete,
                            }),
                        );
                    }
                } else {
                    msgArea.text('');
                }
            }
        },

        /**
         * @param {jQuery} container
         * @param {Array} defaultIds
         *
         * @return {Object}
         */
        offsetTotalFor: function (container, defaultIds) {
            const items = _.map(defaultIds, function (id) {
                return $('[data-id="' + id + '"]', container);
            });

            const isDebit = function (item) {
                return item.hasClass('entry-regular');
            };

            const toTotal = function (acc, item) {
                return (
                    acc +
                    parseFloat(item.find('.entry-amount-due').data('value'))
                );
            };

            const debitTotal = _.chain(items)
                .filter(isDebit)
                .reduce(toTotal, 0)
                .value();

            const creditTotal = _.chain(items)
                .filter(this.complement(isDebit))
                .reduce(toTotal, 0)
                .value();

            const amount =
                debitTotal && creditTotal
                    ? Math.min(debitTotal, creditTotal)
                    : debitTotal || creditTotal;

            return {
                amount: amount,
                creditTotal: creditTotal,
                debitTotal: debitTotal,
            };
        },

        /**
         * @param {TransactionTable} table
         */
        initCsvDownload: function (table) {
            bindhq.abacus.paymentCsv.init({
                getItems: function () {
                    return _.chain(table.getItems())
                        .filter(this.isMarked())
                        .value();
                }.bind(this),
            });
        },

        /**
         * @param {jQuery} container
         * @param {Object} offset
         * @param {Array} defaultIds
         */
        initDefaultSelection: function (container, offset, defaultIds) {
            if (defaultIds.length !== 0) {
                const total = $('.total-payment', container);
                /* eslint-disable indent */
                const totalValue =
                    offset.debitTotal && offset.creditTotal
                        ? numeral(offset.debitTotal).subtract(
                              offset.creditTotal,
                          )
                        : numeral(offset.debitTotal || 0);
                /* eslint-enable indent */
                total
                    .val(totalValue.format(this.INPUT_FORMAT))
                    .data('autofilled', true);
            }
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            const detailId = bindhq.util.queryParam('detailId');
            const defaultIds = detailId ? detailId.split(',') : [];
            const offset = this.offsetTotalFor(container, defaultIds);
            const initConfig = {
                creditTotal: numeral(offset.amount),
                debitTotal: numeral(offset.amount),
            };
            const onInitItem = _.partial(
                this.onInitItem,
                container,
                defaultIds,
                initConfig,
            );
            const onApply = _.partial(this.onApply, container);
            const onTotalUpdate = _.partial(this.onTotalUpdate, container);
            const onEntryUpdate = _.partial(this.onEntryUpdate, container);
            const onSubmit = _.partial(this.onSubmit, container);
            const onSelectAllClick = _.partial(
                this.onSelectAllClick,
                container,
            );
            const onRefreshSelectAll = _.partial(
                this.refreshSelectAll,
                container,
            );
            const updateSummary = _.partial(this.updateSummary, container);
            const selectedItems = container.data('selected-items') || {};

            const select = function (item) {
                const checkbox = $('.entry-apply', item);
                const itemId = checkbox.data('detail-id');
                const itemAmount = selectedItems[itemId];

                if (itemAmount) {
                    const value = numeral(itemAmount).format(
                        bindhq.abacus.payment.INPUT_FORMAT,
                    );

                    checkbox.prop('checked', true);

                    $('input[type=text]', item).val(value);
                    $('input[type=text], input[type=hidden]', item).prop(
                        'disabled',
                        false,
                    );

                    return true;
                }

                return false;
            };

            const onEditHandler = function (item) {
                if (!this.hasItem(item)) {
                    select(item);
                    this.indexItem(item);
                }
            };

            const onNewHandler = function (item, tbody) {
                if (!select(item)) {
                    onInitItem(item);
                }

                this.indexItem(item, tbody);
            };

            this.initDefaultSelection(container, offset, defaultIds);

            container.on('change', '.entry-apply', onApply);
            container.on('change', '.entry-apply', onRefreshSelectAll);
            container.on(
                'keyup',
                '.total-payment',
                _.debounce(onTotalUpdate, 500),
            );
            container.on(
                'keyup',
                '.payment-entries input[type=text]',
                _.debounce(onEntryUpdate, 500),
            );
            container.on(
                'change',
                '.payment-entries input[type=text]',
                _.debounce(onEntryUpdate, 500),
            );
            container.on('click', '.select-all', onSelectAllClick);
            container.on('transaction_table.filtered', onRefreshSelectAll);
            container.on('submit', onSubmit);

            $('.payment-entries tr', container).initEach(onInitItem);

            const table = (this.table = bindhq.abacus.transactionTable.create(
                container,
                {
                    onTransactionLoaded: container.hasClass(
                        'form-edit-abacus-payment',
                    )
                        ? onEditHandler
                        : onNewHandler,
                },
            ));

            table.indexFromDom('.payment-entry');

            container.on('transaction_table.items_loaded', updateSummary);

            this.initCsvDownload(table);

            updateSummary();
        },
    });
})();
