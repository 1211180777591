(function () {
    'use strict';

    bindhq.ns('forms.multiAddress');

    bindhq.forms.multiAddress.initOptionalFields = function (container) {
        bindhq.forms.multiAddress.onCopyLocationChange(container);
        bindhq.forms.multiAddress.onCopyMailingChange(container);
    };

    bindhq.forms.multiAddress.onCopyLocationChange = function (container) {
        const copyLocation = $('.copy-to-location', container);
        const locationAddress = $('.location-address', container);
        bindhq.forms.multiAddress.disabler(copyLocation, locationAddress);
    };

    bindhq.forms.multiAddress.onCopyMailingChange = function (container) {
        const copyMailing = $('.copy-to-mailing', container);
        const mailingAddress = $('.mailing-address', container);
        bindhq.forms.multiAddress.disabler(copyMailing, mailingAddress);
    };

    bindhq.forms.multiAddress.disabler = function (control, content) {
        if ($(control).prop('checked')) {
            $(content).find('input').attr('readonly', true);
            $(content).find('select').attr('disabled', true);
            $(content).addClass('d-none').removeClass('d-block');
        } else {
            $(content).find('input').attr('readonly', false);
            $(content).find('select').attr('disabled', false);
            $(content).addClass('d-block').removeClass('d-none');
        }
    };

    bindhq.forms.multiAddress.init = function () {
        const container = $(this);

        bindhq.forms.multiAddress.initOptionalFields(container);

        const onCopyLocationChange = _.partial(
            bindhq.forms.multiAddress.onCopyLocationChange,
            container,
        );

        const onCopyMailingChange = _.partial(
            bindhq.forms.multiAddress.onCopyMailingChange,
            container,
        );

        container.on('change', '.copy-to-location', onCopyLocationChange);
        container.on('change', '.copy-to-mailing', onCopyMailingChange);
    };
})();
