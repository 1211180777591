(function () {
    'use strict';

    bindhq.nsIn('forms.insured', {
        onCopyChange: function (container) {
            const copy = $('.copy', container);
            const mailAddress = $('.mailing-address-section', container);
            if ($(copy).prop('checked')) {
                $(mailAddress).hide();
            } else {
                $(mailAddress).show();
            }
        },

        initContainer: function (container) {
            this.onCopyChange(container);

            const onCopyChange = _.partial(this.onCopyChange, container);

            container.on('change', '.copy', onCopyChange);
        },
    });
})();
