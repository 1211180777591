(function () {
    'use strict';

    bindhq.ns('history');

    /**
     * @param {jQuery} container
     */
    bindhq.history.initModal = function (container) {
        container.modalAjax({
            success: function () {
                bindhq.tabs.refresh('history');
            },
        });
    };

    /**
     * @param {jQuery.Event} evt
     */
    bindhq.history.onToggleClick = function (evt) {
        const anchor = $(evt.currentTarget);

        anchor
            .closest('.item')
            .toggleClass('toggled')
            .find('.body .inner')
            .slideToggle();
    };

    /**
     * @param {jQuery.Event} evt
     */
    bindhq.history.onExpandClick = function (evt) {
        const anchor = $(evt.currentTarget).closest('li');

        anchor
            .closest('ul')
            .find('.hidden')
            .hide()
            .removeClass('hidden')
            .slideDown();

        anchor.remove();
    };

    /**
     * @param {jQuery.Event} evt
     */
    bindhq.history.onToggleDeleteClick = function (evt) {
        const anchor = $(evt.currentTarget);
        const config = {
            type: 'POST',
            url: anchor.attr('href'),
        };

        $.ajax(config);

        anchor.closest('.item').toggleClass('deleted');

        evt.preventDefault();
    };

    /**
     *
     */
    bindhq.history.init = function () {
        const container = $(this);

        container.on('click', '.toggle', bindhq.history.onToggleClick);

        container.on('click', '.expand', bindhq.history.onExpandClick);

        container.on(
            'click',
            'a.toggle-delete',
            bindhq.history.onToggleDeleteClick,
        );
    };
})();
