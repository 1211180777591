(function () {
    'use strict';

    bindhq.nsIn('abacus.taxReconciliation', {
        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            bindhq.abacus.payment.initContainer(container);
        },
    });
})();
