(function () {
    'use strict';

    function validate(item, property) {
        if (!item[property]) {
            throw new Error(
                'ACHBatchChangeset items need to have the property: ' +
                    property,
            );
        }
    }

    function isType(items, item, type) {
        validate(item, 'id');

        if (!items[item.id]) {
            return false;
        }

        return type === items[item.id].type;
    }

    function store(items, item, type) {
        validate(item, 'id');
        validate(item.amount, 'amount');
        validate(item.amount, 'currency');

        items[item.id] = {
            id: item.id,
            type: type,
            amount: item.amount,
        };
    }

    function ACHBatchChangeset(items) {
        const initial = {};

        _.forEach(items, function (item) {
            validate(item, 'type');

            if (item.type !== 'add' && item.type !== 'remove') {
                throw new Error(
                    'ACHBatchChangeset items need to have an "type" of either "add" or "remove"',
                );
            }

            store(initial, item, item.type);
        });

        this.items = initial;
    }

    ACHBatchChangeset.prototype.add = function (item) {
        validate(item, 'id');

        if (!this.items[item.id]) {
            store(this.items, item, 'add');
        } else if (this.items[item.id].type === 'remove') {
            delete this.items[item.id];
        }
    };

    ACHBatchChangeset.prototype.isAdded = function (item) {
        return isType(this.items, item, 'add');
    };

    ACHBatchChangeset.prototype.remove = function (item) {
        validate(item, 'id');

        if (!this.items[item.id]) {
            store(this.items, item, 'remove');
        } else if (this.items[item.id].type === 'add') {
            delete this.items[item.id];
        }
    };

    ACHBatchChangeset.prototype.isRemoved = function (item) {
        return isType(this.items, item, 'remove');
    };

    ACHBatchChangeset.prototype.getChanges = function () {
        return _.values(this.items);
    };

    bindhq.nsIn('abacus.achBatchChangeset', {
        create: function (items) {
            return new ACHBatchChangeset(items);
        },
    });
})();
