(function () {
    'use strict';

    bindhq.nsIn('forms.historyByType', {
        /**
         * @param {jQuery} container
         * @param {String} html
         */
        onSuccess: function (container, html) {
            const listing = container.closest('.row').find('.listing');

            $('.no-results', listing).remove();

            listing.prepend(html);

            container.get(0).reset();
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            const config = {
                success: _.partial(this.onSuccess, container),
            };

            $('<input type="hidden" />')
                .attr('name', 'template')
                .val('byTypeItem')
                .appendTo(container);

            container.formAjax(config);
        },
    });
})();
