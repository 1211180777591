(function () {
    'use strict';

    bindhq.nsIn('conversations', {
        /**
         * @param {jQuery} container
         * @param {jQuery.Event} evt
         */
        onActionClick: function (container, evt) {
            evt.preventDefault();

            const source = $(evt.currentTarget);
            const config = {
                url: source.attr('href'),
                success: _.partial(this.onHtml, container),
                complete: function () {
                    source.progress('stop');
                },
            };

            $.ajax(config);

            source.progress('start');
        },

        /**
         * @param {jQuery} container
         * @param {String} html
         */
        onHtml: function (container, html) {
            const selector = container.data('conversation-target');
            const target = selector ? $(selector, container) : container;

            target.html(html);

            bindhq.initContainer(target);
            this.initForms(target);
        },

        /**
         * @param {jQuery} container
         * @param {String} message
         */
        onMessageHtml: function (container, message) {
            const html =
                '<li class="conversation-message conversation-message-new">' +
                message +
                '<div class="conversation-message-link"></div>' +
                '</li>';

            $('.conversation-messages', container).prepend(html);
        },

        /**
         * @param {jQuery} container
         * @param {jQuery.Event} evt
         * @param {Object} message
         */
        onEmailReceived: function (container, evt, message) {
            const selector = '.conversation-' + message.conversationId;

            if ($(selector, container).length > 0) {
                const config = {
                    url: message.messageUrl,
                    success: _.partial(this.onMessageHtml, container),
                };

                $.ajax(config);
            }
        },

        /**
         * @param {jQuery} container
         * @param {jQuery} form
         */
        initForm: function (container, form) {
            const select = $('.mode', form);
            const config = {
                success: _.partial(this.onHtml, container),
            };

            select.on('change', function () {
                form.submit();
            });
            form.formAjax(config);
        },

        /**
         * @param {jQuery} container
         */
        initForms: function (container) {
            $('.form-conversation', container).initEach(
                _.partial(this.initForm, container),
            );
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            container.on(
                'click',
                '[data-conversation-action]:not(.tab-content-dynamic [data-conversation-action])',
                _.partial(this.onActionClick, container),
            );

            this.initForms(container);

            $('body').on(
                'conversations.emailReceived',
                _.partial(this.onEmailReceived, container),
            );
        },
    });
})();
