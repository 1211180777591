(function () {
    'use strict';

    bindhq.nsIn('forms.slaBatch', {
        initContainer: function (container) {
            let lastDate = null;

            $('.checkbox-tick', container).change(function () {
                const datepicker = $(this).closest('tr').find('.hasDatepicker');

                if ($(this).prop('checked')) {
                    if (lastDate !== null) {
                        $(datepicker).val(lastDate);
                    }
                } else {
                    $(datepicker).val(null);
                }
            });

            $('.hasDatepicker', container).change(function () {
                lastDate = $(this).val();
            });
        },
    });
})();
