(function () {
    'use strict';

    bindhq.nsIn('forms.userPostComment', {
        /**
         * @type {String}
         */
        containerClass: '.user-post-container',

        /**
         * @param {jQuery} container
         * @param {String} html
         */
        renderComment: function (container, html) {
            container
                .closest(this.containerClass)
                .find('.comments')
                .removeClass('no-comments')
                .append(html);
        },

        /**
         * @param {jQuery} container
         */
        scrollToComment: function (container) {
            const body = container
                .closest(this.containerClass)
                .find('.modal-body')
                .get(0);

            if (body) {
                body.scrollTop = body.scrollHeight;
            }
        },

        /**
         * @param {jQuery} container
         * @param {String} html
         */
        onPostCreated: function (container, html) {
            this.renderComment(container, html);
            this.scrollToComment(container);

            container.get(0).reset();
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            const onPostCreated = _.partial(this.onPostCreated, container);

            container.formAjax({
                success: onPostCreated,
            });
        },
    });
})();
