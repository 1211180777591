(function () {
    'use strict';

    bindhq.nsIn('maps.byArea', {
        /**
         * @param {google.maps.Polygon} polygon
         *
         * @return {google.maps.LatLng}
         */
        centreOf: function (polygon) {
            const bounds = new google.maps.LatLngBounds();

            _.each(polygon.getPath().getArray(), function (latLng) {
                bounds.extend(latLng);
            });

            return bounds.getCenter();
        },

        /**
         * @param {google.maps.Map} map
         * @param {Object} boundaryResult
         * @param {Object} dataResult
         */
        onDataLoaded: function (map, boundaryResult, dataResult) {
            const boundaries = boundaryResult[0];
            const data = dataResult[0];
            const max = _.max(data);
            const infoWindow = new google.maps.InfoWindow();

            _.each(
                data,
                function (result, index) {
                    const boundary = boundaries[index];

                    if (boundary) {
                        const toLatLng = function (pair) {
                            return new google.maps.LatLng(pair[0], pair[1]);
                        };

                        const toLatLngArray = function (latLngs) {
                            return _.map(latLngs, toLatLng);
                        };

                        const minOpacity = 0.2;
                        const opacity =
                            (result / max) * (1 - minOpacity) + minOpacity;

                        const polygon = new google.maps.Polygon({
                            paths: _.map(boundary.points, toLatLngArray),
                            strokeWeight: 0,
                            fillColor: '#eb5767',
                            fillOpacity: opacity,
                        });

                        google.maps.event.addListener(
                            polygon,
                            'click',
                            function (evt) {
                                infoWindow.setContent(
                                    '<b>' + index + '</b>: $' + result,
                                );
                                infoWindow.setPosition(evt.latLng);
                                infoWindow.open(map);
                            },
                        );

                        polygon.setMap(map);
                    }
                },
                this,
            );
        },

        /**
         * @param {google.maps.Map} map
         * @param {String} boundariesUrl
         * @param {String} dataUrl
         */
        loadMapBoundaries: function (map, boundariesUrl, dataUrl) {
            bindhq.maps.showLoading(map);

            $.when($.ajax({ url: boundariesUrl }), $.ajax({ url: dataUrl }))
                .always(_.partial(bindhq.maps.hideLoading, map))
                .done(_.partial(this.onDataLoaded, map));
        },

        /**
         * @param {google.maps.Map} map
         * @param {jQuery} container
         */
        initContainer: function (map, container) {
            const boundariesUrl = container.data('map-byarea-boundaries');
            const dataUrl = container.data('map-byarea-data');
            const onMapLoad = _.partial(
                this.loadMapBoundaries,
                map,
                boundariesUrl,
                dataUrl,
            );

            google.maps.event.addListenerOnce(map, 'idle', onMapLoad);
        },
    });
})();
