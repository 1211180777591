(function () {
    'use strict';

    bindhq.ns('ga');

    if (typeof ga === 'undefined') {
        return;
    }

    /**
     * Send a tracking event to Google Analytics
     *
     * @param {String} category
     * @param {String} action
     * @param {String} label
     * @param {Number} value
     */
    bindhq.ga.event = _.partial(ga || bindhq.util.noop, 'send', 'event');
})();
