(function () {
    'use strict';

    bindhq.ns('alerts');

    bindhq.alerts.container = $('<div class="alert alert-toast"></div>')
        .hide()
        .appendTo('body');

    bindhq.alerts.setOption = function (options, optionName) {
        if (Object.prototype.hasOwnProperty.call(options, optionName)) {
            bindhq.alerts.container.css(optionName, options[optionName]);
        }
    };

    bindhq.alerts.setOptions = function (options) {
        const self = bindhq.alerts;

        self.setOption(options, 'position');
        self.setOption(options, 'top');
        self.setOption(options, 'left');
        self.setOption(options, 'right');
        self.setOption(options, 'bottom');
        self.setOption(options, 'background');
    };

    bindhq.alerts.setContent = function (content) {
        bindhq.alerts.container.find('p').remove();
        bindhq.alerts.container.append('<p>' + content + '</p>');
    };

    bindhq.alerts.fadeInComplete = function () {
        window.setTimeout(bindhq.alerts.hide, 2000);
    };

    bindhq.alerts.show = function (content, options) {
        if (options !== undefined) {
            bindhq.alerts.setOptions(options);
        }

        if (content !== undefined) {
            bindhq.alerts.setContent(content);
        }

        bindhq.alerts.container.fadeIn(bindhq.alerts.fadeInComplete);
    };

    bindhq.alerts.xhrToMessage = function (xhr) {
        try {
            return JSON.parse(xhr.responseText).message;
        } catch (e) {
            return 'Internal server error';
        }
    };

    bindhq.alerts.showResponse = function (xhr) {
        const self = bindhq.alerts;

        self.show(self.xhrToMessage(xhr));
    };

    bindhq.alerts.hide = function () {
        bindhq.alerts.container.fadeOut();
    };
})();
