(function () {
    'use strict';

    bindhq.ns('iscroll');

    bindhq.iscroll.loader = $('<div></div>')
        .append(
            '<div id="circularG">' +
                '<div id="circularG_1" class="circularG"></div>' +
                '<div id="circularG_2" class="circularG"></div>' +
                '<div id="circularG_3" class="circularG"></div>' +
                '<div id="circularG_4" class="circularG"></div>' +
                '<div id="circularG_5" class="circularG"></div>' +
                '<div id="circularG_6" class="circularG"></div>' +
                '<div id="circularG_7" class="circularG"></div>' +
                '<div id="circularG_8" class="circularG"></div>' +
                '</div>',
        )
        .addClass('iscroll-loader');

    /**
     * Handler for when pagniated data is loaded
     *
     * @param {Object} config
     * @param {String} html
     */
    bindhq.iscroll.onDataLoaded = function (config, html) {
        bindhq.iscroll.loader.remove();
        bindhq.iscroll.populate(config, html);
        bindhq.iscroll.checkEvents(config, html);
        config.container.trigger('scroll:dataLoaded');
    };

    /**
     * Check we still need to listen for scroll events
     *
     * @param {Object} config
     * @param {String} html
     */
    bindhq.iscroll.checkEvents = function (config, html) {
        const container = config.container;
        const perPage = container.data('iscroll-per-page');
        const itemCount = $('.iscroll-item', html).length;

        if (itemCount < perPage) {
            config.scroller.unbind('scroll', config.scroll);
        }
    };

    /**
     * Use the HTML to populate the history
     *
     * @param {Object} config
     * @param {String} html
     */
    bindhq.iscroll.populate = function (config, html) {
        const container = config.container;
        const items = $('.iscroll-item, .iscroll-item-content', html).not(
            '.empty',
        );

        if (items.length > 0) {
            const content = container.hasClass('iscroll-content')
                ? container
                : $('.iscroll-content', container);

            content.append(items);
            items.each(bindhq.init);

            config.loading = false;
        }
    };

    /**
     * Load another page of history
     *
     * @param {Object} config
     */
    bindhq.iscroll.loadPage = function (config) {
        const container = config.container;
        const pageParameter =
            container.data('iscroll-page-parameter') || 'page';
        const url = container.data('iscroll-url') || location.href;
        const data = {};
        const success = _.partial(bindhq.iscroll.onDataLoaded, config);

        config.loading = true;

        data[pageParameter] = ++config.page;

        $.get(url, data, success);

        const loaderInsertPoint = container.parent().is('table')
            ? container.parent()
            : container;

        bindhq.iscroll.loader.insertAfter(loaderInsertPoint);
    };

    /**
     * On scroll check if we need to load more history
     *
     * @param {Object} config
     */
    bindhq.iscroll.onScroll = function (config) {
        if (config.container.is(':visible')) {
            const scroller = config.scroller;
            const pxHeight = config.inner.scrollHeight;
            const pxScroll = scroller.scrollTop() + scroller.height();

            if (!config.loading && pxScroll > pxHeight - pxHeight * 0.05) {
                bindhq.iscroll.loadPage(config);
            }
        }
    };

    bindhq.iscroll.getInitialConfig = function (container) {
        const scrollContainer = container.closest('.iscroll-container').get(0);
        const config = {
            loading: false,
            page: 1,
            container: container,
            inner: scrollContainer || document.body,
            scroller: scrollContainer ? $(scrollContainer) : $(window),
        };

        config.scroll = _.partial(bindhq.iscroll.onScroll, config);

        return config;
    };

    /**
     * @param {jQuery} container
     */
    bindhq.iscroll.initContainer = function (container) {
        const config = bindhq.iscroll.getInitialConfig(container);
        const loadPage = _.partial(bindhq.iscroll.loadPage, config);
        const perPage = container.data('iscroll-per-page');
        const items = $('.iscroll-item', container);

        if (items.length === perPage) {
            config.scroller.scroll(config.scroll);

            container.on('iscroll:nextPage', loadPage);
        }
    };

    /**
     * Initialise scroller from function context
     */
    bindhq.iscroll.init = function () {
        const container = $(this);

        bindhq.iscroll.initContainer(container);
    };
})();
