(function () {
    'use strict';

    bindhq.nsIn('renewals.manager', {
        /**
         * @param {jQuery} tables
         *
         * @return {jQuery}
         */
        selectedRowsFor: function (tables) {
            return $(
                'tr:has([type=checkbox]:checked:not(".select-all"))',
                tables,
            );
        },

        /**
         * @param {jQuery} tables
         *
         * @return {Array}
         */
        policiesFor: function (tables) {
            const toAgent = function (row) {
                const agency = $('.agency', row);
                const name = agency.data('agent-name');
                const email = agency.data('agent-email');

                return name || email ? { name: name, email: email } : null;
            };

            const toPolicy = function (index, row) {
                return {
                    policyId: $('.policy', row).data('id'),
                    policyNum: $('.policy', row).text().trim(),
                    agent: toAgent(row),
                    canEmail:
                        'yes' === $('.checkbox-tick', row).data('can-email'),
                    canRenew:
                        'yes' === $('.checkbox-tick', row).data('can-renew'),
                };
            };

            return this.selectedRowsFor(tables).map(toPolicy).toArray();
        },

        /**
         * @param {jQuery} multi
         * @param {jQuery} tables
         */
        onCheckboxClick: function (multi, tables) {
            const visible = 'multi-select-visible';
            const policies = this.policiesFor(tables);

            multi.removeClass('can-email');
            multi.removeClass('can-renew');

            if (policies.length) {
                const policyNums = _.pluck(policies, 'policyNum');
                const canEmail = _.every(policies, (policy) => policy.canEmail);
                const canRenew = _.every(policies, (policy) => policy.canRenew);

                $('.items span', multi).html(policyNums.join(', '));

                multi.addClass(visible);

                if (canEmail) {
                    multi.addClass('can-email');
                }

                if (canRenew) {
                    multi.addClass('can-renew');
                }
            } else {
                multi.removeClass(visible);

                $('.items span', multi).html('');
            }
        },

        /**
         */
        onSelectAll: function (ev) {
            const selected = $(ev.target).is(':checked');
            const table = $(ev.target).parents('table');
            if (selected) {
                $(table).find('input[type="checkbox"]').prop('checked', true);
            } else {
                $(table).find('input[type="checkbox"]').prop('checked', false);
            }
        },

        /**
         */
        onSaveRenewalStatus: function (ev) {
            const token = $('#ajax_token').val();
            const id = $(ev.target).data('id');
            const status = $(ev.target).data('status');
            const row = $(ev.target).parents('tr');
            const button = $(ev.target).parents(':eq(2)');

            button.progress('start');

            $.ajax({
                type: 'POST',
                url: '/secure/policies/' + id + '/save-renewal-status',
                data: { status: status, token: token },
                dataType: 'json',
                success: function (msg) {
                    button.progress('stop');
                    if (msg.status === 'success') {
                        if ($(row).hasClass('renewal-has-quotes')) {
                            const subrow = $(row)
                                .parents('tbody')
                                .find('tr.renewal-quote');
                            $(subrow).removeClass();
                            $(subrow).addClass(
                                'renewal renewal-quote renewal-' + msg.data,
                            );
                        }
                        $(row).removeClass();
                        $(row).addClass(
                            'renewal renewal-has-quotes renewal-' + msg.data,
                        );
                    } else {
                    }
                },
                error: function () {
                    button.progress('stop');
                },
            });
        },

        /**
         * @param {jQuery} container
         */
        initFeatures: function (container) {
            const pop = $('.renewal-note');
            const tables = container.parent().find('.table-renewals');

            tables.on(
                'click',
                '.renewal-status-action',
                _.partial(this.onSaveRenewalStatus),
            );

            tables.on(
                'submit',
                '.renew-form',
                _.partial(function () {
                    setTimeout(function () {
                        location.reload();
                    }, 500);
                }),
            );

            pop.popover({
                placement: 'left',
            });
        },

        /**
         * @param {jQuery} container
         */
        initMultiSelect: function (container) {
            const tpl = $('#renewals-multi-select-tpl').html() || '';
            const html = bindhq.util.template(tpl, {});
            const multi = $(html).appendTo(container);
            const tables = container.parent().find('.table-renewals');

            tables.on('change', '.select-all', _.partial(this.onSelectAll));

            tables.on(
                'change',
                '[type=checkbox]',
                _.partial(this.onCheckboxClick, multi, tables),
            );
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            container.on('refreshPage', () => {
                container.submit();
            });

            this.initMultiSelect(container);
            this.initFeatures(container);
        },
    });
})();
