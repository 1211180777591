(function () {
    'use strict';

    bindhq.ns('gmaps');

    bindhq.gmaps.init = function (defaultPosition, defaultZoom) {
        if (!window.google) {
            return;
        }

        let map = null;
        const container = $(this); // e.g. .gmaps-container
        const boxContainer = $('.gmaps-box-container', container);
        const mapContainer = $('.gmaps-map', boxContainer);
        const viewMapButton = $('.gmaps-view', container);
        const modalContainer = $('.modal', boxContainer);
        const defaultOptions = {
            center: new google.maps.LatLng(
                defaultPosition[0],
                defaultPosition[1],
            ),
            mapId: 'BINDHQ_MAP',
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            zoom: defaultZoom,
        };

        const mapGetter = function () {
            if (!map) {
                map = new google.maps.Map(mapContainer.get(0), defaultOptions);
            }

            return map;
        };

        const onSearch = _.partial(
            bindhq.gmaps.updateMap,
            mapGetter,
            container,
            modalContainer,
            defaultOptions,
            defaultZoom,
        );

        $(viewMapButton).on('click', function (e) {
            e.preventDefault();
            onSearch();
        });

        bindhq.gmaps.initStreetview(container);
    };

    /**
     * Geocode the address in the container, and pass the results
     * to the callback (along with the address extracted)
     *
     * @param {jQuery} container
     * @param {Function} callback
     */
    bindhq.gmaps.geocode = function (container, callback) {
        const geocoder = new google.maps.Geocoder();
        const address = bindhq.gmaps.extractAddress(container);
        const config = { address: address };
        const handler = _.partial(callback, address);

        geocoder.geocode(config, handler);
    };

    /**
     * Create URL for location in the preview container
     *
     * @param {jQuery} preview
     * @param {GLatLng} loc
     *
     * @return {String}
     */
    bindhq.gmaps.streetviewUrlFor = function (preview, loc) {
        return (
            'https://maps.googleapis.com/maps/api/streetview' +
            '?size=' +
            preview.width() +
            'x' +
            preview.height() +
            '&location=' +
            loc.lat() +
            ',%20' +
            loc.lng() +
            '&fov=90' +
            '&heading=235' +
            '&pitch=10' +
            '&sensor=false'
        );
    };

    /**
     * Refresh the streetview preview with the results
     *
     * @param {String} address
     * @param {Array} results
     */
    bindhq.gmaps.refreshStreetview = function (address, results) {
        const visibleClass = 'visible';
        const preview = $('#gmaps-streetview-preview');

        if (results && results.length > 0) {
            const result = results[0];
            const loc = result.geometry.location;
            const url = bindhq.gmaps.streetviewUrlFor(preview, loc);
            const img = $('<img></img>').attr({ src: url });

            preview.empty().append(img).addClass(visibleClass);
        } else {
            preview.removeClass(visibleClass);
        }
    };

    /**
     * Refresh the streetview for the address in the container
     *
     * @param {jQuery} container
     */
    bindhq.gmaps.reloadStreetview = function (container) {
        bindhq.gmaps.geocode(container, bindhq.gmaps.refreshStreetview);
    };

    /**
     * Initialise the streetview for the container
     *
     * @param {jQuery} container
     */
    bindhq.gmaps.initStreetview = function (container) {
        const refresher = _.partial(bindhq.gmaps.reloadStreetview, container);
        const keyHandler = _.debounce(refresher, 50);

        $('input', container).change(keyHandler);
    };

    /**
     * Show street view for a maps current position
     *
     * @param {GMap} map
     */
    bindhq.gmaps.showStreetViewFor = function (map) {
        const sv = map.getStreetView();

        sv.setPosition(map.getBounds().getCenter());
        sv.setVisible(true);
    };

    bindhq.gmaps.showResults = function (map, results, defaultZoom) {
        // @todo is there a better way of handling the 'on load' when the
        // maps bounds gets set initially?
        const onLoad = map.addListener('bounds_changed', function () {
            bindhq.gmaps.showStreetViewFor(map);
            google.maps.event.removeListener(onLoad);
        });

        map.setCenter(results[0].geometry.location);
        map.setZoom(defaultZoom);

        $.each(results, function (index, value) {
            bindhq.gmaps.addMarker(map, value.geometry.location);
        });
    };

    bindhq.gmaps.updateMap = function (
        mapGetter,
        container,
        modalContainer,
        defaultOptions,
        defaultZoom,
    ) {
        const map = mapGetter();

        bindhq.gmaps.geocode(container, function (address, results, status) {
            $('.gmaps-status-container p', modalContainer).hide();

            if (status === google.maps.GeocoderStatus.OK) {
                bindhq.gmaps.showResults(map, results, defaultZoom);
            } else if (address.length > 0) {
                map.setOptions(defaultOptions);
                bindhq.gmaps.updateStatus(status, modalContainer);
            } else if (address.length === 0) {
                map.setCenter(defaultOptions.center);
            }
        });

        $(modalContainer).sizedModal('show');
    };

    bindhq.gmaps.addMarker = function (map, location) {
        bhqGoogleMaps(() => {
            new google.maps.marker.AdvancedMarkerElement({
                map: map,
                position: location,
            });
        });
    };

    bindhq.gmaps.updateStatus = function (status, modalContainer) {
        switch (status) {
            case google.maps.GeocoderStatus.ZERO_RESULTS:
                $(
                    '.gmaps-status-container .zero-results',
                    modalContainer,
                ).show();
                break;
            case google.maps.GeocoderStatus.OVER_QUERY_LIMIT:
                $(
                    '.gmaps-status-container .over-query-limit',
                    modalContainer,
                ).show();
                break;
            case google.maps.GeocoderStatus.REQUEST_DENIED:
                $(
                    '.gmaps-status-container .request-denied',
                    modalContainer,
                ).show();
                break;
            case google.maps.GeoCoderStatus.INVALID_REQUEST:
                $(
                    '.gmaps-status-container .invalid-request',
                    modalContainer,
                ).show();
                break;
            default:
                $('.gmaps-status-container .unknown').show();
        }
    };

    bindhq.gmaps.extractAddress = function (container) {
        let address = '';
        const addressItems = [];

        addressItems.push($('.address_line1', $(container)).val());
        addressItems.push($('.address_line2', $(container)).val());
        addressItems.push($('.address_line3', $(container)).val());
        addressItems.push($('.zip', $(container)).val());

        $.each(addressItems, function (index, value) {
            if (value && value.length > 0) {
                address += ', ' + value;
            }
        });

        return address.substring(2);
    };
})();
