(function () {
    'use strict';

    bindhq.ns('forms.ajaxautocomplete');

    bindhq.forms.ajaxautocomplete.init = function () {
        const input = $(this);
        const config = {
            source: input.data('url'),
            minLength: 2,
            appendTo: input.parent(),
        };
        $(input).parent().css('position', 'relative');
        input.autocomplete(config);
    };
})();
