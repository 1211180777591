(function () {
    'use strict';

    bindhq.ns('forms.sections');

    /**
     * Handler for deleting a field container
     *
     * @param {jQuery} container
     */
    bindhq.forms.sections.onDeleteField = function (container) {
        container.closest('.collection-item').remove();
    };

    /**
     * Initialise a field (default is read-only)
     *
     */
    bindhq.forms.sections.initField = function () {
        const container = $(this);
        const onClick = _.partial(
            bindhq.forms.sections.onDeleteField,
            container,
        );

        $('<a></a>')
            .html('remove')
            .addClass('edit-control remove')
            .click(onClick)
            .prependTo(container);
    };

    /**
     * Create and return a new field attached to the given fields container
     *
     * @param {jQuery} fields
     * @param {String} name
     * @param {String} value
     *
     * @return {jQuery}
     */
    bindhq.forms.sections.createField = function (fields, name, value) {
        const index = $('> .collection-item', fields).length;
        const html = fields
            .closest('.control-group')
            .data('prototype')
            .replace(/__name__/g, index);
        const element = $(html);

        $('.name', element).val(name || '');
        $('.value', element).val(value || '');

        return element.appendTo(fields).find('.field');
    };

    /**
     * Adds a field to a fields element
     *
     * @param {jQuery} fields
     */
    bindhq.forms.sections.addField = function (fields) {
        const field = bindhq.forms.sections.createField(fields);

        bindhq.forms.sections.initField.apply(field);
    };

    /**
     * Initialise the fields part of a sub-section
     *
     */
    bindhq.forms.sections.initFields = function () {
        const container = $(this);
        const onClick = _.partial(bindhq.forms.sections.addField, container);
        const button = $('<a></a>')
            .html('Add field')
            .click(onClick)
            .addClass('add-field');

        $('<div></div>')
            .addClass('edit-control add-wrapper')
            .append(button)
            .insertAfter(container);

        $('.field', container).each(bindhq.forms.sections.initField);
    };

    /**
     * Create a sub-section inside a section (ie. limits, or deductibles)
     *
     * @param {jQuery} section
     * @param {Object} json
     */
    bindhq.forms.sections.createSubSection = function (section, json) {
        const subSections = $('.sub-sections', section);
        const index = $('> .collection-item', subSections).length;
        const fieldsHtml = $('> :nth-child(2)', section)
            .data('prototype')
            .replace(/sections___name__/g, 'sections_' + index)
            .replace(/sections\]\[__name__/g, 'sections][' + index);

        const newSubSection = $(fieldsHtml).appendTo(subSections);
        $(newSubSection).find('span.sub-section-name').html(json.name);
        $(newSubSection).find('input.sub-section-name').val(json.name);

        _.each(json.fields || [], function (field) {
            bindhq.forms.sections.createField(
                $('.fields', newSubSection),
                field.name,
                field.value,
            );
        });
    };

    /**
     * Create and return a new section element
     *
     * @param {jQuery} container (with prototype for section)
     *
     * @return {jQuery}
     */
    bindhq.forms.sections.createSection = function (container) {
        const index = $('> .collection-item', container).length;
        const html = container
            .data('prototype')
            .replace(
                /(carrier_template|quote|binding)_sections___name__/g,
                '$1_sections_' + index,
            )
            .replace(
                /(carrier_template|quote|binding)\[sections\]\[__name__/g,
                '$1[sections][' + index,
            );

        return $('<div></div>')
            .addClass('collection-item')
            .html(html)
            .appendTo(container);
    };

    /**
     * Add a new section to the specified sections container
     *
     * @param {jQuery} container
     * @param {Object} json
     *
     * @return {jQuery}
     */
    bindhq.forms.sections.addNewSection = function (container, json) {
        const section = bindhq.forms.sections.createSection(container);

        $('.section-name', section).val(json.name || '');

        _.each(
            json.sections || [],
            _.partial(bindhq.forms.sections.createSubSection, section),
        );

        bindhq.forms.sections.initSection.apply(section);

        return section;
    };

    /**
     * Handler for when we delete sections
     *
     * @param {jQuery} container
     */
    bindhq.forms.sections.onDeleteSection = function (container) {
        container.fadeAndRemove();
    };

    /**
     * Initialise an individual section
     *
     */
    bindhq.forms.sections.initSection = function () {
        const container = $(this);
        const onClick = _.partial(
            bindhq.forms.sections.onDeleteSection,
            container,
        );
        const button = $('<a></a>')
            .html('delete coverage')
            .addClass('remove')
            .click(onClick);

        $('<div></div>')
            .addClass('edit-control')
            .append(button)
            .prependTo(container);

        $('.fields', container).each(bindhq.forms.sections.initFields);
    };

    /**
     * Prefill the container with the specified quote object's sections
     *
     * @param {jQuery} container
     * @param {Object} quote
     */
    bindhq.forms.sections.prefillWith = function (container, quote) {
        _.each(
            quote.sections,
            _.partial(bindhq.forms.sections.addNewSection, container),
        );
    };

    /**
     * Try to prefill the sections container with a quote
     *
     * @param {jQuery} container
     * @param {Number} id
     */
    bindhq.forms.sections.prefill = function (container, id) {
        $.getJSON(
            '/secure/quotes/' + id + '.json',
            {},
            _.partial(bindhq.forms.sections.prefillWith, container),
        );
    };

    /**
     * Inits the 'sections' part of the quotes form, from current context
     *
     */
    bindhq.forms.sections.initSections = function () {
        const container = $(this);
        const parent = container.parent();

        if (!parent.attr('disabled')) {
            const onClick = _.partial(
                bindhq.forms.sections.addNewSection,
                container,
                {
                    sections: [{ name: 'Limits' }, { name: 'Deductibles' }],
                },
            );

            const button = $('<a></a>')
                .html('Add Coverage Section')
                .addClass('btn btn-success btn-add add-section')
                .click(onClick);

            $('<div></div>')
                .addClass('edit-control')
                .append(button)
                .insertAfter(container);

            $('> .collection-item', container).each(
                bindhq.forms.sections.initSection,
            );

            if (parent.hasClass('sections-prefill')) {
                bindhq.forms.sections.prefill(
                    container,
                    parent.data('quoteid'),
                );
            }
        }
    };

    /**
     * Toggle the edit form as being read only
     *
     * @param {jQuery} container
     */
    bindhq.forms.sections.toggleEditable = function (container) {
        container.toggleClass('quote-read-only');

        $('.field .name', container).toggleReadOnly();
    };

    /**
     * Initialises a read-only quote to be toggleable to edit mode
     *
     * @param {jQuery} container
     */
    bindhq.forms.sections.initReadOnly = function (container) {
        const onClick = _.partial(
            bindhq.forms.sections.toggleEditable,
            container,
        );
        const sections = $('.sections-wrap', container);

        $('.field .name', container).toggleReadOnly();

        $('<a></a>')
            .html('Edit Fields')
            .addClass('btn btn-info toggle')
            .click(onClick)
            .toggleHtmlOnClick('Lock Fields')
            .prependTo(sections);
    };
})();
