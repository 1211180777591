(function () {
    'use strict';

    bindhq.nsIn('abacus.appliesShow', {
        initShow: function (container) {
            const applies = $('#applies', container);
            const appliesTable = bindhq.abacus.transactionTable.create(applies);

            appliesTable.indexFromDom('tbody tr');
        },
    });
})();
