(function () {
    'use strict';

    bindhq.ns('policy.overview');

    bindhq.policy.overview.toggle = function (toToggle, controller) {
        toToggle.slideToggle();
        if (controller.text() === 'show') {
            controller.text('hide');
        } else {
            controller.text('show');
        }
    };

    bindhq.policy.overview.getToggler = function (toToggle) {
        const toggler = $('<a></a>').text('show').addClass('toggler');

        toggler.click(function () {
            bindhq.policy.overview.toggle(toToggle, toggler);
        });

        return toggler;
    };

    bindhq.policy.overview.initAdditionalInsuredToggle = function (container) {
        const additionalInsureds = container.find('.additional-insureds');
        additionalInsureds.each(function () {
            const additionalInsured = $(this);
            const toggler =
                bindhq.policy.overview.getToggler(additionalInsured);
            additionalInsured.prev('h2').append(toggler);
        });
    };

    bindhq.policy.overview.init = function () {
        const container = $(this);
        bindhq.policy.overview.initAdditionalInsuredToggle(container);
    };
})();
