(function () {
    'use strict';

    bindhq.ns('endorsements');

    bindhq.endorsements.onTypeChange = function (container) {
        const input = $('.type-select', container);
        const typeId = input.select2('val');

        $('.type', container)
            .hide()
            .filter('.type-' + typeId, container)
            .show();
    };

    bindhq.endorsements.showPolicy = function (container) {
        const curr = location.href;
        const redirect = container.data('policyurl');

        if (curr.indexOf(redirect) === -1) {
            location.href = redirect;
        } else {
            location.reload();
        }
    };

    bindhq.endorsements.onUserToggleClick = function (control, link) {
        const filter = bindhq.forms.ajaxselect.filterFrom(control) || {};

        if (filter.show_inactive) {
            delete filter.show_inactive;
        } else {
            filter.show_inactive = true;
        }

        const text = filter.show_inactive
            ? 'Hide inactive producers'
            : 'Show inactive producers';

        control.data('filter', filter);
        link.html(text);
    };

    bindhq.endorsements.initUserToggle = function (container) {
        const control = $('input.producer', container);
        const link = $('<a></a>')
            .html('Show inactive producers')
            .addClass('user-toggle')
            .insertAfter(control);
        const onClick = _.partial(
            bindhq.endorsements.onUserToggleClick,
            control,
            link,
        );

        link.click(onClick);
    };

    bindhq.endorsements.onSave = function () {
        if (bindhq.tabs.exists('endorsements')) {
            bindhq.tabs.refresh('endorsements');
        } else {
            location.reload();
        }
    };

    /**
     * @param {jQuery} container
     * @param {jQuery.Event} evt
     */
    bindhq.endorsements.onEffectiveDateChange = function (container, evt) {
        const dateString = $(evt.currentTarget).val().substring(0, 10);

        if (dateString) {
            const wrap = $('.cancellation-rates-wrap', container);
            const config = {
                url: container.data('cancellationratesurl'),
                data: {
                    cancellationDate: dateString,
                },
                success: function (html) {
                    wrap.html(html);
                },
                complete: function () {
                    bindhq.loader.hidePartial(wrap);
                },
            };

            bindhq.loader.showPartial(wrap);

            $.ajax(config);
        }
    };

    bindhq.endorsements.initContainer = function (container) {
        const self = bindhq.endorsements;
        const onChange = _.partial(self.onTypeChange, container);
        const onEffectiveDateChange = _.partial(
            self.onEffectiveDateChange,
            container,
        );

        $('.type-select', container).change(onChange);

        container.formAjax({
            success: self.onSave,
            lockOnSuccess: true,
        });

        if (container.hasClass('form-endorsements-new')) {
            self.initUserToggle(container);
        }

        container.on(
            'change',
            '#invoice_detail_date_effective',
            onEffectiveDateChange,
        );

        onChange();
    };

    bindhq.endorsements.init = function () {
        const container = $(this);

        bindhq.endorsements.initContainer(container);
    };
})();
