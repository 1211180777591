(function () {
    'use strict';

    bindhq.nsIn('forms.file', {
        /**
         * @param {jQuery} container
         * @param {Object} file
         */
        onSuccess: function (container, file) {
            $('body').trigger($.Event('file_updated', { file }));
        },

        /**
         * @param {jQuery} container
         */
        onSubmit: function (container) {
            if ($('.select-folder', container).select2('val') === '') {
                $('.select-folder', container).removeAttr('name');
            }
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            $('select.select-folder', container).select2({
                placeholder: 'Select a folder...',
                allowClear: true,
            });
            $('select.select-types', container).select2({
                placeholder: 'Select tags...',
                allowClear: true,
            });

            container.submit(_.partial(this.onSubmit, container));

            const config = {
                success: _.partial(this.onSuccess, container),
            };

            container.modalAjax(config);
        },
    });
})();
