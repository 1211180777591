(function () {
    'use strict';

    bindhq.ns('localStorage');

    bindhq.localStorage.isSupported = function () {
        return window.localStorage;
    };

    bindhq.localStorage.setItem = function (key, value) {
        try {
            localStorage.setItem(key, value);
        } catch (e) {
            console.error('Error saving key "' + key + '": ', value);

            throw e;
        }
    };

    bindhq.localStorage.getItem = function (key) {
        return localStorage.getItem(key);
    };

    bindhq.localStorage.setObject = function (key, object) {
        bindhq.localStorage.setItem(key, JSON.stringify(object));
    };

    bindhq.localStorage.getObject = function (key) {
        return JSON.parse(localStorage.getItem(key));
    };

    bindhq.localStorage.removeItem = function (key) {
        return localStorage.removeItem(key);
    };

    bindhq.localStorage.clearAll = function () {
        return localStorage.clear();
    };
})();
