(function () {
    'use strict';

    bindhq.nsIn('abacus.transactionListFilter', {
        onSelectCustomer: function (container, e) {
            const customerFilters = $(e.target)
                .parents()
                .eq(1)
                .siblings()
                .find('input');
            customerFilters.select2('val', '');
        },

        initContainer: function (container) {
            const onSelectCustomer = _.partial(
                this.onSelectCustomer,
                container,
            );

            container.on('change', '.customer-filters input', onSelectCustomer);
        },
    });
})();
