(function () {
    'use strict';

    bindhq.nsIn('reports.businessPlans', {
        /**
         * @param {jQuery} container
         * @param {String} html
         */
        onMessageCreated: function (container, html) {
            const messages = $('.conversation-messages', html);

            $('.conversation-messages').replaceWith(messages);
            $('.markup-raw', container).initEach(bindhq.markup.initContainer);

            $('.form-business-plan-conversation-message', container)
                .get(0)
                .reset();
        },

        /**
         * @param {jQuery} container
         */
        initShow: function (container) {
            const config = {
                success: _.partial(this.onMessageCreated, container),
            };

            $('.form-business-plan-conversation-message', container).formAjax(
                config,
            );
        },
    });
})();
