(function () {
    'use strict';

    bindhq.nsIn('abacus.transactionLoading', {
        initFor: function (container, totalPages, message) {
            let currentPage = 1;

            container.html(
                '<div class="transaction-loading">' +
                    '<div class="inner">' +
                    '<div class="progressor progressor-inline progressor-striped active">' +
                    '<div class="bar" style="width:20px;">2%</div>' +
                    '</div>' +
                    '</div>' +
                    '<div class="message">' +
                    (message || 'Loading transactions...') +
                    '</div>' +
                    '</div>',
            );

            return {
                nextPage: function (message) {
                    const width = Math.round(
                        (++currentPage / totalPages) * 100,
                    );
                    const widthValue = width.toString() + '%';

                    $('.bar', container).css({ width: widthValue });
                    $('.bar', container).text(widthValue);
                    $('.message', container).html(message || '');
                },

                finished: function () {
                    container.empty();
                },
            };
        },
    });
})();
