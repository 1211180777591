(function () {
    'use strict';

    bindhq.nsIn('abacus.paymentShow', {
        initShow: function (container) {
            const applies = $('#applies', container);
            const appliesTable = bindhq.abacus.transactionTable.create(applies);

            const payments = $('#payments', container);
            const paymentsTable =
                bindhq.abacus.transactionTable.create(payments);

            const config = {
                getItems: function () {
                    return [].concat(
                        appliesTable.getItems(),
                        paymentsTable.getItems(),
                    );
                },
            };

            appliesTable.indexFromDom('tbody tr');
            paymentsTable.indexFromDom('tbody tr');

            bindhq.abacus.paymentCsv.init(config, container);
        },

        /**
         * This can be removed with legacy payments
         */
        initContainer: function (container) {
            bindhq.abacus.transactionTable
                .create(container)
                .indexFromDom('.entry-regular-show');
        },
    });
})();
