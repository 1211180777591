const Color = require('color-js');
const Chart = require('chart.js');

(function () {
    'use strict';

    bindhq.nsIn('charts', {
        /**
         * @param {Number} offset
         * @param {DOMNode} element
         * @param {Number} index
         *
         * return Object
         */
        toCurrencyData: function (offset, element, index) {
            const value = $('td:nth-child(' + offset + ')', element).text();

            return bindhq.reports.utils.currencyToFloat(value);
        },

        /**
         * @param {jQuery} table
         *
         * @return {Array}
         */
        toData: function (table) {
            const ths = $('thead th', table);
            const trs = $('tbody tr', table).toArray();

            const toDataContent = function (element, index) {
                const color = new Color(
                    bindhq.reports.utils.getColorFromPalette(index),
                );

                return {
                    label: element.innerText,
                    lineTension: 0.2,
                    borderWidth: 2,
                    pointRadius: 4,
                    pointBorderWidth: 2,
                    pointHoverRadius: 8,
                    pointHoverBorderWidth: 3,
                    pointBackgroundColor: 'white',

                    borderColor: color.toCSS(),
                    pointBorderColor: color.toCSS(),
                    pointHoverBackgroundColor: color.toCSS(),
                    backgroundColor: color.setAlpha(0.5).toCSS(),

                    data: _.map(trs, _.partial(this.toCurrencyData, index + 2)),
                };
            };

            return _.map(ths, toDataContent.bind(this));
        },

        /**
         * @param {jQuery} table
         *
         * @return {Array}
         */
        toLabels: function (table) {
            const toLabel = function (index, element) {
                return $('td:first-child', element).text();
            };

            return $('tbody tr', table).map(toLabel).toArray();
        },

        getData: function (container) {
            return {
                labels: this.toLabels(container),
                datasets: this.toData(container),
            };
        },

        init: function (container) {
            const table = container;
            const type = $(table).data('type') ? $(table).data('type') : 'line';
            const data = this.getData(container);
            const ctx = bindhq.reports.utils.contextFor(table);

            const options = {
                legend: {
                    display: true,
                },
            };

            new Chart(ctx, {
                type: type,
                data: data,
                options: options,
            });

            table.remove();
        },
    });
})();
