(function () {
    'use strict';

    bindhq.nsIn('reports.issuance', {
        /**
         * @param {jQuery} container
         * @param {jQuery.Event} evt
         */
        onSaveClick: function (container, evt) {
            const source = $(evt.currentTarget);
            const form = $('.form-issuance', container);

            if (form.valid()) {
                const query = form.serialize();
                const setUrl = function (el, attr) {
                    const value = el.attr(attr);
                    const url =
                        value.substring(0, value.indexOf('?')) + '?' + query;

                    el.attr(attr, url);
                };

                if (source.hasClass('save-form')) {
                    const closest = source.closest('form');
                    setUrl(closest, 'action');
                    closest.get(0).submit();
                } else {
                    setUrl(source, 'href');

                    bindhq.modalAjax.showUrl(source.attr('href'));
                }
            }
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            const onSaveClick = _.partial(this.onSaveClick, container);

            container.on('click', '.save', bindhq.util.noDefault(onSaveClick));
        },
    });
})();
