(function () {
    'use strict';

    bindhq.nsIn('abacus.paymentModal', {
        onSubmit: function (container, e) {
            const url = $(container).attr('action');
            const button = $('.btn-primary', container);

            button.progress('start');

            $.ajax({
                type: 'POST',
                url: url,
                data: $(container).serialize(),
                success: bindhq.modalAjax.helper.defaultOnSuccess,
                error: function (xhr) {
                    button.progress('stop');
                    $('.alert-placeholder', container).html(
                        '<div class="alert alert-error"></div>',
                    );
                    $('.alert-placeholder .alert', container).html(
                        'The payment has not been created',
                    );
                },
            });
        },

        initContainer: function (container) {
            container.validate({
                submitHandler: function (form) {
                    this.onSubmit(form);
                }.bind(this),
                ignoreTitle: true,
            });
        },
    });
})();
