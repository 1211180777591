(function () {
    bindhq.nsIn('forms.inspection', {
        /**
         * @param {jQuery} inspectionCompany
         * @param {jQuery} inspectionCompanyContactContactContactContact
         * @param {Integer} inspectionCompanyId
         */
        onInspectionCompanySelect: function (
            inspectionCompany,
            inspectionCompanyContact,
            inspectionCompanyId,
        ) {
            inspectionCompanyContact.data('ajaxurl', function () {
                return inspectionCompanyContact
                    .data('ajaxurl-pattern')
                    .replace(/{inspectionCompanyId}/, inspectionCompanyId);
            });
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            const inspectionCompany = $(
                '#inspection_inspection_company',
                container,
            );
            const inspectionCompanyContact = $(
                '#inspection_inspection_company_contact',
                container,
            );
            const inspectionCompanyContactPhone = $(
                '#inspection_inspection_company_contact_phone',
                container,
            );
            const inspectionCompanyContactEmail = $(
                '#inspection_inspection_company_contact_email',
                container,
            );

            bindhq.forms.ajaxselect.dependsOn(
                inspectionCompany,
                inspectionCompanyContact,
                this.onInspectionCompanySelect,
            );

            inspectionCompanyContact.on('change', function () {
                inspectionCompanyContactPhone.val('');
                inspectionCompanyContactEmail.val('');

                const data = $(this).select2('data');

                if (data) {
                    inspectionCompanyContactPhone.val(data.phone_extension);
                    inspectionCompanyContactEmail.val(data.email);
                }
            });
        },
    });
})();
