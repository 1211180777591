(function () {
    'use strict';

    bindhq.ns('carriers.policyNumbers');

    bindhq.carriers.policyNumbers.initDelete = function () {
        const form = $(this);
        const row = form.closest('tr');
        const config = {
            beforeSubmit: function () {
                row.fadeOut();
            },
        };

        form.ajaxForm(config);
    };

    bindhq.carriers.policyNumbers.init = function () {
        $(this)
            .find('.form-delete-carrier-policy-number')
            .each(bindhq.carriers.policyNumbers.initDelete);
    };
})();
