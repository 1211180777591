(function () {
    'use strict';

    bindhq.ns('forms.programs');

    /**
     * @param {jQuery} container
     */
    bindhq.forms.programs.initCarrierFields = function (container) {
        const carrier = container.findOne('#quote_template_carrier');

        carrier.on('change', () =>
            carrier.get(0).dispatchEvent(new CustomEvent('input')),
        );
    };

    bindhq.forms.programs.initTooltips = function () {
        function configureHelpPopoverForFormField(parent) {
            const label = $(parent.find('label').get(0));
            const labelText = label.text();
            const helpEl = parent.find('.form-text');
            helpEl.hide();
            const helptext = helpEl.text();
            label.append(
                '<i class="fas fa-question-circle ml-1 mt-1" data-controller="form--popup" data-trigger="hover" data-placement="top" data-html="true" data-content="' +
                    helptext +
                    '"></i>',
            );
            $(label).on('DOMNodeInserted', function () {
                $('[data-toggle="popoverr"]').popover();
            });
        }

        $('.form-text')
            .parent()
            .each(function (x, y) {
                configureHelpPopoverForFormField($(y));
            });
    };

    bindhq.forms.programs.init = function () {
        const self = bindhq.forms.programs;
        const container = $(this);

        self.initCarrierFields(container);
        self.initTooltips();
    };
})();
