const numeral = require('numeral');

(function () {
    'use strict';

    bindhq.nsIn('abacus.paymentCsv', {
        /**
         * @param {Object} item
         * @param {Object} property
         */
        amount: function (item, property) {
            const value = item[property].value();
            const amount = numeral(Math.abs(value));

            if (item.payableType === 'payable') {
                amount.multiply(-1);
            }

            return amount.format('0.00');
        },

        /**
         * @param {Object} config
         */
        generate: function (config) {
            const fields = {
                'Tran Date': function (item) {
                    return item.originalPostedAt || '';
                },
                Policy: function (item) {
                    return item.originalPolicy || '';
                },
                Carrier: function (item, data) {
                    return data.carrierName || '';
                },
                Market: function (item, data) {
                    return data.marketingCompanyName || '';
                },
                'Eff Date': function (item) {
                    return item.originalEffectiveAt || '';
                },
                Insured: function (item) {
                    return item.originalInsured || '';
                },
                Producer: function (item) {
                    return item.originalProducer || '';
                },
                Type: function (item) {
                    return item.originalDescription || '';
                },
                'Gross Premium': function (item, data) {
                    return data.premium || '';
                },
                'Commission Percentage': function (item, data) {
                    return data.commissionPercentage || '';
                },
                'Commission Amount': function (item, data) {
                    return data.commissionAmount || '';
                },
                'Co. Fees': function (item, data) {
                    return data.companyFee || '';
                },
                'Co. Insp. Fees': function (item, data) {
                    return data.companyInspectionFee || '';
                },
                'Net Due': function (item) {
                    return this.amount(item, 'due');
                }.bind(this),
                'Amount To Pay': function (item) {
                    return this.amount(item, 'amount');
                }.bind(this),
                Number: function (item, data) {
                    return data.number;
                },
            };

            const csv = [_.keys(fields)];
            const items = config.getItems();

            _.each(items, function (item) {
                const data = item.element.get(0).dataset;
                const datum = [];

                _.each(_.values(fields), function (callback) {
                    datum.push(callback(item, data));
                });

                csv.push(datum);
            });

            return bindhq.csv.arrayToCsv(csv);
        },

        /**
         * @param {Object} config
         */
        onCsvDownloadClick: function (config) {
            bindhq.util.download(
                this.generate(config),
                'text/csv',
                'transactions.csv',
            );
        },

        /**
         * @param {Object} config
         */
        init: function (config, container) {
            $('.btn-download-payment-csv', container).on(
                'click',
                _.partial(this.onCsvDownloadClick, config),
            );
        },
    });
})();
