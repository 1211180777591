const ProgressBar = require('progressbar.js');

(function () {
    'use strict';

    bindhq.nsIn('progressBars', {
        initProgressBars: function (container) {
            const stopValue = $.isNumeric(container.data('value'))
                ? container.data('value')
                : -1;
            const hasGoal = container.data('value') >= 0;
            let noStopContent = 'No Goal';
            const link = container.data('link');
            noStopContent += link
                ? '<br>' + '<a href="' + link + '">Add</a>'
                : '';

            const bar = new ProgressBar.Circle(container.get(0), {
                color: '#fff',
                trailColor: '#353842a1',
                strokeWidth: 4,
                trailWidth: 4,
                easing: 'easeInOut',
                duration: 1400,
                text: {
                    style: null,
                },
                from: { width: 4 },
                to: { width: 4 },

                step: function (state, circle) {
                    circle.path.setAttribute('stroke', '#12AAEB');
                    circle.path.setAttribute('stroke-width', state.width);

                    const value = Math.round(circle.value() * 100);
                    circle.setText(
                        '<span>' + value + '%</span>' + '<br> of goal',
                    );

                    if (value >= stopValue) {
                        circle.stop();
                        circle.setText(
                            '<span>' +
                                Math.round(stopValue) +
                                '%</span>' +
                                '<br> of goal',
                        );
                    }
                },
            });

            if (hasGoal) {
                bar.animate(1.0); // Number from 0.0 to 1.0
            } else {
                bar.setText(noStopContent);
            }
        },

        init: function (container) {
            this.initProgressBars(container);
        },
    });
})();
