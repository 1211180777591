(function () {
    'use strict';

    bindhq.nsIn('forms.invoices', {
        /**
         * @param {jQuery} container
         * @param {jQuery.Event} evt
         */
        onPayInFullClick: function (container, evt) {
            const button = $(evt.currentTarget);
            const outstanding = button.data('invoice-outstanding');

            button.parent().find('input[type="text"]').val(outstanding);
        },

        /**
         * @param {jQuery} container
         */
        onPaymentModalSuccess: function (container) {
            bindhq.tabs.refresh('endorsements');
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            container.on(
                'click',
                '[data-invoice-outstanding]',
                _.partial(this.onPayInFullClick, container),
            );

            const parent = container.parent();

            if (parent.hasClass('modal')) {
                const config = {
                    success: _.partial(this.onPaymentModalSuccess, container),
                };

                container.modalAjax(config);
            }
        },
    });
})();
