const {
    BigDecimalField,
} = require('../../../../../../../../assets/js/BindHQ/Form/BigDecimalField');

(function () {
    'use strict';

    bindhq.nsIn('forms.mask', {
        /**
         * @param {Integer} index
         * @param {DOMElement} element
         */
        init: function (index, element) {
            const bigDecimal = new BigDecimalField(element);
            bigDecimal.init();
        },
    });
})();
