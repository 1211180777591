// Plugins
let RedactorPlugins = {};

// Insert Signature Style

const signatureStyles = {
    image_left: `<table style="border-collapse: collapse; border: none;">
                    <tbody>
                        <tr style="border: none;">
                            <td style="border: none;">
                                <img src="/bundles/bindhqwebapp/img/avatar-80.png" alt="user img" style="width: 96px; height: 96px;">
                            </td>
                            <td style="border: none;">
                                <h3 style="margin-left: 0px;">John Doe</h3>
                                <p style="margin-left: 0px;">Marketing Manager | Company</p>
                                <ul>
                                    <li><strong>Phone</strong>: 111 222 333</li>
                                    <li><strong>Mobile</strong>: 124 456 5646</li>
                                    <li><strong>Email</strong>: john@doe.com</li>
                                    <li><strong>Website</strong>: <a href="https://example.com">https://example.com</a></li>
                                    <li><strong>Address</strong>: William Ave Bedford, NY, USA</li>
                                </ul>
                            </td>
                        </tr>
                    </tbody>
                </table>`,
    image_right: `<table style="border-collapse: collapse; border: none;">
                    <tbody>
                        <tr style="border: none;">
                            <td style="border: none;">
                                <h3 style="margin-left: 0px;">John Doe</h3>
                                <p style="margin-left: 0px;">Marketing Manager | Company</p>
                                <ul>
                                    <li><strong>Phone:</strong> 111 222 3333</li>
                                    <li><strong>Mobile:</strong> 123 123 1234</li>
                                    <li><strong>Email:</strong> john@doe.com</li>
                                    <li><strong>Website:</strong> https://example.com</li>
                                    <li><strong>Address:</strong> William Ave Bedford, NY, USA</li>
                                </ul>
                            </td>
                            <td style="border: none;">
                                <img src="/bundles/bindhqwebapp/img/avatar-80.png" alt="user img" style="width: 96px; height: 96px;">
                            </td>
                        </tr>
                    </tbody>
                </table>`,
    inline: `<table style="border-collapse: collapse; border: none;">
                <tbody>
                    <tr style="border: none;">
                        <td style="border: none;">
                            <img src="/bundles/bindhqwebapp/img/avatar-80.png" alt="user img" style="width: 96px; height: 96px;">
                        </td>
                        <td style="border: none;">
                            <h3 style="margin-left: 0px;">John Doe</h3>
                            <p style="margin-left: 0px;">Marketing Manager | Company</p>
                        </td>
                        <td style="border: none;">
                            <ul>
                                <li><strong>Phone:</strong> 111 222 3333</li>
                                <li><strong>Mobile:</strong> 123 123 1234</li>
                                <li><strong>Email:</strong> john@doe.com</li>
                                <li><strong>Website:</strong> https://example.com</li>
                                <li><strong>Address:</strong> William Ave Bedford, NY, USA</li>
                            </ul>
                        </td>
                    </tr>
                </tbody>
            </table>`,
};

RedactorPlugins.signatureStyle = {
    init: function () {
        this.buttonAdd('signatureStyle', 'Insert Signature Style', false, {
            image_left: {
                title: '<img src="/bundles/bindhqwebapp/img/sig-left.svg" style="padding: 5px;">',
                callback: () => {
                    this.setEditor(signatureStyles.image_left);
                }
            },
            separator_1: {
                name: 'separator',
            },
            image_right: {
                title: '<img src="/bundles/bindhqwebapp/img/sig-right.svg" style="padding: 5px;">',
                callback: () => {
                    this.setEditor(signatureStyles.image_right);
                },
            },
            separator_2: {
                name: 'separator',
            },
            inline: {
                title: '<img src="/bundles/bindhqwebapp/img/sig-inline.svg" style="padding: 5px;">',
                callback: () => {
                    this.setEditor(signatureStyles.inline);
                },
            }
        });

        this.buttonAwesome('signatureStyle', 'fa-magic');
    },
};

module.exports = RedactorPlugins;
