(function () {
    'use strict';

    bindhq.nsIn('reports.agencyRanking', {
        initContainer: function (container) {
            container.on('refreshPage', function () {
                container.closest('form').get(0).submit();
            });
        },
    });
})();
