(function () {
    'use strict';

    bindhq.ns('forms.agency');

    bindhq.forms.agency.okToBindChecked = function (container, e) {
        if ($(e.currentTarget).attr('checked') === 'checked') {
            $('.ok-to-quote', container).attr('checked', 'checked');
        }
    };

    bindhq.forms.agency.init = function () {
        const container = $(this);

        const okToBind = _.partial(
            bindhq.forms.agency.okToBindChecked,
            container,
        );

        container.on('click', '.ok-to-bind', okToBind);
    };
})();
