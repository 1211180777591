(function () {
    'use strict';

    bindhq.ns('table.horizontalScroll');

    bindhq.table.horizontalScroll.init = function (container) {
        let scrollHandle = 0;
        const scrollStep = 5;
        const pan = $('.pan');

        // Start the scrolling process
        pan.on('mouseenter', function () {
            const data = $(this).data('scrollModifier');
            const direction = parseInt(data, 10);

            $(this).addClass('active');

            startScrolling(direction, scrollStep);
        });

        pan.on('mouseleave', function () {
            stopScrolling();
            $(this).removeClass('active');
        });

        function startScrolling(direction) {
            if (scrollHandle === 0) {
                scrollHandle = setInterval(function () {
                    const newOffset =
                        container.scrollLeft() + scrollStep * direction;
                    container.scrollLeft(newOffset);
                }, 10);
            }
        }

        function stopScrolling() {
            clearInterval(scrollHandle);
            scrollHandle = 0;
        }
    };
})();
