(function () {
    'use strict';

    bindhq.ns('training');

    /**
     * @param {jQuery} tags
     *
     * @return {Array}
     */
    bindhq.training.selectedTags = function (tags) {
        const toTag = function (i, e) {
            return e.value;
        };

        return $('input:checked', tags).map(toTag).toArray();
    };

    /**
     * @param {jQuery} lesson
     *
     * @return {Array}
     */
    bindhq.training.lessonTags = function (lesson) {
        const toTag = function (i, e) {
            return e.dataset.tag;
        };

        return $('.tag', lesson).map(toTag).toArray();
    };

    /**
     * @param {jQuery} container
     * @param {jQuery} input
     * @param {jQuery} tags
     */
    bindhq.training.filter = function (container, input, tags) {
        // var search = input.val().toLowerCase();
        const selectedTags = bindhq.training.selectedTags(tags);

        const tagMatches = function (lesson) {
            const lessonTags = bindhq.training.lessonTags(lesson);

            if (selectedTags.length === 0) {
                return true;
            } else {
                return _.intersection(selectedTags, lessonTags).length > 0;
            }
        };

        const showIfMatches = function () {
            const lesson = $(this);
            const action = tagMatches(lesson) ? 'fadeIn' : 'fadeOut';

            lesson[action]();
        };

        $('.lesson', container).each(showIfMatches);
    };

    /**
     * @param {jQuery} container
     * @param {jQuery.Event} evt
     */
    bindhq.training.onTagFilterClick = function (container, evt) {
        bindhq.training.resetTags();
        $(evt.currentTarget).toggleClass('btn-selected');

        container.trigger('filterschanged');
    };

    /**
     * @param {jQuery} container
     */
    bindhq.training.resetTags = function (container) {
        $('.filter-tags .btn-selected', container).prop('checked', false);
        $('.filter-tags .btn-selected', container).toggleClass('btn-selected');
    };

    /**
     * @param {jQuery} container
     */
    bindhq.training.onFilterChanged = function (container) {
        container.trigger('filterschanged');
    };

    /**
     *
     */
    bindhq.training.init = function (container) {
        const self = bindhq.training;
        const filterInput = $('.filter input', container);
        const tags = $('.filter-tags', container);
        const onTagFilterClick = _.partial(self.onTagFilterClick, container);
        const onFilterChanged = _.partial(self.onFilterChanged, container);

        filterInput.keyup(_.debounce(onFilterChanged, 200));

        container.on(
            'filterschanged',
            _.partial(self.filter, container, filterInput, tags),
        );

        $('.filter-tags input', container).on('click', onTagFilterClick);
    };
})();
