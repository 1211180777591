(function () {
    'use strict';

    bindhq.ns('ajax');

    /**
     * On load of JSON, possibly cache, then pass back to the
     * handler function.
     *
     * @param {Function} success
     * @param {String} cacheId
     * @param {Number} timeout Seconds
     * @param {Object} data
     */
    bindhq.ajax.onLoad = function (success, cacheId, timeout, data) {
        success(data);
    };

    /**
     * Return cache id for URL
     *
     * @param {String} url
     *
     * @return {String}
     */
    bindhq.ajax.idFor = function (url) {
        return bindhq.util.userId() + '-ajax-get-' + url;
    };

    /**
     * Do a GET request to fetch some JSON, optionally caching it for
     * some period of time.
     *
     * @param {String} url
     * @param {Object} data
     * @param {Object} options
     */
    bindhq.ajax.get = function (url, data, options) {
        const cacheId = bindhq.ajax.idFor(url);
        const success = options.success || bindhq.util.noop;

        bindhq.ajax.rawGet(url, data, options);
    };

    /**
     * Does a GET, ignoring any cache read
     *
     * @param {String} url
     * @param {Object} data
     * @param {Object} options
     */
    bindhq.ajax.rawGet = function (url, data, options) {
        const onLoad = _.partial(
            bindhq.ajax.onLoad,
            options.success,
            bindhq.ajax.idFor(url),
            options.timeout,
        );

        $.ajax({
            dataType: 'json',
            url: url,
            data: data,
            success: onLoad,
            error: options.error,
        });
    };
})();
