(function () {
    'use strict';

    bindhq.nsIn('itemActions', {
        /**
         * @type {jQuery}
         */
        element: null,

        /**
         * @type {Array}
         */
        items: null,

        /**
         * @param {jQuery.Event} evt
         */
        onDismissClick: function (evt) {
            if (this.items.length === 1) {
                this.hideAll();
            } else {
                const element = $(evt.currentTarget);
                const item = element.closest('.item-action');
                const id = item.data('action-id');

                this.remove(id);

                item.slideUp(this.refresh);
            }
        },

        /**
         *
         */
        onDismissAllClick: function () {
            this.hideAll();
        },

        /**
         * @param {String} id
         */
        remove: function (id) {
            this.items = _.filter(this.items, function (item) {
                return item._id !== id;
            });

            if (this.items.length === 0) {
                this.reset();
            }
        },

        /**
         *
         */
        hideAll: function () {
            this.items = [];

            const properties = {
                bottom: this.element.outerHeight() * -1,
            };

            const options = {
                complete: this.reset,
                easing: 'easeOutCubic',
                duration: 500,
            };

            this.element.animate(properties, options);
        },

        /**
         *
         */
        reset: function () {
            this.items = [];
            this.refresh();
            this.element.removeClass('visible').removeAttr('style'); // added by jQuery animate
        },

        /**
         *
         */
        refresh: function () {
            const html = bindhq.util.template(bindhq.tpl.item_actions, {
                items: this.items,
            });

            this.element.empty().html(html).addClass('visible');

            bindhq.initContainer(this.element);
        },

        /**
         * @param {mixed} data
         *
         * @return {boolean}
         */
        isValid: function (data) {
            return _.isObject(data) && data.type;
        },

        /**
         * @param {jQuery} data
         */
        handle: function (data) {
            if (!this.isValid(data)) {
                return;
            }

            const id = 'AI-' + parseInt(Math.random() * 10000000, 10);
            const item = _.extend(data, { _id: id });

            this.items.push(item);
            this.refresh();

            return id;
        },

        /**
         * @param {String} id
         * @param {Object} data
         */
        update: function (id, data) {
            if (!this.isValid(data)) {
                return;
            }

            this.items = _.map(this.items, function (item) {
                return item._id === id ? _.extend(data, { _id: id }) : item;
            });

            this.refresh();
        },

        /**
         *
         */
        init: function () {
            this.items = [];
            this.element = $('<div class="item-actions"></div>').appendTo(
                'body',
            );
            this.element.on('click', '.dismiss', this.onDismissClick);
            this.element.on('click', '.dismiss-all', this.onDismissAllClick);
        },
    });
})();
