const { DateTime } = require('luxon');
const numeral = require('numeral');

(function () {
    'use strict';

    bindhq.nsIn('abacus.transactionMultiSelect', {
        /**
         * @param {String}
         */
        INVOICE: 'invoice',

        /**
         * @param {String}
         */
        CREDIT: 'credit-memo',

        /**
         * @param {String}
         */
        BILL: 'bill',

        /**
         * @param {String}
         */
        BILL_CREDIT: 'bill-credit',

        /**
         * @param {String}
         */
        JOURNAL_DEBIT: 'journal-debit-item',

        /**
         * @param {String}
         */
        JOURNAL_CREDIT: 'journal-credit-item',

        /**
         * @param {String}
         */
        RECEIPT: 'receipt',

        /**
         * @param {String}
         */
        PAYMENT: 'payment',

        /**
         * @param {String}
         */
        FORMAT: '(0,0.00)',

        /**
         * @param {String}
         */
        ZERO: '0.00',

        /**
         * @param {Object} checkboxes
         */
        getOffsetData: function (checkboxes) {
            return {
                abacus_offset: {
                    posted_at: DateTime.now().toFormat('y-MM-dd 00:00:00'),
                    customer: checkboxes[0].dataset.customerId,
                    transactions: _.map(checkboxes, function (checkbox) {
                        return {
                            transaction:
                                checkbox.dataset.transactionType +
                                ':' +
                                checkbox.dataset.transactionId,
                            amount: checkbox.dataset.amount,
                        };
                    }),
                },
            };
        },

        /**
         * @param {Object} evt
         */
        onOffsetClick: function (evt) {
            const qs = $.param(this.getOffsetData(evt.checkboxes));
            const url = '/secure/accounting/offsets/new?' + qs;

            bindhq.modalAjax.showUrl(url, {
                callback: function (modal) {
                    $('form', modal).modalAjax({
                        success: function () {
                            $(evt.checkboxes).each(function () {
                                $(this)
                                    .closest('td')
                                    .trigger(
                                        $.Event(
                                            'transaction_multi_select.offset.complete',
                                        ),
                                    );
                            });
                        },
                        error: function (error, xhr) {
                            $('.modal-footer input', modal)
                                .removeClass('btn-primary')
                                .addClass('btn-danger')
                                .val('Ooops, ' + error + '. Try again?');
                        },
                    });
                },
            });
        },

        /**
         * @param {Array} checkboxes
         *
         * @return {Boolean}
         */
        sameCustomer: function (checkboxes) {
            const byCustomer = _.groupBy(checkboxes, function (checkbox) {
                return checkbox.dataset.customerId;
            });

            return _.keys(byCustomer).length === 1;
        },

        /**
         * @param {Array} checkboxes
         * @param {Array} types
         *
         * @return {Array}
         */
        byType: function (checkboxes, types) {
            return _.filter(checkboxes, function (checkbox) {
                return types.includes(checkbox.dataset.transactionType);
            });
        },

        /**
         * @param {Array} checkboxes
         * @param {Array} types
         *
         * @return {Array}
         */
        anyOfType: function (checkboxes, types) {
            return this.byType(checkboxes, types).length !== 0;
        },

        /**
         * @param {Array} checkboxes
         * @param {Array} types
         *
         * @return {Array}
         */
        oneOfType: function (checkboxes, types) {
            return this.byType(checkboxes, types).length === 1;
        },

        /**
         * @param {String} url
         * @param {String} context
         * @param {Array} checkboxes
         */
        onActionClick: function (url, context, checkboxes) {
            const toId = function (checkbox) {
                return checkbox.dataset.transactionId;
            };

            const first = checkboxes[0];
            const ids = _.pluck(checkboxes, toId);

            location.href =
                url +
                '?abacus_' +
                context +
                '_context[customer]=' +
                first.dataset.customerId +
                '&detailId=' +
                ids.join(',');
        },

        /**
         * @param {Array} checkboxes
         * @param {Array} totals
         *
         * @return {Boolean}
         */
        isOffset: function (checkboxes, totals) {
            return (
                this.sameCustomer(checkboxes) &&
                !this.anyOfType(checkboxes, [this.RECEIPT, this.PAYMENT]) &&
                totals.receivable.value() !== 0 &&
                totals.payable.value() !== 0
            );
        },

        /**
         * @param {Array} checkboxes
         * @param {Array} totals
         *
         * @return {Boolean}
         */
        isPayment: function (checkboxes, totals) {
            return (
                this.sameCustomer(checkboxes) &&
                totals.payable > totals.receivable
            );
        },

        /**
         * @param {Array} checkboxes
         * @param {Array} totals
         *
         * @return {Boolean}
         */
        isReceipt: function (checkboxes, totals) {
            return (
                this.sameCustomer(checkboxes) &&
                totals.receivable > totals.payable
            );
        },

        /**
         * @param {Array} checkboxes
         * @param {Array} totals
         *
         * @return {Boolean}
         */
        isThirdPartyPayment: function (checkboxes, totals) {
            return (
                checkboxes.length === 2 &&
                !this.sameCustomer(checkboxes) &&
                this.oneOfType(checkboxes, this.INVOICE) &&
                this.oneOfType(checkboxes, this.BILL)
            );
        },

        /**
         * @param {Array} checkboxes
         * @param {Object} totals
         *
         * @return {Object}
         */
        makeOffsetAction: function (checkboxes, totals) {
            if (this.isOffset(checkboxes, totals)) {
                return {
                    label: 'Offset',
                    click: this.onOffsetClick,
                };
            }
        },

        /**
         * @param {Array} checkboxes
         * @param {Object} totals
         */
        makeReceiptAction: function (checkboxes, totals) {
            if (this.isReceipt(checkboxes, totals)) {
                return {
                    label: 'Record Receipt',
                    click: _.partial(
                        this.onActionClick,
                        '/secure/accounting/receipts/new',
                        'receipt',
                        checkboxes,
                    ),
                };
            }
        },

        /**
         * @param {Array} checkboxes
         * @param {Object} totals
         */
        makePaymentAction: function (checkboxes, totals) {
            if (this.isPayment(checkboxes, totals)) {
                return {
                    label: 'Make Payment',
                    click: _.partial(
                        this.onActionClick,
                        '/secure/accounting/payments/new',
                        'payment',
                        checkboxes,
                    ),
                };
            }
        },

        /**
         * @param {Array} checkboxes
         * @param {Object} totals
         * @param {jQuery} multiSelect
         *
         * @return {Object}
         */
        makeThirdPartyPaymentAction: function (
            checkboxes,
            totals,
            multiSelect,
        ) {
            if (this.isThirdPartyPayment(checkboxes, totals)) {
                const getTransaction = function (transactionType) {
                    return _.chain(checkboxes)
                        .filter(function (checkbox) {
                            return (
                                transactionType ===
                                checkbox.dataset.transactionType
                            );
                        })
                        .first()
                        .value().dataset.transactionId;
                };

                const invoiceId = getTransaction(this.INVOICE);
                const billId = getTransaction(this.BILL);

                return {
                    label: 'Process 3rd Party Payment',
                    click: function () {
                        const url =
                            '/secure/policies/' +
                            multiSelect.data('policyId') +
                            '/3rd-party-payments/new?invoice_id=' +
                            invoiceId +
                            '&bill_id=' +
                            billId;

                        bindhq.modalAjax.showUrl(url);
                    },
                };
            }
        },

        /**
         * @param {Array} checkboxes
         * @param {Object} totals
         * @param {jQuery} multiSelect
         *
         * @return {Array}
         */
        actionsFor: function (checkboxes, totals, multiSelect) {
            const all = [
                this.makeReceiptAction,
                this.makePaymentAction,
                this.makeOffsetAction,
                this.makeThirdPartyPaymentAction,
            ];

            return _.chain(all)
                .map(function (action) {
                    return action(checkboxes, totals, multiSelect);
                })
                .filter(function (action) {
                    return action;
                })
                .value();
        },

        /**
         * @param {Array} checkboxes
         * @param {Array} types
         *
         * @return {Numeral}
         */
        totalFor: function (checkboxes, types) {
            const isType = function (node) {
                const amount = numeral(node.dataset.amount).value();

                return (
                    (types.includes(node.dataset.transactionType) &&
                        amount > 0) ||
                    (!types.includes(node.dataset.transactionType) &&
                        amount < 0)
                );
            };

            const sumValues = function (total, value) {
                return value.value() >= 0
                    ? total.add(value)
                    : total.subtract(value);
            };

            const toNumeral = function (node) {
                return numeral(node.dataset.amount);
            };

            return _.chain(checkboxes)
                .filter(isType)
                .map(toNumeral)
                .reduce(sumValues, numeral(0))
                .value();
        },

        /**
         * @param {jQuery} container
         * @param {Object} selected
         * @param {jQuery.Event} evt
         */
        onCheckboxClick: function (container, selected, evt) {
            const checkbox = $(evt.currentTarget);
            const transactionId = checkbox.data('transactionId');

            if (checkbox.length !== 0 && checkbox.prop('checked')) {
                selected[transactionId] = checkbox.get(0);
            } else {
                delete selected[transactionId];
            }

            container.trigger(
                $.Event('transaction_multi_select.selection_changed'),
            );
        },

        /**
         * @param {Numeral} value
         *
         * @return {String}
         */
        formatPositive: function (value) {
            return numeral(Math.abs(value.value())).format(this.FORMAT);
        },

        /**
         * @param {jQuery} multiSelect
         * @param {Object} selected
         */
        onSelectionChanged: function (multiSelect, selected) {
            const actions = $('.actions', multiSelect).empty();
            const checkboxes = _.values(selected);

            if (_.empty(checkboxes)) {
                multiSelect.removeClass('multi-select-visible');
            } else {
                multiSelect
                    .addClass('multi-select-visible')
                    .removeClass('multi-select-error');

                const totalFor = _.partial(this.totalFor, checkboxes);
                const totals = {
                    receivable: totalFor([
                        this.INVOICE,
                        this.BILL_CREDIT,
                        this.JOURNAL_DEBIT,
                    ]),
                    payable: totalFor([
                        this.CREDIT,
                        this.BILL,
                        this.JOURNAL_CREDIT,
                    ]),
                };

                const allActions = this.actionsFor(
                    checkboxes,
                    totals,
                    multiSelect,
                );

                const handler = function (action) {
                    return function (evt) {
                        action.click({
                            originalEvent: evt,
                            checkboxes: checkboxes,
                            totals: totals,
                        });
                    };
                };

                const diff = numeral(totals.receivable.value()).subtract(
                    totals.payable.value(),
                );

                $('label', multiSelect).html(
                    '$' +
                        this.formatPositive(totals.receivable) +
                        ' Receivable - ' +
                        '$' +
                        this.formatPositive(totals.payable) +
                        ' Payable - ' +
                        '$' +
                        this.formatPositive(diff) +
                        ' Net ' +
                        (diff.value() >= 0 ? 'Receivable' : 'Payable'),
                );

                if (allActions.length === 1) {
                    const action = allActions[0];

                    $('<input type="button" />')
                        .addClass('btn btn-grey btn-primary-soft')
                        .val(action.label)
                        .click(handler(action))
                        .appendTo(actions);
                } else if (allActions.length > 1) {
                    const btn = $('<a></a>')
                        .addClass(
                            'btn btn-grey btn-primary-soft dropdown-toggle',
                        )
                        .data('toggle', 'dropdown')
                        .attr('data-toggle', 'dropdown')
                        .html('With these transactions...');

                    const list = $('<ul></ul>').addClass('dropdown-menu');

                    const group = $('<div></div>')
                        .addClass('btn-group pull-right pull-top')
                        .append(btn)
                        .append(list);

                    _.each(allActions, function (action) {
                        const anchor = $('<a>' + action.label + '</a>').click(
                            handler(action),
                        );

                        $('<li></li>').append(anchor).appendTo(list);
                    });

                    group.appendTo(actions);
                }
            }
        },

        /**
         * @param {jQuery} container
         * @param {Object} selected
         */
        onClear: function (container, selected) {
            const keys = _.keys(selected);
            const vals = _.values(selected);

            $(vals).prop('checked', false);

            _.each(keys, function (key) {
                delete selected[key];
            });

            container.trigger(
                $.Event('transaction_multi_select.selection_changed'),
            );
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            const selected = {};
            const multiSelectTpl = $('#transaction-multi-select').html();
            const multiSelectHtml = bindhq.util.template(multiSelectTpl, {});
            const multiSelect = $(multiSelectHtml).appendTo(container);

            const onCheckboxClick = _.partial(
                this.onCheckboxClick,
                container,
                selected,
            );

            const onSelectionChanged = _.partial(
                this.onSelectionChanged,
                multiSelect,
                selected,
            );

            const onClear = _.partial(this.onClear, container, selected);

            container.on(
                'change',
                '.transaction-multi-select-action',
                onCheckboxClick,
            );

            container.on(
                'transaction_multi_select.selection_changed',
                _.debounce(onSelectionChanged, 250),
            );

            container.on(
                'control_section.cleared, transaction_multi_select.offset.complete',
                onClear,
            );

            container.on(
                'click',
                '.btn-cancel',
                bindhq.util.noDefault(onClear),
            );
        },
    });
})();
