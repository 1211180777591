const progress = require('../../../../../../../assets/js/BindHQ/Utils/Progress');
const md5 = require('../3rdparty/js/md5.js');

(function () {
    'use strict';

    bindhq.nsIn('search', {
        /**
         * @type {Array}
         */
        types: [
            'agencies',
            'applications',
            'carriers',
            'policies',
            'users',
            'marketingcompanies',
            'suppliers',
            'payments',
            'receipts',
            'taxauthorities',
            'all',
        ],

        /**
         * @type {Object}
         */
        filters: {
            producer: 'producer-id',
            inactive: 'inactive',
        },

        /**
         * @type {Number}
         */
        perPage: 20,

        /**
         * @type {Object}
         */
        cache: {},

        /**
         * @type {Object}
         */
        mode: null,

        /**
         * @type {jQuery}
         */
        placeholder: $('.live-search'),

        /**
         * @type {String}
         */
        filterForm: $('#search-filter-form').text(),

        /**
         * @param {jQuery} q
         * @param {jQuery} filters
         * @return {Object}
         */
        makeQuery: function (q, filters, filterTypes) {
            return {
                /**
                 * @return {boolean}
                 */
                hasParams: function () {
                    return q.val();
                },

                /**
                 * @return {String}
                 */
                q: function () {
                    return q.val();
                },

                filters: function () {
                    return _.reduce(
                        $('input[name]', filters),
                        function (acc, field) {
                            const key = $(field).attr('name');
                            const type = filterTypes[key];
                            let val;

                            if ($(field).prop('type') === 'checkbox') {
                                val = $(field).prop('checked') ? 'yes' : 'no';
                            } else {
                                val = $(field).val();
                            }

                            if (val && type) {
                                const obj = {};
                                obj[type] = val;
                                acc.push(obj);
                            }
                            return acc;
                        },
                        [],
                    );
                },
            };
        },

        /**
         * @param {jQuery} container
         * @param {Object} query
         */
        renderSuggestions: function (container, query) {
            const body = $('body');

            body.addClass('live-search-visible');

            const onClickSuggestion = _.partial(
                this.loadSearch,
                container,
                query,
            );
            const html = bindhq.util.template(bindhq.tpl.search_suggestions, {
                recentSearches: this.loadLocalData('recentSearches'),
                recentApplications: this.loadLocalData('recentApplications'),
                recentPolicies: this.loadLocalData('recentPolicies'),
            });

            $('.results', this.placeholder).html(html);

            $('.suggested-search', this.placeholder).on(
                'click',
                onClickSuggestion,
            );
        },

        /**
         * @param {jQuery} container
         * @param {Object} query
         */
        startSuggestions: function (container, query) {
            if (!query.hasParams()) {
                $('body').addClass(
                    'live-search-visible live-search-suggestions',
                );

                this.renderSuggestions(container, query);
            }
        },

        /**
         * @param {jQuery} container
         * @param {Object} query
         */
        startSearch: function (container, query) {
            const queryId = this.queryId(query);

            location.hash = 'search:' + queryId;

            progress.start();

            const config = {
                url: this.mode.url,
                data: _.extend(
                    {
                        'min-score': bindhq.MIN_SEARCH_SCORE,
                        q: query.q(),
                    },
                    this.mode.options,
                ),
                success: _.partial(this.onSearchSuccess, container, query),
                complete: _.partial(this.onSearchComplete, container),
            };

            const filters = query.filters();
            _.each(filters, function (filter) {
                config.data = _.extend(filter, config.data);
            });

            if (this.xhr) {
                this.xhr.abort();
            }

            this.xhr = bindhq.util.ajax(config);

            this.storeSearch(container, query.q());
        },

        /**
         */
        expandPerfectMatch: function () {
            const row = $('.direct-hit', this.placeholder).closest(
                '.expandable-row',
            );
            const container = row.closest('.search-all-results');

            container.find('[data-toggle="collapse"]').click();
            row.click();
        },
        /**
         * @param {Object} query
         *
         * @return {String}
         */
        queryId: function (query) {
            return query.q() + ':' + this.mode.type;
        },

        /**
         *
         */
        cancelSearch: function () {
            location.hash = '';

            $('.search-query').val('');

            $('body').removeClass('live-search-visible');
        },

        /**
         * @param {jQuery} container
         * @param {Object} query
         */
        onSearch: function (container, query) {
            if (container.is(':visible')) {
                const object = {
                    type: this.mode.type,
                    q: query.q().trim().toLowerCase(),
                    filters: query.filters(),
                };
                const hash = md5(JSON.stringify(object));
                const oldHash = bindhq.search.oldHash;

                if (oldHash && oldHash === hash) {
                    return;
                }

                bindhq.search.oldHash = hash;

                if (query.hasParams()) {
                    this.mode.page = 1;
                    this.startSearch(container, query);
                } else {
                    this.cancelSearch();
                }
            }
        },

        /**
         * @param {jQuery} container
         * @param {Object} query
         */
        onSearchSuccess: function (container, query, html) {
            $('.results', this.placeholder).html(html);
            $('.table-listing', this.placeholder).each(
                bindhq.tableListing.init,
            );

            this.initPagination();

            $('body').addClass('live-search-visible');
        },

        /**
         * @param {jQuery} container
         */
        onSearchComplete: function (container) {
            progress.stop();

            this.xhr = null;

            $('body').removeClass('live-search-suggestions');

            this.expandPerfectMatch();
        },

        /**
         * @param {jQuery} container
         * @param {jQuery} query
         */
        onTypeChanged: function (container, query) {
            const selector = $('select.type-selector', container);
            const input = $('.search-query', container);
            const url = selector.data('filter-url');
            const selected = $(':selected', selector);
            const action = selected.data('search-action');
            const placeholder = selected.data('search-placeholder');
            const type = $(':selected', selector).val();
            const filters = $('.filters', this.placeholder);

            $('input', filters).remove();

            filters.html(bindhq.search.filterForm);

            $('.form-group', filters).each(function () {
                const entities = $(this).data('search-entities');

                if (entities && !entities.split(',').includes(type)) {
                    $(this).remove();
                }
            });

            container.data('search-url', action);
            container.data('search-type', type);
            this.mode = this.getMode(container);
            if (query && query.hasParams()) {
                this.onSearch(container, query);
            }

            input.attr('placeholder', placeholder);

            this.storeLocalData('selectedType', type, 1);
        },

        /**
         * @param {jQuery} container
         * @param {Object} query
         */
        onPageChange: function (container, query) {
            if (container.is(':visible')) {
                this.mode.options.offset =
                    parseInt(this.mode.page - 1) * this.perPage;
                this.startSearch(container, query);
            }
        },

        /**
         */
        onNextPage: function (e) {
            this.mode.page = this.mode.page + 1;
            this.placeholder.trigger('pagination:updated');
        },

        /**
         */
        onPreviousPage: function (e) {
            this.mode.page = this.mode.page - 1;
            this.placeholder.trigger('pagination:updated');
        },

        onLoad: function () {
            const body = $('body');
            if (undefined !== body.data('page-type')) {
                const name = body.data('page-name');
                switch (body.data('page-type')) {
                    case 'application':
                        this.storePage(
                            'recentApplications',
                            name,
                            $('.clippy').data('clipboardText'),
                        );
                        break;

                    case 'policy':
                        this.storePage(
                            'recentPolicies',
                            name,
                            $('.clippy').data('clipboardText'),
                        );
                        break;
                }
            }
        },

        /**
         * @return {Object}
         */
        getMode: function (container) {
            const type = container.data('search-type');
            const obj = {
                type: type,
                url: container.data('search-url'),
                page: 1,
                options: {
                    limit: this.perPage,
                    offset: 0,
                },
            };
            return this.addOptionsByType(obj, type);
        },

        /**
         * @return {Object}
         */
        addOptionsByType: function (obj, type) {
            switch (type) {
                case 'invoicepayments':
                    obj.options = _.extend({ 'payment-type': 1 }, obj.options);
                    break;
                case 'vendorpayments':
                    obj.options = _.extend({ 'payment-type': 2 }, obj.options);
                    break;
                case 'payments':
                    obj.options = _.extend({ 'payment-type': 3 }, obj.options);
                    break;
                case 'receipts':
                    obj.options = _.extend({ 'payment-type': 4 }, obj.options);
                    break;
            }
            return obj;
        },

        /**
         * @param {jQuery} container
         */
        onCancelClick: function (container) {
            $('.search-query', container).val('');
            bindhq.search.oldHash = null;

            this.mode = this.getMode(container);
            this.cancelSearch();
        },

        paramFromStorage: function (content, idx) {
            if (typeof content === 'string') {
                const arr = content.split(';');
                return arr[idx] ? arr[idx] : '';
            }
        },

        storePage: function (key, name, info) {
            let item = name + ';' + window.location.pathname;
            if (info) {
                item += ';' + info;
            }
            this.storeLocalData(key, item, 10);
        },

        storeSearch: function (container, query) {
            const key = 'recentSearches';
            const item = query + ';' + container.data('search-type');
            this.storeLocalData(key, item, 10);
        },

        loadLocalData: function (key) {
            if (bindhq.localStorage.isSupported()) {
                const searchData =
                    bindhq.localStorage.getObject('bindhq.searchData') || {};
                return searchData[key] || [];
            }
        },

        storeLocalData: function (key, item, maxItems) {
            if (bindhq.localStorage.isSupported()) {
                const searchData =
                    bindhq.localStorage.getObject('bindhq.searchData') || {};
                const keyData = searchData[key] || [];

                const idx = $.inArray(item, keyData);
                if (idx !== -1) {
                    keyData.splice(idx, 1);
                }
                keyData.unshift(item);
                if (keyData.length > maxItems) {
                    keyData.length = maxItems;
                }
                searchData[key] = keyData;
                bindhq.localStorage.setObject('bindhq.searchData', searchData);
            } else {
                console.debug('No Local Storage Support');
            }
        },

        loadSearch: function (container, query, evt) {
            evt.preventDefault();
            const search = $(evt.target).data('search');
            const type = $(evt.target).data('type');
            const inputQuery = $('.search-query', container);
            const typeSelector = $('select.type-selector', container);

            inputQuery.val(search);
            typeSelector.select2('val', type);
            this.onTypeChanged(container, query);
        },

        /**
         */
        initPagination: function () {
            const pager = $('.pager-container', this.placeholder);

            const onNextPage = _.partial(this.onNextPage, pager);
            const onPreviousPage = _.partial(this.onPreviousPage, pager);

            pager.on('click', '.btn-pagination-next', onNextPage);
            pager.on('click', '.btn-pagination-previous', onPreviousPage);
        },

        initType: function (container) {
            const defaultType = this.loadLocalData('selectedType');
            if (
                defaultType.length &&
                this.types.indexOf(defaultType[0]) !== -1
            ) {
                const selector = $('select.type-selector', container);
                selector.val(defaultType);
            }
        },

        initSearch: function (container) {
            const typeSelector = $('select.type-selector', container);
            const inputQuery = $('.search-query', container);
            const matches = location.hash.match('search:(.+):(.+)');
            if (matches) {
                inputQuery.val(decodeURIComponent(matches[1]));
                typeSelector.val(matches[2]);
            }
            this.onTypeChanged(container, null);
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            container = container.find('.form-search');
            const searchContainer = $('.live-search');
            const inputQuery = $('.search-query', container);
            const inputFilters = $('.filters', searchContainer);
            const query = this.makeQuery(
                inputQuery,
                inputFilters,
                this.filters,
            );

            const onFocus = _.partial(this.startSuggestions, container, query);
            const onSearch = _.partial(this.onSearch, container, query);
            const onCancel = _.partial(this.onCancelClick, container);
            const onTypeChanged = _.partial(
                this.onTypeChanged,
                container,
                query,
            );
            const onPageChange = _.partial(this.onPageChange, container, query);

            this.initType(container);
            this.initSearch(container);

            container.on('focus', '.search-query', _.debounce(onFocus, 500));

            container.on('keydown', '.search-query', _.debounce(onSearch, 500));

            inputFilters.on('change', 'input', _.debounce(onSearch, 500));

            searchContainer.on(
                'pagination:updated',
                _.debounce(onPageChange, 500),
            );

            container.on('change', '.type-selector', onTypeChanged);

            searchContainer.on(
                'refreshPage',
                '.search-results-table table',
                onSearch,
            );

            $('body').on('bindhq.division_switched', onSearch);

            $('<a></a>')
                .html('Cancel')
                .addClass('btn btn-danger btn-cancel btn-small')
                .click(onCancel)
                .appendTo(container);

            if (location.hash && query.hasParams()) {
                this.startSearch(container, query);
            }

            $(window).hashchange(
                function () {
                    if (!location.hash) {
                        this.cancelSearch();
                    }
                }.bind(this),
            );

            this.onLoad();
        },
    });
})();
