(function () {
    'use strict';

    bindhq.ns('iscrollTableSorter');

    bindhq.iscrollTableSorter.resortTable = function (container) {
        container.trigger('update', [false]);
    };

    bindhq.iscrollTableSorter.init = function () {
        const container = $(this);

        const onDataLoaded = _.partial(
            bindhq.iscrollTableSorter.resortTable,
            container,
        );

        container.on('scroll:dataLoaded', onDataLoaded);
    };
})();
