(function () {
    'use strict';

    bindhq.ns('forms.activity');

    bindhq.forms.activity.getSubjectDropdown = function (container) {
        return $('.subject', container);
    };

    bindhq.forms.activity.getAgencyDropdown = function (container) {
        return $('.agency', container);
    };

    bindhq.forms.activity.getAgentsDropdown = function (container) {
        return $('.agent', container);
    };

    bindhq.forms.activity.getCallValues = function (container) {
        const subjectDropdown =
            bindhq.forms.activity.getSubjectDropdown(container);
        const options = $('option', subjectDropdown);
        const callValues = [];
        const testString = 'call ';
        let i = 0;
        const optionsLength = options.length;

        for (; i < optionsLength; i += 1) {
            const option = $(options[i]);
            const optionText = option.text().toLowerCase();
            if (optionText.substring(0, testString.length) === testString) {
                callValues.push(option.val());
            }
        }

        return callValues;
    };

    bindhq.forms.activity.setAgentImpact = function (value, container) {
        const callValues = bindhq.forms.activity.getCallValues(container);

        const agentImpact = $('.agent-impact', container);
        if (callValues.indexOf(value) !== -1) {
            agentImpact.closest('.hidden').show();
        } else {
            agentImpact.closest('.hidden').hide();
        }
    };

    bindhq.forms.activity.subjectHandler = function (container, e) {
        const subjectDropdown = $(e.target);

        const value = subjectDropdown.select2('val');
        bindhq.forms.activity.setAgentImpact(value, container);
    };

    bindhq.forms.activity.error = function (
        modal,
        form,
        jqXHR,
        textStatus,
        errorThrown,
    ) {
        const data = $.parseJSON(jqXHR.responseText);

        $('.modal-footer .alert-placeholder', modal).html(
            '<div class="alert alert-error"></div>',
        );
        $('.modal-footer .alert', modal).html(data.message);

        $('.btn-submit', modal).removeClass('disabled');
    };

    bindhq.forms.activity.onSubmit = function (container, modal, form) {
        if (form.get(0).checkValidity()) {
            const submitButton = $('.btn-submit', modal);

            if ($(submitButton).prop('disabled') === false) {
                const url = $(form).attr('action');

                const error = _.partial(
                    bindhq.forms.activity.error,
                    modal,
                    form,
                );

                const config = {
                    type: 'POST',
                    url: url,
                    data: $(form).serialize(),
                    success: bindhq.modalAjax.helper.defaultOnSuccess,
                    error: error,
                };

                $.ajax(config);
            }

            submitButton.prop('disabled', true);
        } else {
            form.submit();
        }
    };

    bindhq.forms.activity.updateAgentUrl = function (agency, agents, agencyId) {
        agents.prop('disabled', false);
        agents.data('ajaxurl', function () {
            return agents
                .get(0)
                .dataset.ajaxurlPattern.replace(/{agencyId}/, agencyId);
        });
    };

    bindhq.forms.activity.initAgencyAgents = function (container) {
        const agency = bindhq.forms.activity.getAgencyDropdown(container);
        const agents = bindhq.forms.activity.getAgentsDropdown(container);

        bindhq.forms.ajaxselect.dependsOn(
            agency,
            agents,
            bindhq.forms.activity.updateAgentUrl,
        );
    };

    bindhq.forms.activity.init = function (container) {
        const subjectDropdown =
            bindhq.forms.activity.getSubjectDropdown(container);
        const modal = container.closest('.modal-activity-new');
        const modalForm = $('form', modal);

        const subjectHandler = _.partial(
            bindhq.forms.activity.subjectHandler,
            container,
        );
        $(subjectDropdown).on('change', subjectDropdown, subjectHandler);

        bindhq.forms.activity.setAgentImpact(
            subjectDropdown.select2('val'),
            container,
        );
        bindhq.forms.activity.initAgencyAgents(container);

        const onSubmit = _.partial(
            bindhq.forms.activity.onSubmit,
            container,
            modal,
            modalForm,
        );

        modal.on('click', '.btn-submit', function (e) {
            e.preventDefault();
            onSubmit();
        });
    };
})();
