(function () {
    'use strict';

    bindhq.ns('lesson');

    /**
     * @param {jQuery} container
     */
    bindhq.lesson.resetMedia = function (container) {
        const elem = $('.tab-pane', container).not('.active').find('iframe');

        const src = elem.attr('src');
        $(elem).attr('src', '');
        $(elem).attr('src', src);
    };

    /**
     * @param {jQuery} container
     */
    bindhq.lesson.init = function (container) {
        const resetMedia = _.partial(bindhq.lesson.resetMedia, container);
        $('[data-toggle="tab"]', container).on('shown', resetMedia);

        $(container).find('.tab-pane iframe').not('.active');
    };
})();
