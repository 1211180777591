(function () {
    'use strict';

    bindhq.nsIn('menuToggle', {
        /**
         * @param {jQuery.EVent} evt
         */
        onClick: function (evt) {
            const source = $(evt.currentTarget);
            const targetId = source.data('target');
            const target = $(targetId);
            $('.menu-toggle-content:not(' + targetId + ')').fadeOut();
            target.fadeToggle();
        },

        /**
         *
         */
        init: function (container) {
            container.on('click', this.onClick);
        },
    });
})();
