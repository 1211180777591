(function () {
    'use strict';

    bindhq.ns('modalAjax');
    bindhq.ns('modalAjax.helper');

    bindhq.modalAjax.init = function () {
        const container = $(this);

        bindhq.modalAjax.initContainer(container);
    };

    bindhq.modalAjax.initContainer = function (container, options) {
        const onClick = _.partial(bindhq.modalAjax.onClick, options);

        container.click(onClick);
    };

    bindhq.modalAjax.disableDropDown = function () {
        $('.ajax-select').select2('close');
    };

    /**
     * @param {String} url
     * @param {Object} options
     */
    bindhq.modalAjax.showUrl = function (url, options) {
        options = options || {};

        if (url.indexOf('#') === 0) {
            $(url).sizedModal('show');
        } else {
            const onLoad = _.partial(bindhq.modalAjax.onLoad, options);

            bindhq.loader.start(options.button);

            $.get(url, onLoad)
                .done(bindhq.modalAjax.success)
                .fail(function (xhr) {
                    if (xhr.status === 403) {
                        location.reload(); // user has been logged out
                    } else {
                        alert('There was an error completing your request.');
                    }
                })
                .always(_.partial(bindhq.modalAjax.complete, options));
        }
    };

    bindhq.modalAjax.complete = function (options) {
        bindhq.loader.stop(options.button);

        if (options.success) {
            options.success();
        }
    };

    bindhq.modalAjax.onClick = function (options, evt) {
        options = options || {};

        const anchor = $(evt.currentTarget);
        const url = anchor.attr('href');

        if (anchor.data('progress') !== 'mask') {
            options.button = anchor;
        }

        if (url) {
            bindhq.modalAjax.showUrl(url, options);

            return false;
        }

        console.debug(
            'Could not find URL for anchor at currentTarget: ' +
                bindhq.util.getPathTo(evt.currentTarget),
        );

        return true;
    };

    bindhq.modalAjax.onLoad = function (options, data) {
        const modal = $(data);

        if (options && options.callback) {
            options.callback(modal);
        }

        modal.sizedModal(options);

        const modalContainer = $(modal).closest('.modal');

        bindhq.initContainer(modalContainer);

        bindhq.modalAjax.helper.destroyModalWhenClosed(modalContainer);
        bindhq.modalAjax.helper.initModalDetectScroll(modal);
    };

    bindhq.modalAjax.success = function () {
        bindhq.loader.hide();
    };

    bindhq.modalAjax.helper.initModalDetectScroll = function (modal) {
        $(modal).find('.modal-body').scroll(bindhq.modalAjax.disableDropDown);
    };

    bindhq.modalAjax.helper.reloadWhenClosed = function (modal) {
        $(modal).on(bindhq.modalAjax.helper.hiddenModalSelector(), function () {
            // @TODO prevent any clicks from being propagated to the window
            window.location.reload();
        });
    };

    bindhq.modalAjax.helper.showAlert = function (alertType, modal, message) {
        $('.modal-footer .alert-placeholder', modal).html(
            '<div class="alert alert-' + alertType + '"></div>',
        );
        $('.modal-footer .alert-placeholder .alert', modal)
            .html(message)
            .animate({ backgroundColor: '#FFFFFF' }, 'slow');
    };

    bindhq.modalAjax.helper.removeAlert = function (modal) {
        $('.modal-footer .alert-placeholder .alert', modal).remove();
    };

    bindhq.modalAjax.helper.clearForm = function (form) {
        $(':input:not(.not-removable)', form).val('');
    };

    bindhq.modalAjax.helper.lockForm = function (form, submitButton) {
        bindhq.modalAjax.helper.toggleForm(form, submitButton, true);
    };

    bindhq.modalAjax.helper.unlockForm = function (form, submitButton) {
        bindhq.modalAjax.helper.toggleForm(form, submitButton, false);
    };

    bindhq.modalAjax.helper.toggleForm = function (
        form,
        submitButton,
        isLocked,
    ) {
        const toggler = isLocked ? 'hide' : 'show';

        $(':input', form).attr('disabled', isLocked);
        $('.btn.delete', form)[toggler]();
        $('.btn.fileviewer-add', form).toggleClass('disabled');
        $('.fileviewer-list', form).attr('disabled', isLocked);
        $('.ajax-select', form).select2(isLocked ? 'disable' : 'enable');
        $(submitButton).toggleClass('disabled');
    };

    bindhq.modalAjax.helper.lockUploader = function (modal) {
        $('.fileviewer-list', modal)
            .css('visibility', 'hidden')
            .attr('disabled', true); // not 'hide()' to stop modal changing size
        $('.fileviewer-add', modal).addClass('disabled');
    };

    bindhq.modalAjax.helper.replaceFormWith = function (form, newFormHtml) {
        // @TODO when replace the form, capture the textarea width + height
        // and then apply these dimensions to the new textareas (in same index order)
        //
        // It seems this is not a problem on 'transfer producer' form. Investigate
        // if this is fixed or only happens on some forms.

        const newForm = $(newFormHtml);

        form.replaceWith(newForm);

        bindhq.forms.init.apply(newForm);
    };

    bindhq.modalAjax.helper.resetFormWhenClosed = function (modal) {
        $(modal).on(bindhq.modalAjax.helper.hiddenModalSelector(), function () {
            bindhq.modalAjax.helper.clearForm(modal);
            $('.alert-placeholder', modal).html('');
            $('.fileviewer-add', modal).removeClass('disabled');
            $('.fileviewer-list', modal)
                .css('visibility', 'inherit')
                .attr('disabled', false);
        });
    };

    bindhq.modalAjax.helper.destroyModalWhenClosed = function (modalContainer) {
        modalContainer.on(
            bindhq.modalAjax.helper.hiddenModalSelector(),
            function () {
                modalContainer.remove();
            },
        );
    };

    bindhq.modalAjax.helper.getErrorMessage = function (responseText) {
        let message;
        let form;

        try {
            const data = $.parseJSON(responseText);
            message = data.message;
            form = data.form;
        } catch (err) {
            message = 'Error';
        }

        return {
            message: message,
            form: form,
        };
    };

    bindhq.modalAjax.helper.submitForm = function (form) {
        form.submit();
    };

    bindhq.modalAjax.helper.ajaxFormDetachSubmitBinding = function (form) {
        const modal = $(form).closest('.modal');

        modal.off('click', '.btn-submit');
    };

    bindhq.modalAjax.helper.ajaxFormAttachSubmitBinding = function (
        form,
        callback,
    ) {
        const modal = $(form).closest('.modal');

        modal.on('click', '.btn-submit', bindhq.util.noDefault(callback));
    };

    bindhq.modalAjax.helper.hiddenModalSelector = function () {
        return 'hidden.bs.modal';
    };

    bindhq.modalAjax.helper.defaultOnSuccess = function () {
        location.reload(true);
    };

    bindhq.modalAjax.helper.onSave = function (container, onSuccess, xhr) {
        const errors = $('.error:visible', container);

        if (errors.length === 0) {
            (onSuccess || bindhq.modalAjax.helper.defaultOnSuccess)(container);
        } else {
            bindhq.modalAjax.helper.toggleEnabled(container);
        }
    };

    bindhq.modalAjax.helper.onError = function (container, xhr) {
        const modal = container.parents('.modal');
        const error = JSON.parse(xhr.responseText);

        bindhq.modalAjax.helper.showAlert('error', modal, error.message);
        bindhq.modalAjax.helper.toggleEnabled(container);
    };

    bindhq.modalAjax.helper.toggleEnabled = function (container) {
        $('input[type=submit]', container).toggleAttr('disabled', 'disabled');
    };
})();
