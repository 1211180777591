(function () {
    'use strict';

    bindhq.nsIn('productionReport', {
        hidePart: function (container, op) {
            $(container)
                .find('.collection-user-team')
                .last()
                .addClass('row-' + op);
        },

        itemCount: function (collectionContainer) {
            return collectionContainer.find(
                bindhq.forms.collection.inline.ITEM_SELECTOR,
            ).length;
        },

        setDefaultName: function (container, op) {
            $('#create-report-button').prop('disabled', false);

            const self = this;
            const input = $(container).find(
                '.span-' + op + ' input.ajax-select',
            );
            const content = $(container)
                .parents()
                .eq(2)
                .find('input[type=text]:first');
            const collectionContainer = $(container).find(
                '.control-group:first',
            );

            $(input).on('change', function () {
                const index = self.itemCount(collectionContainer);

                if (index === 1) {
                    if (op === 'user') {
                        $(content).val($(container).find('span:first').text());
                    } else {
                        $(content).val($(container).find('span:eq(2)').text());
                    }
                }
            });
        },

        initContainer: function (container) {
            const onAddUser = _.partial(this.hidePart, container, 'user');
            const onAddTeam = _.partial(this.hidePart, container, 'team');
            const onAddUser2 = _.partial(
                this.setDefaultName,
                container,
                'user',
            );
            const onAddTeam2 = _.partial(
                this.setDefaultName,
                container,
                'team',
            );

            $(container).on('click', '.addUser a', onAddUser);

            $(container).on('click', '.addTeam a', onAddTeam);

            $(container).on('click', '.addUser a', onAddUser2);

            $(container).on('click', '.addTeam a', onAddTeam2);
        },

        init: function (container) {
            if ($('.collection-item', container).length > 0) {
                $('#create-report-button').prop('disabled', false);
            }
            if (
                $(
                    '.collection-inline[data-collection-prefix="items"]',
                    container,
                ).length > 0
            ) {
                $('.collection-inline[data-collection-prefix="items"]').each(
                    function (i, elem) {
                        bindhq.productionReport.setDefaultName(elem, 'user');
                        bindhq.productionReport.setDefaultName(elem, 'team');
                    },
                );
            }
        },
    });
})();
