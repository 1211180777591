(function () {
    'use strict';

    bindhq.nsIn('csv', {
        arrayToCsv: function (data) {
            const quote = function (value) {
                const str =
                    typeof value === 'undefined' || value === null ? '' : value;

                return (
                    '"' +
                    str
                        .replace(/\s+/g, ' ')
                        .trim()
                        .replace(new RegExp('"', 'g'), '""') +
                    '"'
                );
            };

            let csv = '';
            const EOL = '\n';

            _.each(data, function (datum) {
                csv += datum.map(quote).join(',') + EOL;
            });

            return csv;
        },

        /**
         * @param {jQuery} container
         * @param {String} rowSelector
         *
         * @return {String}
         */
        tableToCsv: function (container, rowSelector) {
            const csv = [];

            $(rowSelector || 'tr', container).each(function () {
                const toData = function (node) {
                    if (undefined !== node.dataset.csvValue) {
                        return node.dataset.csvValue;
                    }

                    if (
                        node.childNodes.length === 1 &&
                        node.childNodes[0].nodeName === '#text'
                    ) {
                        return node.innerText || node.innerHTML;
                    }

                    const currency = $('.currency', node);

                    if (currency.length === 1) {
                        return currency.text();
                    }

                    return '';
                };

                const toValues = function (acc, node) {
                    acc.push(toData(node));

                    if (node.getAttribute('colspan')) {
                        const colspan = parseInt(
                            node.getAttribute('colspan'),
                            10,
                        );

                        for (let i = 1; i < colspan; i++) {
                            acc.push('');
                        }
                    }

                    return acc;
                };

                const cells = $('td, th', this).toArray();

                csv.push(_.reduce(cells, toValues, []));
            });

            return this.arrayToCsv(csv);
        },
    });
})();
