const numeral = require('numeral');

(function () {
    'use strict';

    bindhq.nsIn('abacus.newTransaction', {
        /**
         * @param {String}
         */
        DISPLAY_FORMAT: '(0,0.00)',

        /**
         * @param {jQuery} container
         *
         * @return {Numeral}
         */
        calculateTotal: function (container) {
            const itemRows = $('#items tr.collection-item', container);
            const expenseRows = $('#expenses tr.collection-item', container);

            const rows = itemRows.add(expenseRows);

            const toTotal = function (acc, item) {
                const value = $('input[name*="amount"]', item).val();
                const amount = numeral(value);

                return acc.add(amount);
            };

            return _.chain(rows).reduce(toTotal, numeral(0)).value();
        },

        /**
         * @param {jQuery} container
         */
        updateSummary: function (container) {
            const textTotal = $('.transaction-total', container);
            const textItemsCount = $('.items-count', container);
            const textExpensesCount = $('.expenses-count', container);

            const total = this.calculateTotal(container);
            const itemsCount = $('#items tr.collection-item', container).length;
            const expensesCount = $(
                '#expenses tr.collection-item',
                container,
            ).length;

            textTotal.text(total.format(this.DISPLAY_FORMAT));
            textItemsCount.text(itemsCount);
            textExpensesCount.text(expensesCount);
        },

        onSelectItem: function (container, e) {
            const descriptionArea = $(e.target)
                .closest('tr')
                .find('.description-area');
            const value = $(e.target)
                .find('option:selected')
                .data('description');
            $(descriptionArea).text(value || '');
        },

        avoidSubmitOnEnter: function (e) {
            const keyCode = e.keyCode || e.which;
            if (keyCode === 13) {
                e.preventDefault();
                return false;
            }
        },

        initContainer: function (container) {
            const onSelectItem = _.partial(this.onSelectItem, container);
            const onChange = _.partial(this.updateSummary, container);
            const onPressEnter = _.partial(this.avoidSubmitOnEnter);

            container.on('keyup', 'input[name*="amount"]', onChange);
            container.on('change', '.select-transaction-item', onSelectItem);
            container.on('keyup keypress', onPressEnter);
        },
    });
})();
