(function () {
    'use strict';

    bindhq.nsIn('reports.productionOverview', {
        onCsvClick: function (container) {
            const table = $('.table-custom-report', container);

            bindhq.util.download(
                bindhq.csv.tableToCsv(table, 'tr'),
                'text/csv',
                container.data('table-csv-filename') || 'report.csv',
            );

            return false;
        },

        initContainer: function (container) {
            const onCsvClick = _.partial(this.onCsvClick, container);

            $('.report-csv-link', container.parent()).click(onCsvClick);

            container.on('refreshPage', function () {
                container.closest('form').get(0).submit();
            });
        },
    });
})();
