(function () {
    'use strict';

    bindhq.ns('mailto');

    bindhq.mailto.setupLink = function (link) {
        const href = link.attr('href');
        const address = href.slice(7);
        const name = link.data('email-name') || address;

        link.click(function (e) {
            e.preventDefault();
            bindhq.mailpane.showCompose(
                link.data('mailpane-subject') || '',
                link.data('mailpane-body') || '',
                [{ name: name, id: address }],
            );
        });
    };

    bindhq.mailto.init = function () {
        if (Object.prototype.hasOwnProperty.call(bindhq, 'mailpane')) {
            bindhq.mailto.setupLink($(this));
        }
    };
})();
