const { DateTime } = require('luxon');
const numeral = require('numeral');

(function () {
    'use strict';

    bindhq.nsIn('reports.utils', {
        /**
         * @param {jQuery} container
         *
         * @return {CanvasRenderingContext2D}
         */
        contextFor: function (container) {
            return $('<canvas></canvas>')
                .addClass('chartjs')
                .insertAfter(container)
                .get(0)
                .getContext('2d');
        },

        /**
         * @param {jQuery} table
         *
         * @return {DOMNode}
         */
        containerFor: function (table) {
            const node = document.createElement('div');
            $(node).addClass('report-wrapper').insertAfter(table);

            return node;
        },

        /**
         * '$123,456.78' => 123456.78
         *
         * @param {String} text
         *
         * @return {Number}
         */
        currencyToFloat: function (text) {
            const number = text.replace(/[$,]/g, '');

            return parseFloat(number);
        },

        /**
         * @return {array}
         */
        palette: function () {
            return [
                '#3bb2ed',
                '#4ecaab',
                '#ff9c62',
                '#b357b6',
                '#ffc362',
                '#eb5767',
                '#f23e5e',
                '#ebb2ed',
            ];
        },

        /**
         * @return {string}
         */
        getColorFromPalette: function (index) {
            const palette = this.palette();

            if (typeof palette[index] !== 'undefined') {
                return palette[index];
            } else {
                // random color
                return '#' + Math.random().toString(16).substr(-6);
            }
        },

        /**
         * @param {Number} value
         *
         * @return {String}
         */
        floatToCurrency: function (value) {
            return '$' + numeral(value).format('0,00.00');
        },

        /**
         * @param {Number} ts
         *
         * @return {String}
         */
        tsToDate: function (ts) {
            return DateTime.fromSeconds(ts).toFormat('dd/MM/y');
        },
    });
})();
