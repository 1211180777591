(function () {
    'use strict';

    bindhq.nsIn('abacus.contextSelector', {
        onInputChange: function (container) {
            const enableButton = function (
                type,
                entity,
                form,
                linkButton,
                baseUrl,
            ) {
                linkButton.prop('disabled', false);
            };
            const disableButton = function (
                type,
                entity,
                form,
                linkButton,
                baseUrl,
            ) {
                linkButton.prop('disabled', true);
            };
            this.ifComplete(container, enableButton, disableButton);
        },

        onCustomerChange: function (container, evt) {
            const customerId = evt.val;
            if (customerId) {
                $('.table-report tbody tr').hide();
                const visible = $(
                    '.table-report tbody tr[data-customer-id=' +
                        customerId +
                        ']',
                );
                if (visible.length) {
                    visible.show();
                } else {
                    const content = $('.table-report tbody');
                    const td = $('<td></td>')
                        .attr('colspan', 999)
                        .html(
                            'No draft transactions matching selected customer...',
                        );

                    $('<tr></tr>')
                        .addClass('no-results')
                        .append(td)
                        .appendTo(content);
                }
            } else {
                $('.table-report tbody tr').show();
            }
        },

        onRadioType: function (container, evt) {
            const item = $(evt.target);
            const type = item.data('type-name');
            _.each($('.entity-selector', container), function (element) {
                if (
                    $(element).hasClass(type) &&
                    !$(element).hasClass('active')
                ) {
                    $(element).addClass('active');
                } else if ($(element).hasClass('active')) {
                    $(element).removeClass('active');
                }
            });
            this.setDefaultAccountByType(container, item);
            this.onInputChange(container);
        },

        setDefaultAccountByType: function (container, item) {
            const type = item.data('type-name');
            let defaultApAccount = '';
            let defaultArAccount = '';

            if (type === 'marketingCompany') {
                defaultApAccount = $(container).data(
                    'default-marketing-company-ap',
                );
                defaultArAccount = $(container).data(
                    'default-marketing-company-ar',
                );
            } else if (type === 'agency') {
                defaultApAccount = $(container).data('default-agency-ap');
                defaultArAccount = $(container).data('default-agency-ar');
            } else if (type === 'supplier') {
                defaultApAccount = $(container).data('default-supplier-ap');
            }

            $('select[name*="ar_account"]', container)
                .val(defaultArAccount)
                .select2();
            $('select[name*="ap_account"]', container)
                .val(defaultApAccount)
                .select2();
        },

        initDefaultAccount: function (container) {
            const item = $('input[name="entity-type"]:checked', container);
            this.setDefaultAccountByType(container, item);
        },

        onClick: function (container) {
            this.updateInputValues(container);

            const proceed = function (type, entity, form, linkButton, baseUrl) {
                const link = baseUrl + '/' + type + '/' + entity;
                linkButton.progress('start');
                form.attr('action', link);
                form.submit();
            };

            const fail = function () {
                swal({
                    title: '',
                    text: 'Please, fill all the fields',
                    type: 'warning',
                });
            };

            this.ifComplete(container, proceed, fail);
        },

        updateInputValues: function (container) {
            const insuredInput = $('input[name*="insured"]', container);
            const policyInput = $('input[name*="policy"]', container);

            if (insuredInput.select2('data')) {
                const insuredName = insuredInput.select2('data').name;
                insuredInput.val(insuredName);
            }

            if (policyInput.select2('data')) {
                const policyName = policyInput.select2('data').policy_num;
                policyInput.val(policyName);
            }
        },

        ifComplete: function (container, action, noAction) {
            const type = $('input[type=radio]:checked', container).data(
                'type-name',
            );
            const entity = $(
                '.entity-selector.active input',
                container,
            ).select2('val');
            const account = $('.account-select').select2('val');
            const form = $('.context-selector-form', container);
            const linkButton = $('.btn-proceed', container);
            const baseUrl = linkButton.data('href');
            if (baseUrl && type && entity && account) {
                action(type, entity, form, linkButton, baseUrl);
            } else {
                noAction(type, entity, form, linkButton, baseUrl);
            }
        },

        onUserToggleClick: function (control, link) {
            const filter = bindhq.forms.ajaxselect.filterFrom(control) || {};

            if (filter.show_inactive) {
                delete filter.show_inactive;
            } else {
                filter.show_inactive = true;
            }

            const text = filter.show_inactive
                ? 'Hide inactive customers'
                : 'Show inactive customers';

            control.data('filter', filter);
            link.html(text);
        },

        initUserToggle: function (container) {
            const control = $('.entity-selector input.ajax-select', container);
            const link = $('<a></a>')
                .html('Show inactive customers')
                .addClass('customer-toggle')
                .insertAfter(control);
            const onClick = _.partial(this.onUserToggleClick, control, link);

            link.click(onClick);
        },

        init: function (container) {
            const onInputChange = _.partial(this.onInputChange, container);
            const onRadioType = _.partial(this.onRadioType, container);
            const onClick = _.partial(this.onClick, container);
            const onCustomerChange = _.partial(
                this.onCustomerChange,
                container,
            );

            container.on('change', '.select2', onInputChange);
            container.on('change', '.ajax-select', onInputChange);
            container.on('change', 'input[type=radio]', onRadioType);
            container.on('click', '.btn-proceed', onClick);
            container.on(
                'change',
                '.customer-filter input.ajax-select',
                onCustomerChange,
            );

            this.initDefaultAccount(container);
            this.onInputChange(container);
            this.initUserToggle(container);
        },
    });
})();
