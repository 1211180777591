(function () {
    'use strict';

    // Url: secure/reports/production/monthly

    bindhq.ns('reports.productionMonthly.chart');

    bindhq.reports.productionMonthly.chart.init = function ($container) {
        const $form = $container.find('form');

        const chartDatePickerApi = $form
            .find('.datepicker')
            .data('datepickerApi');

        $form.on('submit', function (e) {
            if (chartDatePickerApi && !chartDatePickerApi.isDatePickerValid()) {
                swal('You must enter a date to refresh the chart.');
                e.preventDefault();
            }
        });
    };
})();
