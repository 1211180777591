(function () {
    'use strict';

    bindhq.nsIn('forms.user', {
        onSelectRole: function (container, evt) {
            const data = $(evt.target.selectedOptions).data('defaults');
            const defaults = data ? data.split(',') : [];

            $('.role-features-list li').removeClass(
                'role-features-list-item-highlighted',
            );

            _.each(defaults, function (item) {
                let features;
                item = item.trim();
                if (item === '*') {
                    features = $('.role-features-list li');
                } else {
                    const items = $(
                        '.role-features-list input[value="' + item + '"]',
                    );
                    features = items.parent();
                }
                features.addClass('role-features-list-item-highlighted');
            });

            $('.add-default-features', container).show();
        },

        onSetDefaultRoles: function (container) {
            const all = $(
                '.role-features-list-item input[type="checkbox"]',
                container,
            );
            const defaults = $(
                '.role-features-list-item-highlighted input[type="checkbox"]',
                container,
            );

            all.prop('checked', false);
            defaults.prop('checked', true);
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            const onSelectRole = _.partial(this.onSelectRole, container);
            const onClickDefaultRoles = _.partial(
                this.onSetDefaultRoles,
                container,
            );

            $('.role-selector', container).on('change', onSelectRole);
            $('.add-default-features', container).on(
                'click',
                onClickDefaultRoles,
            );
        },
    });
})();
