(function () {
    'use strict';

    bindhq.nsIn('policyAccountingFilters', {
        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            bindhq.abacus.transactionTable
                .create(container, {
                    perPage: bindhq.abacus.transactionTable.NO_PAGINATION,
                })
                .indexFromDom('.transaction-item');

            bindhq.abacus.transactionMultiSelect.initContainer(container);

            container.on(
                'transaction_multi_select.offset.complete',
                function (evt) {
                    $(evt.target)
                        .empty()
                        .next()
                        .html('<div class="success-value">Fully Paid</div>');
                },
            );
        },
    });
})();
