(function () {
    'use strict';

    bindhq.ns('forms.toggleable');

    /**
     * Initialises a section toggle events
     */
    bindhq.forms.toggleable.initToggle = function () {
        const container = $(this);
        const controls = $('.toggleable', container);
        const toggle = _.bind($.fn.slideToggle, controls);
        const checkbox = $('.toggleable-checkbox', container);

        checkbox.on('click', toggle);

        if (checkbox.is(':checked')) {
            toggle();
        }
    };

    /**
     * Initialises sections
     */
    bindhq.forms.toggleable.init = function () {
        const section = $(this);

        const initToggle = _.bind(bindhq.forms.toggleable.initToggle, section);
        initToggle();
    };
})();
