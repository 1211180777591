(function () {
    'use strict';

    bindhq.nsIn('settings', {
        /**
         * @param {jQuery} container
         * @param {jQuery.Event} evt
         */
        onClickEdit: function (container, evt) {
            const button = $(evt.target);
            const url = button.data('url');
            const options = {
                callback: null,
            };

            bindhq.modalAjax.showUrl(url, options);
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            const actions = $('.edit-setting', container);
            const onClickEdit = _.partial(this.onClickEdit, container);

            container.on('click', actions, onClickEdit);
        },
    });
})();
