(function () {
    'use strict';

    bindhq.nsIn('forms.agent', {
        /**
         * @param {jQuery} field
         * @param {Object} data
         */
        onContactStatusAutofillSuccess: function (field, data) {
            field.select2('val', data.id);
        },

        /**
         * @param {jQuery} field
         * @param {String} url
         */
        onContactStatusAutofillClick: function (field, url) {
            const config = {
                dataType: 'json',
                url: url,
                success: _.partial(this.onContactStatusAutofillSuccess, field),
                complete: bindhq.loader.hide,
            };

            $.ajax(config);

            bindhq.loader.show('Calculating...');
        },

        /**
         * @param {jQuery} field
         * @param {String} url
         */
        addContactStatusAutofill: function (field, url) {
            const onClick = _.partial(
                this.onContactStatusAutofillClick,
                field,
                url,
            );

            $('<a></a>')
                .addClass('btn btn-warning btn-autofill')
                .html('Autofill')
                .click(onClick)
                .insertAfter(field);
        },

        /**
         * @param {jQuery} container
         */
        initContactStatus: function (container) {
            const field = $('select.contact-status', container);
            const form = field.closest('form');
            const url = form.data('contactstatusurl');

            if (url) {
                this.addContactStatusAutofill(field, url);
            }
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            this.initContactStatus(container);
        },
    });
})();
