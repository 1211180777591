(function () {
    'use strict';

    bindhq.nsIn('abacus.receiptDisbursementConfiguration', {
        /**
         * @param {jQuery} lineItems
         */
        reindex: function (lineItems) {
            $('.collection-item', lineItems).each(function (index, item) {
                $(
                    '.line-item-spread, .line-item-order, .line-item-type',
                    item,
                ).each(function () {
                    $(this).attr(
                        'name',
                        $(this).attr('name').replace(/\d+/, index),
                    );
                });

                $('.line-item-order', item).val(index);
            });
        },

        /**
         * @param {jQuery} lineItems
         * @param {jQuery} item
         */
        rearrange: function (lineItems, item) {
            item.remove();

            const firstCheckedItem = $(
                '.line-item-spread:checked:first',
                lineItems,
            );

            if (firstCheckedItem.length === 0) {
                lineItems.append(item);
            } else {
                item.insertBefore(firstCheckedItem.closest('.collection-item'));
            }

            this.reindex(lineItems);
        },

        /**
         * @param {jQuery} lineItems
         */
        onStop: function (lineItems) {
            this.reindex(lineItems);
        },

        /**
         * @param {jQuery} lineItems
         * @param {jQuery.Event} evt
         */
        onSpreadChange: function (lineItems, evt) {
            this.rearrange(
                lineItems,
                $(evt.target).closest('.collection-item'),
            );
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            const lineItems = $('.line-items', container);

            const onStop = _.partial(this.onStop, lineItems);
            const onSpreadChange = _.partial(this.onSpreadChange, lineItems);

            const sortableConfig = {
                stop: onStop,
                items: '.collection-item:not(:has(.line-item-spread:checked))',
                handle: '.line-item-move',
            };

            lineItems.sortable(sortableConfig);
            lineItems.change('.line-item-spread', onSpreadChange);
        },
    });
})();
