(function () {
    'use strict';

    bindhq.nsIn('abacus.customerReceivables', {
        initTable: function (container) {
            container.tablesorter({
                sortList: [[1, 1]],
            });
        },

        init: function (container) {
            const table = container.hasClass('table-customer-receivables')
                ? container
                : $('.table-customer-receivables', container);

            this.initTable(table);
        },
    });
})();
