(function () {
    'use strict';

    bindhq.nsIn('renewals.overview', {
        /**
         * @param {jQuery} container
         * @param {jQuery.Event} evt
         */
        onFilterClick: function (container, evt) {
            const source = $(evt.currentTarget);
            const filter = source.val();

            $('.table-renewals-overview', container)
                .removeClass(
                    'filter-income filter-renewal-income filter-accounts filter-renewal-accounts',
                )
                .addClass('filter-' + filter);
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            const onFilterClick = _.partial(this.onFilterClick, container);

            container.on(
                'change',
                '.filter',
                bindhq.util.noDefault(onFilterClick),
            );
        },
    });
})();
