(function () {
    'use strict';

    bindhq.ns('intabAjax');

    /**
     * @param {String} alertType
     * @param {jQuery} container
     * @param {String} message
     */
    bindhq.intabAjax.showAlert = function (alertType, container, message) {
        $('.alert-placeholder', container).html(
            '<div class="alert alert-' + alertType + '"></div>',
        );

        $('.alert-placeholder .alert', container)
            .html(message)
            .animate({ backgroundColor: '#FFFFFF' }, 'slow');
    };

    /**
     * @param {jQuery} oldContent
     * @param {jQuery} newContent
     * @param {jQuery} cancelLink
     */
    bindhq.intabAjax.cancelContent = function (
        oldContent,
        newContent,
        cancelLink,
    ) {
        newContent.remove();
        cancelLink.remove();

        oldContent.show();
    };

    /**
     * @param {jQuery} anchor
     * @param {Object} options
     * @param {String} html
     */
    bindhq.intabAjax.showContent = function (anchor, options, html) {
        const tabId = anchor.data('intabajax');
        const tab = $('#' + tabId);
        const oldContent = tab.contents().hide();
        const newContent = $('<div class="intab-content">' + html + '</div>');

        const cancelLink = $('<a></a>')
            .addClass('intab-cancel')
            .text('Cancel, back to ' + tabId);

        const cancelWrapper = $('<div></div>')
            .addClass('intab-cancel-wrapper')
            .append(cancelLink);

        cancelLink.click(
            _.partial(
                bindhq.intabAjax.cancelContent,
                oldContent,
                newContent,
                cancelWrapper,
            ),
        );

        tab.append(cancelWrapper).append(newContent);

        $('.intab-content', tab).each(bindhq.init);

        if (options && options.success) {
            options.success();
        }
    };

    /**
     * @param {jQuery} anchor
     */
    bindhq.intabAjax.loadContent = function (anchor, options) {
        options = options || {};

        const onSuccess = _.partial(
            bindhq.intabAjax.showContent,
            anchor,
            options,
        );
        const button = options.button || anchor;

        bindhq.loader.start(button);

        $.ajax({
            url: anchor.attr('href'),
            success: onSuccess,
            error: function (xhr) {
                bindhq.intabAjax.showContent(
                    anchor,
                    options,
                    '<div class="intab-exception">' +
                        xhr.responseText +
                        '</div>',
                );
            },
            complete: function () {
                bindhq.loader.stop(button);
            },
        });
    };

    /**
     * @param {jQuery} anchor
     */
    bindhq.intabAjax.initAnchor = function (anchor) {
        const onClick = _.partial(bindhq.intabAjax.loadContent, anchor);

        if (anchor.closest('.tab-pane').length > 0) {
            anchor.click(bindhq.util.noDefault(onClick));
        }
    };

    /**
     * Initialise an anchor which is current context
     */
    bindhq.intabAjax.init = function () {
        const anchor = $(this);

        bindhq.intabAjax.initAnchor(anchor);
    };
})();
