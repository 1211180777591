(function () {
    'use strict';

    bindhq.ns('files');

    bindhq.files.XHR_COMPLETE = 4;

    /**
     * @param {jQuery} placeholder
     * @param {String} html
     */
    bindhq.files.uploadComplete = function (placeholder, html) {
        const element = $(html);
        const file = bindhq.files.elementToFile(element);

        placeholder.replaceWith(element);

        bindhq.initContainer(element);

        $('body').trigger($.Event('file_uploaded', { file: file }));
    };

    /**
     * @param {Object} xhr
     * @param {Object} options
     */
    bindhq.files.uploadProgress = function (xhr, options) {
        options = options || {};

        if (xhr.readyState === bindhq.files.XHR_COMPLETE) {
            const refreshPage = function () {
                location.reload();
            };

            (options.complete || refreshPage)(xhr);
        }
    };

    /**
     * @param {Object} options
     * @param {File} file
     */
    bindhq.files.uploadFile = function (options, file) {
        const xhr = new XMLHttpRequest();
        const name = options.name || file.name;

        xhr.open('POST', options.action || '', true);
        xhr.onreadystatechange = _.partial(
            bindhq.files.uploadProgress,
            xhr,
            options,
        );
        try {
            xhr.setRequestHeader('X-FILENAME', name);
        } catch (e) {
            $('.attach-area tr.loading').remove();
            alert(
                'Sorry, it looks like the supplied file has an invalid name that contains non-ISO characters.' +
                    ' We recommend you rename the file and retry.',
            );
            return;
        }
        xhr.setRequestHeader('X-OWNERS', options.owners || '');
        xhr.setRequestHeader('X-TAGS', options.tags || '');
        xhr.setRequestHeader('X-TEMPLATE', options.template || '');

        if (file.type) {
            xhr.setRequestHeader('Content-Type', file.type);
        }

        xhr.send(file);
    };

    /**
     * @param {DOMElement} item
     *
     * @return {Object}
     */
    bindhq.files.elementToFile = function (item) {
        const element = $(item);
        const toTag = function (index, element) {
            const tag = $(element);

            return {
                name: tag.text().trim(),
                identifier: tag.data('tag'),
                type: tag.data('tag-type'),
            };
        };

        return {
            id: element.data('file-id') || '',
            name:
                element.data('file-name') ||
                $('.title', element).text() ||
                element.text(),
            source: element.data('file-source') || '',
            type: element.data('file-type') || '',
            size: element.data('file-size') || -1,
            input: element.data('input'),
            browserViewable: element.data('file-browser-viewable'),
            tags: $('.tag', element).map(toTag).toArray(),
        };
    };

    /**
     * @param {jQuery|DOMElement} element
     * @param {Object} file
     */
    bindhq.files.withData = function (element, file) {
        $(element)
            .data('file-id', file.id)
            .data('file-name', file.name)
            .data('file-source', file.source)
            .data('file-type', file.type)
            .data('file-size', file.size);
    };

    /**
     * @param {Array} acc
     * @param {Object} file
     *
     * @return {Array}
     */
    bindhq.files.toUnique = function (acc, file) {
        return _.where(acc, file).length > 0 ? acc : acc.concat([file]);
    };

    /**
     * @param {Array} files
     *
     * @return {Array}
     */
    bindhq.files.unique = function (files) {
        return _.reduce(files, bindhq.files.toUnique, []);
    };

    /**
     * Finds by either id or source
     *
     * @param {Array} files
     * @param {String} query
     *
     * @return {Object|Boolean}
     */
    bindhq.files.findIn = function (files, query) {
        let file;

        file = _.find(files, { id: parseInt(query, 10) });
        if (file) {
            return file;
        }

        file = _.find(files, function (item) {
            return item.source && query.match(item.source + '.*');
        });
        if (file) {
            return _.extend({}, file, { source: query });
        }

        return false;
    };

    /**
     * @return {Array}
     */
    bindhq.files.select = function (selector) {
        return _.map($(selector), bindhq.files.elementToFile);
    };

    /**
     * @param {Number} sizeInBytes
     *
     * @return {String}
     */
    bindhq.files.bytesToDescription = function (sizeInBytes) {
        const oneKb = 1024;
        const oneMb = oneKb * 1024;

        if (sizeInBytes < oneKb) {
            return sizeInBytes + ' bytes';
        } else if (sizeInBytes < oneMb) {
            return Math.ceil(sizeInBytes / oneKb) + ' Kb';
        } else {
            return Math.ceil(sizeInBytes / oneMb) + ' Mb';
        }
    };

    /**
     * @param {jQuery} container
     * @param {String} name
     *
     * @return {jQuery}
     */
    bindhq.files.loaderFor = function (container, name) {
        const message = 'Uploading "' + name + '" ...';

        if (container.hasClass('files-table')) {
            return $(
                '<tr class="loading"><td colspan="999">' +
                    message +
                    '</td></tr>',
            );
        } else if (container.hasClass('files-folder')) {
            return $(
                '<li><div class="thumbnail-wrap"><img src="/build/images/no-thumb.png" class="img-loading"></div><a>' +
                    message +
                    '</a></li>',
            );
        } else {
            return $('<li></li>').addClass('loading').html(message);
        }
    };

    /**
     * @param {Object} options
     */
    bindhq.files.choose = function (options) {
        const file = $('<input type="file" />')
            .attr({
                name: 'file',
            })
            .css({
                display: 'none',
            });

        const form = $('<form></form>')
            .attr({
                method: 'post',
                enctype: 'multipart/form-data',
            })
            .css({
                display: 'none',
            })
            .append(file)
            .appendTo('body');

        file.change(function () {
            if (file.val()) {
                form.ajaxSubmit(options);
            }
        }).trigger('click');
    };
})();
