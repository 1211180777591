(function () {
    'use strict';

    bindhq.nsIn('divisionSwitcher', {
        /**
         * @param {jQuery} container
         * @param {jQuery.EVent} evt
         */
        onClick: function (container, evt) {
            const source = $(evt.currentTarget);
            const selector = container.find('.division-selector');

            const config = {
                url: source.attr('href'),
                success: function (html) {
                    const btn = $('.btn-group', html);
                    container.empty().append(btn);

                    container.trigger('bindhq.division_switched');
                },
                complete: function () {
                    selector.progress('stop');
                },
            };

            selector.progress('start');

            $.ajax(config);
        },

        /**
         *
         */
        init: function () {
            if (bindhq.isGranted('FEATURE_ACCOUNTING')) {
                const container = $('.division-selector-container');

                container.on(
                    'click',
                    '.dropdown-menu a',
                    bindhq.util.noDefault(_.partial(this.onClick, container)),
                );
            }
        },
    });
})();
