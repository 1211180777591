(function () {
    'use strict';

    bindhq.nsIn('forms.lesson', {
        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            const collapseSlides = function () {
                $('.lesson-slides button.nocoll', container).click();
            };

            $('.lesson-slides .add a', container).on('click', collapseSlides);
        },
    });
})();
