(function () {
    'use strict';

    bindhq.nsIn('help', {
        /**
         * @param {jQuery} container
         */
        initModal: function (container) {
            const id = container.attr('id');

            if (id) {
                const cookieName = 'bhq-help';
                const tenYears = 365 * 10;
                const config = { path: '/secure/', expires: tenYears };
                const helpData = $.cookie(cookieName);
                const help = JSON.parse(helpData || '{}');

                if (!help[id]) {
                    help[id] = 1;

                    $.cookie(cookieName, JSON.stringify(help), config);

                    container
                        .on('show', function () {
                            $('.animated-sprite', this).initEach(
                                bindhq.animator.initContainer,
                            );
                        })
                        .sizedModal();
                }
            }
        },
    });
})();
