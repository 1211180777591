(function () {
    'use strict';

    bindhq.nsIn('steps', {
        /**
         * @param {jQuery} container
         */
        next: function (container) {
            $('.title .on a', container)
                .parent()
                .next()
                .find('a')
                .trigger('click');
        },

        /**
         * @param {jQuery} container
         */
        onNextClick: function (container) {
            this.next(container);
        },

        /**
         * @param {jQuery} container
         */
        onConfirmClick: function (container) {
            container.trigger($.Event('step-confirmed'));
        },

        /**
         * @param {jQuery} container
         * @param {jQuery.Event} evt
         */
        onStepClick: function (container, evt) {
            const step = evt.currentTarget.hash.substring(1);
            const item = $(evt.currentTarget).parent();

            $('.title li', container).removeClass('on prev done');

            item.addClass('on')
                .prev()
                .addClass('prev')
                .prevAll()
                .addClass('done');

            $('.step', container).hide();

            $('.body .' + step, container).show();

            container.removeClass('final');

            if (item.is(':last-child')) {
                container.addClass('final');
            }

            container.trigger($.Event('step-changed', { name: step }));

            evt.preventDefault();
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            const onStepClick = _.partial(this.onStepClick, container);
            const onNextClick = _.partial(this.onNextClick, container);
            const onConfirmClick = _.partial(this.onConfirmClick, container);

            container.on('click', '.title ul a', onStepClick);
            container.on('click', '.next', onNextClick);
            container.on('click', '.confirm', onConfirmClick);

            $('.title ul a:first').trigger('click');
        },
    });
})();
