(function () {
    'use strict';

    bindhq.nsIn('abacus.customerSelect', {
        /**
         * @param {String}
         */
        itemTpl: '<%= item.name || item.text %>',
        itemInactiveTpl:
            '<div class="select2-result-customer"><div class="inactive"><%= item.name || item.text %></div></div>',

        /**
         * @param {String} prefix
         * @param {Object} item
         *
         * @return {Object}
         */
        toResult: function (prefix, item) {
            const id = prefix + item.id;

            return {
                id: id,
                text: bindhq.util.template(
                    item.inactive ? this.itemInactiveTpl : this.itemTpl,
                    { item: item },
                ),
            };
        },

        /**
         * @param {jQuery} container
         * @param {Object} data
         */
        toResults: function (container, data) {
            const toResultAgency = _.partial(
                this.toResult,
                container.data('agency-prefix'),
            );
            const toResultMarketingCompany = _.partial(
                this.toResult,
                container.data('marketing-company-prefix'),
            );
            const toResultSupplier = _.partial(
                this.toResult,
                container.data('supplier-prefix'),
            );
            const toResultFinance = _.partial(
                this.toResult,
                container.data('finance-prefix'),
            );
            const toResultTaxAuthority = _.partial(
                this.toResult,
                container.data('tax-authority-prefix'),
            );
            const toResultInsured = _.partial(
                this.toResult,
                container.data('insured-prefix'),
            );

            const agencies = this.getResultsOrEmpty(
                data.agencies,
                toResultAgency,
            );
            const marketingCompanies = this.getResultsOrEmpty(
                data.marketingcompanies,
                toResultMarketingCompany,
            );
            const suppliers = this.getResultsOrEmpty(
                data.suppliers,
                toResultSupplier,
            );
            const financeCompanies = this.getResultsOrEmpty(
                data.financecompanies,
                toResultFinance,
            );
            const taxAuthorities = this.getResultsOrEmpty(
                data.taxauthorities,
                toResultTaxAuthority,
            );
            const insureds = this.getResultsOrEmpty(
                data.insureds,
                toResultInsured,
            );

            const results = [];

            if (agencies.length) {
                const agenciesResults = {
                    text: 'Agencies',
                    children: agencies,
                };
                results.push(agenciesResults);
            }

            if (marketingCompanies.length) {
                const marketingCompaniesResults = {
                    text: 'Marketing Companies',
                    children: marketingCompanies,
                };
                results.push(marketingCompaniesResults);
            }

            if (suppliers.length) {
                const suppliersResults = {
                    text: 'Suppliers',
                    children: suppliers,
                };
                results.push(suppliersResults);
            }

            if (financeCompanies.length) {
                const financeCompaniesResults = {
                    text: 'Finance Companies',
                    children: financeCompanies,
                };
                results.push(financeCompaniesResults);
            }

            if (taxAuthorities.length) {
                const taxAuthoritiesResults = {
                    text: 'Tax Authorities',
                    children: taxAuthorities,
                };
                results.push(taxAuthoritiesResults);
            }

            if (insureds.length) {
                const insuredsResults = {
                    text: 'Insureds',
                    children: insureds,
                };
                results.push(insuredsResults);
            }

            return {
                results: results,
                more: false,
            };
        },

        getResultsOrEmpty: function (data, callback) {
            return data ? _.map(data.results, callback) : [];
        },

        /**
         * @return {Object}
         */
        toData: function (term, page) {
            return {
                q: term,
                limit: 20,
            };
        },

        /**
         * @param {Object} item
         *
         * @return {String}
         */
        formatItem: function (item) {
            return bindhq.util.template(this.itemTpl, { item: item });
        },

        /**
         * @param {DOMElement} element
         * @param {Function} callback
         */
        initSelection: function (element, callback) {
            const item = $(element);
            const initial = item.data('initial');

            if (initial) {
                callback(initial);
            }
        },

        onUserToggleClick: function (container, link) {
            const inactive = container.data('inactive');
            let text = '';

            if (inactive === 'both') {
                container.data('inactive', 'no');
                text = 'Show inactive customers';
            } else {
                container.data('inactive', 'both');
                text = 'Hide inactive customers';
            }
            this.initAjax(container);
            link.html(text);
        },

        initUserToggle: function (container) {
            if (!container.prop('disabled')) {
                const link = $('<a></a>')
                    .html('Show inactive customers')
                    .addClass('customer-toggle')
                    .insertAfter(container);
                const onClick = _.partial(
                    this.onUserToggleClick,
                    container,
                    link,
                );

                link.click(onClick);
            }
        },

        /**
         * @param {jQuery} container
         */
        initAjax: function (container) {
            const entities = container.data('entities');
            const inactive = container.data('inactive') || 'no';
            const config = {
                dropdownAutoWidth: true,
                placeholder: 'Find ...',
                minimumInputLength: 1,
                formatResult: this.formatItem,
                formatSelection: this.formatItem,
                initSelection: this.initSelection,
                allowClear: true,
                ajax: {
                    url:
                        container.data('customers-search-url') +
                        '?entities=' +
                        entities +
                        '&inactive=' +
                        inactive +
                        '&min-score=' +
                        bindhq.MIN_SEARCH_SCORE,
                    dataType: 'json',
                    data: this.toData,
                    results: _.partial(this.toResults, container),
                },
            };
            container.select2(config);
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            this.initAjax(container);
            this.initUserToggle(container);
        },
    });
})();
