(function () {
    'use strict';

    bindhq.ns('forms.address');

    bindhq.forms.address.init = function () {
        const container = $(this).closest('.address_auto');

        const boxContainer = $('.box-container');

        const formField = $('<a></a>')
            .html('Lookup')
            .addClass('btn btn-lookup');

        $('.address_lookup', container).after(formField);

        $('.btn-lookup', container).on('click', function (e) {
            bindhq.forms.address.click(e, container, boxContainer);
        });

        $('.modal select#addresses', boxContainer).change(function () {
            bindhq.forms.address.updateForm($(this).val(), container);

            $('.modal', boxContainer).modal('hide');
        });
    };

    bindhq.forms.address.click = function (e, container, boxContainer) {
        e.preventDefault();

        // build up the url to send to the bundle
        const jsonURL = bindhq.forms.address.getFormData(
            container,
            boxContainer,
        );

        $.getJSON(jsonURL, function (data) {
            bindhq.forms.address.populateDropDown(data, boxContainer);
        });

        $('.modal', boxContainer).modal('show');
    };

    bindhq.forms.address.populateDropDown = function (data, boxContainer) {
        const select = $('.modal select#addresses', boxContainer);

        $('.modal .error', boxContainer).hide();
        $(select).show();

        const options = select.prop('options');
        $('option', select).remove();

        options[options.length] = new Option('Please select an address', '');

        let optionData = '';
        $.each(data, function (key, val) {
            if (val.error) {
                bindhq.forms.address.showError(val.error, boxContainer);
            } else {
                optionData =
                    val.name +
                    '|' +
                    val.street1 +
                    '|' +
                    val.street2 +
                    '|' +
                    val.city +
                    '|' +
                    val.state +
                    '|' +
                    val.zip;

                options[options.length] = new Option(
                    optionData.replace(/\|/g, ', '),
                    optionData,
                );
            }
        });
    };

    // update the form with the selected information
    bindhq.forms.address.updateForm = function (optionData, container) {
        const dataArray = optionData.split('|');

        $('.company_auto', container).val(dataArray[0]);
        $('.address-line1', container).val(dataArray[1]);
        $('.address-line2', container).val(dataArray[2]);
        $('.address-line3', container).val(dataArray[3]);
        $('.state_auto', container).val(dataArray[4]);
        $('.address_lookup', container).val(dataArray[5]);
    };

    bindhq.forms.address.showError = function (error, boxContainer) {
        $('.modal select#addresses', boxContainer).hide();

        $('.modal .error ul', boxContainer)
            .empty()
            .append('<li>' + error + '</li>');
        $('.modal .error', boxContainer).show();
    };

    bindhq.forms.address.getFormData = function (container, boxContainer) {
        // get the data from the form

        const street = $('.address-line1', container).val();
        const state = $('.state_auto', container).val();
        const zip = $('.address_lookup', container).val();

        // append the entered information to the modal element
        const addressData = street + ', ' + state + ', ' + zip;

        $('.modal .addressData span', boxContainer).empty().html(addressData);

        let jsonURL = '/secure/zipcode?';
        jsonURL += 'street=' + street + '&';
        jsonURL += 'state=' + state + '&';
        jsonURL += 'zip=' + zip;

        return jsonURL;
    };
})();
