(function () {
    'use strict';

    if (window.google) {
        google.maps.Map.prototype.markers = [];

        google.maps.Map.prototype.addMarker = function (marker) {
            this.markers[this.markers.length] = marker;
        };

        google.maps.Map.prototype.getMarkers = function () {
            return this.markers;
        };

        google.maps.Map.prototype.clearMarkers = function () {
            for (let i = 0; i < this.markers.length; i++) {
                this.markers[i].setMap(null);
            }

            this.markers = [];
        };

        google.maps.Map.prototype.fixHiddenIssue = function () {
            google.maps.event.trigger(this, 'resize');

            this.setZoom(this.getZoom() - 1);
            this.setZoom(this.getZoom() + 1);
        };
    }
})();
