(function () {
    'use strict';

    bindhq.nsIn('quoteStatus', {
        /**
         * @param {jQuery} container
         * @param {String} html
         */
        onSuccess: function (container, html) {
            container.progress('stop');

            const button = $(html);

            container.replaceWith(button);

            this.initContainer(button);
        },

        /**
         * @param {jQuery} container
         */
        onError: function (container) {
            container.progress('stop');
        },

        /**
         * @param {jQuery} container
         * @param {jQuery.Event} evt
         */
        onStatusClick: function (container, evt) {
            const anchor = $(evt.currentTarget);
            const config = {
                url: container.data('quote-status-url'),
                method: 'POST',
                data: { type: anchor.data('status-type') },
                success: _.partial(this.onSuccess, container),
                error: _.partial(this.onError, container),
            };

            if (config.url) {
                container.progress('start');
                $.ajax(config);
            }
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            const onStatusClick = _.partial(this.onStatusClick, container);

            container.on('click', '.dropdown-menu a', onStatusClick);
        },
    });
})();
