(function () {
    'use strict';

    bindhq.nsIn('reports.policyAudit', {
        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {},
    });
})();
