(function () {
    'use strict';

    bindhq.ns('fileviewer');

    bindhq.fileviewer.showFile = function (url) {
        const viewer = $('#fileviewer-display');
        const iframe = $('<iframe></iframe>').attr({ src: url });

        viewer.empty().append(iframe);
    };

    bindhq.fileviewer.onSelect = function (select) {
        const file = select.select2('data');

        if (file) {
            bindhq.fileviewer.showFile(file.element[0].dataset.url);
        }
    };

    bindhq.fileviewer.init = function () {
        const select = $(this);
        const onSelect = _.partial(bindhq.fileviewer.onSelect, select);

        select.on('change', onSelect);

        onSelect();
    };
})();
