(function () {
    'use strict';

    bindhq.nsIn('tbodysorter', {
        /**
         * @type {Number}
         */
        DIR_ASC: -1,

        /**
         * @type {Number}
         */
        DIR_DESC: 1,

        /**
         * @param {String} value
         *
         * @return {Number}
         */
        currencyValue: function (value) {
            return parseFloat(value.replace(/[$,]/g, ''));
        },

        /**
         * @param {String} value
         *
         * @return {String}
         */
        textValue: function (value) {
            return value.toLowerCase();
        },

        /**
         * @param {Number} index
         * @param {Function} toValue
         * @param {jQuery} row
         */
        valueFor: function (index, toValue, row) {
            const column = $('tr:first td', row).get(index);

            return toValue(column.innerText);
        },

        /**
         * @param {jQuery} header
         * @param {Number} direction
         * @param {jQuery} a
         * @param {jQuery} b
         *
         * @return {Number}
         */
        sorter: function (header, direction, a, b) {
            const index = header.index();
            const isCurrency = header.hasClass('tbodysorter-currency');
            const toValue = isCurrency ? this.currencyValue : this.textValue;

            const a_ = this.valueFor(index, toValue, a);
            const b_ = this.valueFor(index, toValue, b);

            if (a_ < b_) {
                return -1 * direction;
            } else if (a_ > b_) {
                return 1 * direction;
            } else {
                return 0;
            }
        },

        /**
         * @param {jQuery} container
         * @param {Array} rows
         * @param {jQuery.Event} evt
         */
        onChange: function (container, rows, evt) {
            const header = $(evt.currentTarget);
            const direction =
                header.data('tbodysorter-direction') || this.DIR_ASC;
            const isAscending = direction === this.DIR_ASC;
            const sorter = _.partial(this.sorter, header, direction);
            const head = $('thead', container);
            const sorted = rows.detach().toArray().sort(sorter);

            _.each(sorted, function (row) {
                $(row).insertAfter(head);
            });

            header
                .data(
                    'tbodysorter-direction',
                    isAscending ? this.DIR_DESC : this.DIR_ASC,
                )
                .parent()
                .children()
                .removeClass('sort-ascending sort-descending');

            header.addClass(isAscending ? 'sort-ascending' : 'sort-descending');
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            const rows = $('tbody', container);
            const onChange = _.partial(this.onChange, container, rows);

            container.on('click', 'thead th', onChange);
        },
    });
})();
