(function () {
    'use strict';

    bindhq.nsIn('forms.userPost', {
        /**
         * @param {jQuery} container
         * @param {Array} results
         * @param {Number} status
         */
        onGeocode: function (container, results, status) {
            if (
                status === google.maps.GeocoderStatus.OK &&
                results.length > 0
            ) {
                const locationDescription = results[0].formatted_address;

                $('.location-description').val(locationDescription);
            }
        },

        /**
         * @param {jQuery} container
         */
        onSuccess: function (container, position) {
            const onGeocode = _.partial(this.onGeocode, container);
            const geocoder = new google.maps.Geocoder();
            const latLng = new google.maps.LatLng(
                position.coords.latitude,
                position.coords.longitude,
            );

            geocoder.geocode({ latLng: latLng }, onGeocode);
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            const onSuccess = _.partial(this.onSuccess, container);

            navigator.geolocation.getCurrentPosition(onSuccess);
        },
    });
})();
