(function () {
    'use strict';

    bindhq.ns('reports');

    bindhq.reports.changeRowHighlight = function (row) {
        const checkbox = row.find('input[type=checkbox]');
        if (checkbox.prop('checked')) {
            row.addClass('exclude');
        } else {
            row.removeClass('exclude');
        }
    };

    bindhq.reports.excludeError = function (data, row) {
        bindhq.alerts.show('There was a problem excluding this item.');

        const checkbox = row.find('input[type=checkbox]');
        checkbox.prop('checked', !checkbox.prop('checked'));
        bindhq.reports.changeRowHighlight(row);
    };

    bindhq.reports.excludeFromAccountsCurrent = function (e) {
        const target = $(e.target);
        const form = target.closest('form');
        const row = form.closest('tr');

        $.ajax(form.attr('action'), {
            type: 'POST',
            data: form.serialize(),
            error: function (data) {
                bindhq.reports.excludeError(data, row);
            },
        });

        bindhq.reports.changeRowHighlight(row);
    };

    bindhq.reports.initAccountsCurrent = function () {
        $('.accounts-current-report table').on(
            'change',
            'input[type=checkbox]',
            bindhq.reports.excludeFromAccountsCurrent,
        );
    };

    bindhq.reports.initCarrierRanking = function (container) {
        const validatorPartial = _.partialRight(
            bindhq.validator.datePicker,
            container,
            'From and To dates are required.',
        );

        container.find('form').on('submit', validatorPartial);
        $('.btn-download').on('click', validatorPartial);
    };

    bindhq.reports.init = function () {
        const container = $(this);

        if (container.hasClass('policy-audit')) {
            bindhq.reports.policyAudit.initContainer(container);
        } else if (container.hasClass('accounts-current-report')) {
            bindhq.reports.initAccountsCurrent();
        } else if (container.hasClass('carrierRanking')) {
            bindhq.reports.initCarrierRanking(container);
        } else if (container.hasClass('report-issuance')) {
            bindhq.reports.issuance.initContainer(container);
        } else if (container.hasClass('report-business-plan')) {
            bindhq.reports.businessPlans.initShow(container);
        } else if (container.hasClass('report-renewals-overview')) {
            bindhq.renewals.overview.initContainer(container);
        } else if (container.hasClass('production-overview')) {
            bindhq.reports.productionOverview.initContainer(container);
        } else if (container.hasClass('agency-ranking')) {
            bindhq.reports.agencyRanking.initContainer(container);
        }
    };
})();
