(function () {
    'use strict';

    bindhq.nsIn('reportTableCsv', {
        /**
         * @param {jQuery} container
         */
        onClick: function (container) {
            bindhq.util.download(
                bindhq.csv.tableToCsv(container),
                'text/csv',
                container.data('table-csv-filename') || 'report.csv',
            );

            return false;
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            const onClick = _.partial(this.onClick, container);

            $('.report-table-csv-link').click(onClick);
        },
    });
})();
