(function () {
    'use strict';

    bindhq.nsIn('forms.tasks', {
        /**
         * @param {jQuery} container
         * @param {String} html
         */
        onTaskLoaded: function (container, html) {
            const panel = container.closest('.panel').find('.panel-pane-task');

            const onComplete = function () {
                panel.empty().closest('.panel').removeClass('panel-slid');
            };

            const cancel = $('<a></a>')
                .html('Cancel')
                .addClass('btn-cancel')
                .click(bindhq.util.noDefault(onComplete));

            panel.html(html).closest('.panel').addClass('panel-slid');

            bindhq.initContainer(panel);

            $('.form-task', panel)
                .formAjax({ success: onComplete })
                .find('[type=submit]')
                .last()
                .after(cancel);
        },

        /**
         * @param {jQuery} container
         */
        initTask: function (container) {
            if (container.hasClass('form-modal')) {
                container.modalAjax();
            }
        },

        /**
         * @param {jQuery} container
         */
        initTaskPanel: function (container) {
            const onEditClick = _.partial(this.onTaskEdit, container);

            $('.btn-edit-task', container).click(
                bindhq.util.noDefault(onEditClick),
            );
        },

        /**
         * @param {jQuery} container
         * @param {jQuery.Event} evt
         */
        onTaskEditClick: function (container, evt) {
            const anchor = $(evt.currentTarget);
            const href = anchor.attr('href');

            if (anchor.closest('.modal').length > 0) {
                const config = {
                    url: href + '?template=form',
                    dataType: 'html',
                    success: _.partial(this.onTaskLoaded, container),
                    complete: function () {
                        anchor.progress('stop');
                    },
                };

                anchor.progress('start');

                $.ajax(config);
            } else {
                bindhq.modalAjax.showUrl(href, { button: anchor });
            }
        },

        /**
         * @param {jQuery} container
         * @param {jQuery.Event} evt
         */
        onTaskCompleteChange: function (container, evt) {
            const checkbox = $(evt.currentTarget);
            const config = {
                url: checkbox.data('url'),
                type: 'POST',
            };

            checkbox.closest('tr').toggleClass('task-complete');

            $.ajax(config);
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            const onTaskCompleteChange = _.partial(
                this.onTaskCompleteChange,
                container,
            );
            const onTaskEditClick = _.partial(this.onTaskEditClick, container);

            container
                .on(
                    'change',
                    'input[type=checkbox]',
                    bindhq.util.noDefault(onTaskCompleteChange),
                )
                .on(
                    'click',
                    '.btn-task-edit',
                    bindhq.util.noDefault(onTaskEditClick),
                );
        },
    });
})();
