(function () {
    'use strict';

    bindhq.nsIn('controlSection', {
        /**
         * @param {jQuery} selector
         * @param {jQuery} form
         * @param {Boolean} clear
         */
        onUpdate: function (selector, form, clear) {
            const visibleClass = 'control-section-visible';
            const key = 'control-section-id';
            const id = $('input[type=radio]:checked', selector).val();

            $('.control-section', form).removeClass(visibleClass);

            if (clear) {
                $('.control-section input', form)
                    .val('')
                    .prop('checked', false)
                    .trigger('change');

                $('.control-section select', form)
                    .prop('selectedIndex', 0)
                    .trigger('change');

                selector.trigger($.Event('control_section.cleared'));
            }

            $('.control-section[data-' + key + '="' + id + '"]', form).addClass(
                visibleClass,
            );
        },

        /**
         * @param {jQuery} selector
         */
        initSelector: function (selector) {
            const form = selector.closest('.control-section-container');
            const onUpdate = _.partial(this.onUpdate, selector, form);
            let clearOnChange = true;

            if (form.data('control-section-clear-on-change') === false) {
                clearOnChange = false;
            }

            selector.on(
                'click',
                'input[type=radio]',
                _.partial(onUpdate, clearOnChange),
            );

            form.on('transaction_table.filtered', _.partial(onUpdate, false));
        },
    });
})();
