(function () {
    'use strict';

    bindhq.nsIn('forms.collection.inline', {
        /**
         * @param {String}
         */
        ITEM_SELECTOR:
            '> .controls > .collection-item, > .collection-item, > div > .collection-item',

        /**
         * @param {Integer} acc
         * @param {DOMElement} collection
         *
         * @return {Integer}
         */
        toMaxId: function (acc, collection) {
            const items = $(
                '.not-removable, select[tabIndex], input[tabIndex]',
                collection,
            );

            for (let i = 0; i < items.length; i++) {
                const item = items.get(i);
                const matches = item.id.match(/(_\d+_|_\d+$)/g);

                if (matches !== null && matches.length !== 0) {
                    const index = parseInt(matches.pop().substring(1), 10);

                    return Math.max(index, acc);
                }
            }

            return acc;
        },

        /**
         * @param {jQuery} container
         *
         * @return {Number}
         */
        nextIndex: function (container) {
            const items = container.find(this.ITEM_SELECTOR).toArray();
            const maxId = _.reduce(items, this.toMaxId, -1);

            return maxId + 1;
        },

        /**
         * @param {jQuery} container
         * @param {jQuery} collectionContainer
         */
        addBlankItem: function (container, collectionContainer) {
            const index =
                bindhq.forms.collection.inline.nextIndex(collectionContainer);
            let html = collectionContainer.attr('data-prototype');
            const matches = html
                .match(/[\w[\]]+__name__[\w+[\]"]+/g)
                .sort()
                .reverse();

            _.each(matches, function (match) {
                html = html.replace(match, match.replace('__name__', index));
            });

            const newItem = $(html);

            collectionContainer
                .find('> .controls')
                .add(collectionContainer)
                .last()
                .append(newItem);

            bindhq.forms.init.apply(newItem);

            this.updateNoResultsMessage(container, collectionContainer);

            bindhq.forms.collection.inline.toRadioButton(
                newItem,
                collectionContainer,
            );

            collectionContainer.trigger(
                'bindhq.forms.collection.inline.blank_item_added',
                [newItem, collectionContainer, container],
            );

            return newItem;
        },

        /**
         * @param {jQuery} item
         */
        hideItem: function (item) {
            item.hide();
            $('input.inactive', item).prop('checked', true);
        },

        /**
         * @param {jQuery} container
         * @param {jQuery} collectionContainer
         * @param {jQuery.Event} event
         */
        deleteItem: function (container, collectionContainer, event) {
            const item = $(event.currentTarget).closest('.collection-item');

            if (container.hasClass('collection-inline-with-inactive')) {
                this.hideItem(item);
            } else {
                item.remove();
            }

            this.refreshCollectionKeys(container, collectionContainer);
            this.updateNoResultsMessage(container, collectionContainer);

            collectionContainer.trigger(
                'bindhq.forms.collection.inline.item_removed',
                [item, collectionContainer, container],
            );
        },

        /**
         * @param {jQuery} container
         * @param {jQuery} collectionContainer
         */
        refreshCollectionKeys: function (container, collectionContainer) {
            const collection = $(
                '> .controls > .collection-item',
                collectionContainer,
            );
            const collectionPrefix = container.data('collection-prefix');

            collection.each(function (i, item) {
                $('[id^="' + collectionPrefix + '"]', item).each(function (
                    fieldIndex,
                    field,
                ) {
                    const regex = /^([a-z_0-9[\]]+?)\d+([a-z_0-9[\]]+)$/i;

                    _.each(['id', 'name'], function (attr) {
                        const value = $(field).attr(attr) || '';

                        $(field).attr(
                            attr,
                            value.replace(regex, '$1' + i + '$2'),
                        );
                    });
                });
            });
        },

        /**
         * @param {jQuery} container
         * @param {jQuery} collectionContainer
         */
        updateNoResultsMessage: function (container, collectionContainer) {
            if (this.nextIndex(collectionContainer) === 0) {
                $('.no-results', container).show();
            } else {
                $('.no-results', container).hide();
            }
        },

        toRadioButton: function (newItem, collectionContainer) {
            if (
                $(collectionContainer).find(
                    'input[type="checkbox"].radio-button',
                ).length === 0
            ) {
                return;
            }

            function checkboxAsRadio(e) {
                e.preventDefault();

                const currentElement = e.currentTarget;
                const id = currentElement.getAttribute('for');
                const checkbox = document.querySelector(`#${id}`);

                if (checkbox.checked) {
                    checkbox.removeAttribute('checked');
                } else {
                    checkbox.setAttribute('checked', true);
                }

                if (checkbox.checked) {
                    $(currentElement)
                        .parent()
                        .find('.checkmark')
                        .removeClass('unchecked')
                        .addClass('checked');

                    $(collectionContainer)
                        .find('input[type="checkbox"]')
                        .filter((i, el) => el.id !== id && el.checked)
                        .each((_, cb) => {
                            cb.removeAttribute('checked');
                            $(cb)
                                .parent()
                                .find('.checkmark')
                                .removeClass('checked')
                                .addClass('unchecked');
                        });
                }
            }

            $(newItem).find('label.checkbox').on('click', checkboxAsRadio);
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            const collectionContainer = container.find(
                '.control-group:first, [data-prototype]:first',
            );
            const onAdd = _.partial(
                this.addBlankItem,
                container,
                collectionContainer,
            );

            const onClick = (event) => {
                const target = event.currentTarget;
                const title =
                    target.getAttribute('data-delete-title') || 'Are you sure?';
                const body =
                    target.getAttribute('data-delete-body') ||
                    'You cannot undo this action.';
                const confirmText =
                    target.getAttribute('data-delete-confirm') || 'Confirm';

                const onDelete = () => {
                    this.deleteItem(container, collectionContainer, event);
                };

                window.dispatchEvent(
                    new CustomEvent('confirm--button:clicked', {
                        detail: {
                            title,
                            body,
                            cancel: 'Cancel',
                            confirm: confirmText,
                            onConfirm: (closeDialog) => {
                                onDelete();

                                window.dispatchEvent(
                                    new CustomEvent(
                                        'confirm--dialog:closeDialog',
                                    ),
                                );
                            },
                        },
                    }),
                );
            };

            const stopPropagation = true;

            this.updateNoResultsMessage(container, collectionContainer);

            $(container).on(
                'click',
                '.add a',
                bindhq.util.noDefault(onAdd, stopPropagation),
            );

            $(container).on(
                'click',
                '.delete a, button.close',
                bindhq.util.noDefault(onClick.bind(this), stopPropagation),
            );

            $(container)
                .find('div.collection-item')
                .each((i, el) => this.toRadioButton(el, collectionContainer));
        },
    });
})();
