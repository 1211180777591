(function () {
    'use strict';

    bindhq.nsIn('forms.policy', {
        /**
         * @param {String} source
         * @param {jQuery.Event} evt
         * @param {Object} message
         */
        onInvoiceUpserted: function (source, evt, message) {
            $('a[data-invoice-id="' + message.invoiceId + '"]').removeClass(
                'invoice-unavailable',
            );
        },

        /**
         * @param {jQuery} container
         */
        initRebookContainer: function (container) {
            const carrier = $('.carrier', container);
            const carrierState = $('.carrierState', container);

            bindhq.forms.ajaxselect.dependsOn(
                carrier,
                carrierState,
                function () {
                    const carrierId = carrier.select2('val');

                    carrierState.data('ajaxurl', function () {
                        return carrierState
                            .get(1)
                            .dataset.ajaxurlPattern.replace(
                                /{carrierId}/,
                                carrierId,
                            );
                    });
                },
            );

            container.formAjax({
                success: function (html) {
                    $(html).appendTo('body').modal('show');
                },
            });
        },

        /**
         *
         */
        initContainer: function (container) {
            $('body').on(
                'policies.invoiceUpserted',
                _.partial(this.onInvoiceUpserted, container),
            );
        },
    });
})();
