(function () {
    'use strict';

    bindhq.nsIn('abacus.chequeBatch', {
        onSelectAll: function (container, e) {
            const input = $(e.target);
            const allInputs = $('[type=checkbox]', container);
            allInputs.prop('checked', input.prop('checked'));
        },

        initContainer: function (container) {
            const onSelectAll = _.partial(this.onSelectAll, container);

            container.on(
                'change',
                '#abacus-cheque-batch-select-all',
                onSelectAll,
            );
        },
    });
})();
