(function () {
    'use strict';

    bindhq.ns('emailTemplates');

    bindhq.emailTemplates.placeholder = {
        id: 0,
        text: 'Select a template...',
    };

    /**
     * @param {jQuery} container
     * @param {String} subject
     * @param {String} message
     */
    bindhq.emailTemplates.setValues = function (container, subject, message) {
        const subjectInput = $('.subject', container);
        const messageInput = $('.message', container);

        subjectInput.val(subject);

        if (messageInput.hasClass('wysiwyg')) {
            messageInput.redactor('set', message);
        } else {
            messageInput.val(message);
        }
    };

    bindhq.emailTemplates.populateForm = function (container, url) {
        const selector = $('.email-template-list', container);

        bindhq.emailTemplates.setValues(container, '', '');

        if (url !== bindhq.emailTemplates.placeholder.id) {
            bindhq.emailTemplates.setValues(container, '', 'Loading...');

            $.ajax({
                context: this,
                url: url,
                success: function (data) {
                    bindhq.emailTemplates.setValues(
                        container,
                        data.subject,
                        data.body,
                    );

                    if (typeof selector.select2 !== 'function') {
                        throw new Error(
                            'Selector does not have a select2 Function: ' +
                                typeof selector +
                                ' - ' +
                                typeof selector.select2,
                        );
                    }

                    selector.select2(
                        'val',
                        bindhq.emailTemplates.placeholder.id,
                    );
                },
                error: function () {
                    bindhq.emailTemplates.setValues(
                        container,
                        '',
                        'There was an error loading the email template...',
                    );
                },
            });
        }
    };

    bindhq.emailTemplates.onChange = function (container, e) {
        bindhq.emailTemplates.populateForm(container, e.val);
    };

    bindhq.emailTemplates.loadSignature = function (container) {
        const signature = container.find('textarea').data('email-signature');

        if (signature) {
            bindhq.emailTemplates.setValues(container, '', '<br/>' + signature);
        }
    };

    bindhq.emailTemplates.init = function (container) {
        bindhq.emailTemplates.loadSignature(container);

        const listPath = container.data('list-path');
        const loadPath = container.data('load-path');
        const source = $('body').data('mailpane-source') || '';
        const parameters = source.split(':');
        if (parameters.length > 1) {
            const id = parameters[1];
            const type = parameters[0];
            let typeId = null;

            switch (type) {
                case 'application':
                    typeId = 'A';
                    break;

                case 'policy':
                    typeId = 'P';
                    break;

                case 'agency':
                    typeId = 'AS';
                    break;
            }

            if (typeId && id) {
                const onChange = _.partial(
                    bindhq.emailTemplates.onChange,
                    container,
                );
                let selector = $('.email-template-list', container);

                if (selector.length === 0) {
                    selector = $('<div></div>')
                        .addClass('email-template-list')
                        .insertBefore('.subject', container);
                }

                $('body').one('mailpane:show', () => {
                    $.ajax({
                        url: listPath
                            .replace(/{type}/, typeId)
                            .replace(/{entityId}/, id),
                        success: function (data) {
                            const formatForSelect = function (template) {
                                return {
                                    id: loadPath
                                        .replace(/{templateId}/, template.id)
                                        .replace(/{entityId}/, id),
                                    text: template.name,
                                };
                            };
                            const results = [
                                bindhq.emailTemplates.placeholder,
                            ].concat(_.map(data, formatForSelect));

                            selector.select2({ data: results });
                            selector.select2(
                                'val',
                                bindhq.emailTemplates.placeholder.id,
                            );
                            selector.on('change', onChange);
                        },
                    });
                });
            }
        }
    };
})();
