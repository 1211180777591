(function () {
    'use strict';

    bindhq.nsIn('forms.applicationIndexForm', {
        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            container.submit(function () {
                const tabValue = container.find("input[name^='tab']").val();

                if (tabValue === 'my_applications') {
                    $('#applicationFilter_users').prop('disabled', false);
                }
            });
        },
    });
})();
