(function () {
    'use strict';

    bindhq.ns('forms.filter');

    /**
     * Reloads page
     */
    bindhq.forms.filter.redirect = function () {
        const hash = window.location.hash;
        const urlData = window.location.href.split('?');
        const baseUrl = urlData[0];
        const redirectUrl =
            baseUrl.indexOf('#') !== -1 ? baseUrl : baseUrl + hash;

        window.location = redirectUrl;
    };

    /**
     * Initialises section
     */
    bindhq.forms.filter.init = function () {
        const clearButton = $(this);

        clearButton.on('click', bindhq.forms.filter.redirect);
    };
})();
