(function () {
    'use strict';

    bindhq.ns('forms.datepicker');

    bindhq.forms.datepicker.onChange = function (evt) {
        const target = $(evt.currentTarget);
        const today = new Date();
        const desired = target.datepicker('getDate');
        const controls = target.parent();

        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);

        target.parent().find('label.error').remove();

        if (target.data('past-date-warning') && desired < today) {
            if (controls.find('.warning').length === 0) {
                const warning = $(
                    '<div class="warning">Note that the date you\'ve selected is in the past.</div>',
                );

                warning.appendTo(controls);
            }
        } else {
            controls.find('.warning').remove();
        }
    };

    bindhq.forms.datepicker.clear = function (input, display) {
        input.val('');
        display.val('');
        input.trigger('change');
    };

    bindhq.forms.datepicker.addClearer = function (input, display) {
        const onClick = _.partial(
            bindhq.forms.datepicker.clear,
            input,
            display,
        );
        const icon = $('<span><span>').addClass('fa fa-times');
        const link = $('<a></a>').append(icon).addClass('clear').click(onClick);

        display.wrap('<div class="clear-wrap"></div>').parent().append(link);
    };

    bindhq.forms.datepicker.addPicker = function (input) {
        const onClick = _.bind($.fn.datepicker, input, 'show');

        $('<a></a>')
            .html('<i class="fa fa-calendar-alt"></i>')
            .addClass('calendar-container')
            .click(onClick)
            .insertAfter(input);
    };

    bindhq.forms.datepicker.initialDateFrom = function (input, format) {
        const value = input.val() || input.data('default') || false;

        if (!value) {
            return '';
        }

        const dateString = value.substring(0, 10) + ' 00:00:00';

        return $.datepicker.formatDate(format, new Date(dateString));
    };

    /**
     * Default to the 1st of the month when month/year changed
     *
     * @param {Number} year
     * @param {Number} month
     * @param {jQuery} inst
     */
    bindhq.forms.datepicker.onChangeMonthYear = function (year, month, inst) {
        const isVisible = $(inst.dpDiv).is(':visible');

        if (isVisible) {
            const current = new Date();

            current.setDate(1);
            current.setYear(year);
            current.setMonth(month - 1);

            $(this).datepicker('setDate', current);
        }
    };

    bindhq.forms.datepicker.isValid = function ($input) {
        return $input.val() !== '';
    };

    bindhq.forms.datepicker.init = function () {
        const self = bindhq.forms.datepicker;
        const container = $(this);
        const controls = $('> .controls', container);
        const input = $('input:first', controls);
        const format = input.data('displayformat') || 'mm/dd/yy';
        const display = $('<input type="text"></input>');
        const hideDatepicker = _.bind($.fn.datepicker, input, 'hide');
        const config = {
            altField: display,
            altFormat: format,
            dateFormat: 'yy-mm-dd 00:00:00',
            changeMonth: true,
            changeYear: true,
            onChangeMonthYear: self.onChangeMonthYear,
        };

        // add a reference to the api so external can find stuff out about the state of the datepicker
        container.data('datepickerApi', {
            isDatePickerValid: _.partial(
                bindhq.forms.datepicker.isValid,
                input,
            ),
        });

        const oldDisplay = $(
            '[name="' + input.attr('name') + '_display' + '"]',
            container,
        );
        if (oldDisplay) {
            oldDisplay.remove();
        }

        display
            .attr('readonly', 'readonly')
            .attr('name', input.attr('name') + '_display')
            .attr('placeholder', input.attr('placeholder'))
            .val(self.initialDateFrom(input, format))
            .prependTo(controls);

        if (input.prop('required')) {
            display.attr('required', 'required');
        }

        input.addClass('hidden').datepicker(config);

        controls.on('change', '.hasDatepicker', self.onChange);

        if (!input.attr('disabled') && !input.attr('readonly')) {
            self.addClearer(input, display);
            self.addPicker(input);
        }

        $(window).resize(hideDatepicker);

        $('body').find('.modal-body').first().on('scroll', hideDatepicker);
    };
})();
