(function () {
    'use strict';

    bindhq.nsIn('dashboard', {
        onUpdatePerformanceParametersSuccess: function (content, html) {
            content.empty().append(html);

            bindhq.initContainer(content);
        },

        onUpdatePerformanceParamenters: function (container) {
            const content = container.find('.performance-content');
            const config = {
                url: $(container).attr('action'),
                data: $(container).serialize(),
                success: _.partial(
                    this.onUpdatePerformanceParametersSuccess,
                    content,
                ),
                complete: function () {
                    content.removeClass('content-loading');
                },
            };

            content.addClass('content-loading');
            $.ajax(config);
        },

        initPerformanceArea: function (container) {
            const form = container.find('form');
            const input = $('input[name^="performanceFilter"]', form);
            const onChange = _.partial(
                this.onUpdatePerformanceParamenters,
                form,
            );

            input.on('change', onChange);
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            $('.widget-performance', container).initEach(
                this.initPerformanceArea,
            );
        },
    });
})();
