(function () {
    'use strict';

    bindhq.ns('validator');

    /**
     * Validates the supplied date value. If invalid, aborts the event.
     *
     * @param {Event} e
     * @param {jQuery} container
     * @param {String} msg
     */
    bindhq.validator.datePicker = function (e, container, msg) {
        const datePickers = container.find('form').find('.datepicker');
        datePickers.each(function () {
            const chartDatePickerApi = $(this).data('datepickerApi');
            if (!chartDatePickerApi.isDatePickerValid()) {
                swal({
                    title: msg,
                    type: 'error',
                });

                e.preventDefault();
            }
        });
    };
})();
