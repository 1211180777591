(function () {
    'use strict';

    bindhq.ns('forms.inactivableComment');

    bindhq.forms.inactivableComment.displayTextarea = function (
        container,
        evt,
    ) {
        let label;
        const checkbox = $(evt.target);
        const inactivableComment = $('.inactivable-comment', container);
        if (checkbox.prop('checked')) {
            label = 'Why do you want to inactivate it?';
        } else {
            label = 'Why do you want to activate it?';
        }
        const textarea = $('textarea', container);
        textarea.parent().parent().find('label').html(label);
        inactivableComment.toggle();
    };

    /**
     * @param {jQuery} container
     */
    bindhq.forms.inactivableComment.initContainer = function (container) {
        const onChange = _.partial(
            bindhq.forms.inactivableComment.displayTextarea,
            container,
        );

        container.on('change', ':checkbox', onChange);
    };

    /**
     * Initialise scroller from function context
     */
    bindhq.forms.inactivableComment.init = function (container) {
        bindhq.forms.inactivableComment.initContainer(container);
    };
})();
