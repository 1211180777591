const progress = require('../../../../../../../assets/js/BindHQ/Utils/Progress');

(function () {
    'use strict';

    /**
     * @param {String} method
     * @param {Object} options
     */
    bindhq.progress = function (method, options) {
        const stateStopped = -1;

        /**
         * @param {jQuery} input
         */
        function start(input) {
            progress.start();

            // compat with 'toggle'
            input.data('progress-state', 0);
        }

        /**
         * @param {jQuery} input
         */
        function stop(input) {
            progress.stop();

            // compat with 'toggle'
            input.data('progress-state', stateStopped);
        }

        /**
         * @param {jQuery} input
         */
        function success(input) {
            input.addTimedClass('btn-success');
        }

        switch (method) {
            case 'toggle':
                const currentState = this.data('progress-state');

                if (
                    currentState === undefined ||
                    currentState === stateStopped
                ) {
                    start(this);
                } else {
                    stop(this);
                }
                break;
            case 'start':
                start(this);
                break;

            case 'stop':
                stop(this);
                break;

            case 'success':
                stop(this);
                success(this);
                break;

            default:
                throw new Error(
                    'Unknown method: ' +
                        method +
                        ' (valid methods are "start" and "stop")',
                );
        }
    };
})();
