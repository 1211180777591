(function () {
    'use strict';

    /**
     * SELECT2
     */
    // jscs:disable
    const select2PositionDropdown =
        window.Select2.class.abstract.prototype.positionDropdown; // jshint ignore:line

    window.Select2.class.abstract.prototype.positionDropdown = function () {
        // jshint ignore:line
        // jscs:enable
        select2PositionDropdown.apply(this, arguments);

        const $dropdown = this.dropdown;
        const offset = this.container.offset();

        $dropdown.css({ left: offset.left });
    };

    /**
     * REDACTOR
     */

    const redactorBuildEventClipboardUpload =
        $.Redactor.prototype.buildEventClipboardUpload;

    $.Redactor.prototype.buildEventClipboardUpload = function (e) {
        this.callback('clipboardPaste');

        redactorBuildEventClipboardUpload.call(this, e);
    };

    /**
     * JQUERY UI DATEPICKER
     */

    const jqueryUiDatepickerCheckOffset = $.datepicker._checkOffset;

    $.datepicker._checkOffset = function (inst, offset, isFixed) {
        // isFixed becomes true for datespickers inside modals, which then breaks
        // the positioning - always being false seems to fix it.
        return jqueryUiDatepickerCheckOffset.call(this, inst, offset, false);
    };

    /**
     * Array.prototype.includes
     * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/includes
     */

    if (!Array.prototype.includes) {
        Object.defineProperty(Array.prototype, 'includes', {
            value: function (searchElement, fromIndex) {
                // 1. Let O be ? ToObject(this value).
                if (this === null) {
                    throw new TypeError('"this" is null or not defined');
                }

                const o = Object(this);

                // 2. Let len be ? ToLength(? Get(O, "length")).
                const len = o.length >>> 0; // jshint ignore:line

                // 3. If len is 0, return false.
                if (len === 0) {
                    return false;
                }

                // 4. Let n be ? ToInteger(fromIndex).
                //    (If fromIndex is undefined, this step produces the value 0.)
                const n = fromIndex | 0; // jshint ignore:line

                // 5. If n ≥ 0, then
                //  a. Let k be n.
                // 6. Else n < 0,
                //  a. Let k be len + n.
                //  b. If k < 0, let k be 0.
                let k = Math.max(n >= 0 ? n : len - Math.abs(n), 0);

                function sameValueZero(x, y) {
                    return (
                        x === y ||
                        (typeof x === 'number' &&
                            typeof y === 'number' &&
                            isNaN(x) &&
                            isNaN(y))
                    );
                }

                // 7. Repeat, while k < len
                while (k < len) {
                    // a. Let elementK be the result of ? Get(O, ! ToString(k)).
                    // b. If SameValueZero(searchElement, elementK) is true, return true.
                    // c. Increase k by 1.
                    if (sameValueZero(o[k], searchElement)) {
                        return true;
                    }

                    k++;
                }

                // 8. Return false
                return false;
            },
        });
    }
})();
