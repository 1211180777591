(function () {
    'use strict';

    bindhq.nsIn('wysiwyg', {
        /**
         * @param {jQuery} textarea
         * @param {String} html
         */
        setContent: function (textarea, html) {
            textarea.redactor('set', html || '');
        },

        /**
         * @param {jQuery} container
         */
        onUploadStart: function (container) {
            container.redactor('showProgressBar');
        },

        /**
         * @param {jQuery} container
         */
        onUploadSuccess: function (container) {
            container.redactor('hideProgressBar');
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            const onUploadStart = _.partial(this.onUploadStart, container);
            const onUploadSuccess = _.partial(this.onUploadSuccess, container);

            const body = $('body');
            const config = {
                convertVideoLinks: true,
                imageUpload:
                    container.data('redactor-imageupload') === undefined
                        ? body.data('redactor-imageupload')
                        : container.data('redactor-imageupload'),
                clipboardUploadUrl: body.data('redactor-clipboardupload'),
                imageUploadCallback: onUploadSuccess,
                clipboardPasteCallback: onUploadStart,
                dropCallback: onUploadStart,
                toolbar: container.data('redactor-toolbar') !== false,
                placeholder: container.data('redactor-placeholder'),
                plugins: (container.data('redactor-plugins') || '')
                    .split(',')
                    .filter((s) => s != ''),
            };

            // Support Stimulus actions
            if (container.data('action')) {
                config.changeCallback = function (event) {
                    this.$element.get(0).dispatchEvent(new Event('input'));
                };
            }

            container.redactor(config);

            // set name for jquery validate
            container.prev().attr('name', container.attr('name'));
        },
    });
})();
