(function () {
    'use strict';

    bindhq.ns('sendemail');

    bindhq.sendemail.showModal = function (url, data, template) {
        bindhq.loader.show('Loading...');
        const update = _.partial(bindhq.sendemail.updateForm, template);

        const config = {
            url: url,
            data: data,
            success: _.compose(bindhq.loader.hide, update),
            error: _.compose(bindhq.loader.hide, function (jqXHR) {
                const data = $.parseJSON(jqXHR.responseText);
                swal(data.message);
            }),
        };

        $.ajax(config);
    };

    bindhq.sendemail.getForm = function (modal, data) {
        let form;

        if (data !== undefined) {
            form = $(data);
            $('form', modal).replaceWith(form);
            bindhq.forms.init.apply(form);
        } else {
            form = $('form', modal);
        }

        return form;
    };

    bindhq.sendemail.getModal = function (template, data) {
        if ($('body > .modal').length > 0) {
            return $('body > .modal');
        } else {
            const modal = $(bindhq.util.template(template, { form: data }));
            bindhq.modalAjax.helper.destroyModalWhenClosed(modal);
            return modal;
        }
    };

    bindhq.sendemail.updateForm = function (template, data) {
        const modal = bindhq.sendemail.getModal(template, data);
        const form = bindhq.sendemail.getForm(modal, data);
        const submitButton = $('.btn-submit', modal);

        bindhq.sendemail.updateSubmitButton(modal, submitButton);

        $(modal).off('click', '.btn-submit');
        $(modal).on('click', '.btn-submit', function (e) {
            e.preventDefault();
            if (!submitButton.hasClass('disabled')) {
                $(form).submit();
            }
        });
        $(modal).on(
            'click',
            '.fileviewer-list li .delete',
            bindhq.util.noDefault(bindhq.sendemail.removeExistingFile),
        );

        // remove the error display (if it exists) when the 'to' field is updated
        if ($('#sendEmail_to', modal).hasClass('error')) {
            $('#sendEmail_to', modal).one('change', function () {
                $(this).removeClass('error');
                const container = $(this).closest('.control-group');
                container.removeClass('error');
                $('.help-inline', container).remove();
                bindhq.modalAjax.helper.removeAlert(modal);
            });
        }

        $('#sendEmail_to', modal).on('change', function (e) {
            bindhq.sendemail.updateSubmitButton(modal, submitButton);
            if (
                $('.modal-footer .alert-placeholder .alert', modal).length !== 0
            ) {
                bindhq.modalAjax.helper.removeAlert();
            }
        });

        $('#sendEmail_to', modal).on(
            'dataLoaded',
            bindhq.sendemail.addTooltipsToList,
        );

        bindhq.sendemail.ajaxForm(template, modal, form);
        $(modal).sizedModal('show');
    };

    bindhq.sendemail.addTooltipsToList = function () {
        // as select2 exists outside the form's DOM we can't add extra selectors
        // (such as modal or form).

        // removes existing tooltip element
        $('.tooltip').remove();

        _.delay(function () {
            $('li.select2-result-unselectable').tooltip({
                title: 'No email address available.<br />Please update records.',
                placement: 'left',
                html: true,
            });
        }, 100);
    };

    bindhq.sendemail.updateSubmitButton = function (modal, submitButton) {
        const emailToList = $('#sendEmail_to', modal).select2('val');
        if (emailToList.length === 0) {
            submitButton.addClass('disabled');
        } else {
            submitButton.removeClass('disabled');
        }
    };

    bindhq.sendemail.removeExistingFile = function (e) {
        const target = $(e.currentTarget);
        const files = target.closest('.documents').find('.files-list');
        const id = target.prev().data('id');

        target.closest('li').fadeAndRemove();
        files
            .find('input[value="' + id + '"]')
            .closest('.control-group')
            .remove();
    };

    bindhq.sendemail.ajaxForm = function (template, modal, form) {
        const success = _.partial(bindhq.sendemail.submitSuccess, modal);

        const error = _.partial(bindhq.sendemail.submitError, template, modal);

        const reloadWhenClosed = _.partial(
            bindhq.modalAjax.helper.reloadWhenClosed,
            modal,
        );

        const options = {
            beforeSubmit: _.partial(bindhq.sendemail.beforeSubmit, modal),
            success: _.compose(reloadWhenClosed, bindhq.loader.hide, success),
            error: _.compose(bindhq.loader.hide, error),
        };

        $(form).ajaxForm(options);
    };

    bindhq.sendemail.submitSuccess = function (modal, data) {
        bindhq.modalAjax.helper.showAlert('success', modal, data.message);
        bindhq.modalAjax.helper.lockForm(modal);
    };

    bindhq.sendemail.submitError = function (template, modal, jqXHR) {
        const errorMsg = bindhq.modalAjax.helper.getErrorMessage(
            jqXHR.responseText,
        );

        bindhq.modalAjax.helper.showAlert('error', modal, errorMsg.message);

        bindhq.sendemail.updateForm(template, errorMsg.form);
    };

    bindhq.sendemail.beforeSubmit = function (modal) {
        const emailList = $('#sendEmail_to', modal).val().split(',');
        const valid = _.filter(emailList, bindhq.util.isValidEmail);
        if (valid.length !== emailList.length) {
            bindhq.modalAjax.helper.showAlert(
                'error',
                modal,
                'Invalid email address',
            );
            return false;
        }

        bindhq.loader.show('Saving...');
        return true;
    };
})();
