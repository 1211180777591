(function () {
    'use strict';

    bindhq.nsIn('markup', {
        /**
         * @return {Boolean}
         */
        hasShadowDom: function () {
            return document.head.attachShadow;
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            const html = container.text();

            if (this.hasShadowDom()) {
                const shadow = container
                    .get(0)
                    .attachShadow({ mode: 'closed' });

                const element = document.createElement('div');
                element.innerHTML = html;

                shadow.appendChild(element);
            } else {
                container.html(container.text());
            }
        },
    });
})();
