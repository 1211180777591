(function () {
    'use strict';

    /* Url: secure/reports/itc-noc */

    bindhq.ns('forms.itcNocReport');

    bindhq.forms.itcNocReport.initContainer = function ($container) {
        const $datePickers = $container.find('.datepicker');

        const datepickerApi = $($datePickers).data('datepickerApi');

        $container.on('submit', function (e) {
            if (!datepickerApi.isDatePickerValid()) {
                swal('You must enter a due date!');
                e.preventDefault();
            }
        });
    };
})();
