(function () {
    'use strict';

    bindhq.nsIn('quiz', {
        completeQuiz: function (container, evt) {
            evt.preventDefault();

            if (confirm('Are you sure?')) {
                const form = $('#quiz-form', container);
                const validateQuiz = this.validateQuiz(container);

                const config = {
                    beforeSubmit: validateQuiz,
                    success: function (data) {
                        $(data).sizedModal();
                    },
                };
                $(form).ajaxSubmit(config);
            }
        },

        validateQuiz: function (container) {
            const items = $('.quiz-question form', container);
            let hits = 0;

            _.each(items, function (item) {
                const selected = $('input:checked', item).val();
                const solution = $('input[name="res"]', item).val();

                if (selected !== undefined) {
                    if (selected === solution) {
                        $(item).parents('.quiz-question').addClass('success');
                        hits++;
                    } else {
                        $(item).parents('.quiz-question').addClass('fail');
                    }
                } else {
                    $(item).parents('.quiz-question').addClass('fail');
                }
            });

            $('#quiz-form input', container).val(hits);
        },

        /**
         * @param {jQuery} container
         */
        init: function (container) {
            const onValidate = _.partial(this.completeQuiz, container);

            container.on('submit', '#quiz-form', onValidate);
        },
    });
})();
