(function () {
    'use strict';

    bindhq.ns('itcnoc');

    bindhq.itcnoc.init = function () {
        const config = {
            keepOpenOnSuccess: true,
            success: function () {
                location.reload();
            },
        };

        $(this).modalAjax(config);
    };
})();
