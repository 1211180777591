const progress = require('../../../../../../../assets/js/BindHQ/Utils/Progress');

(function () {
    'use strict';

    bindhq.ns('loader');

    /**
     * @param {jQuery} anchor
     */
    bindhq.loader.start = function (anchor) {
        if (anchor) {
            anchor.css({ 'pointer-events': 'none' });
        }

        progress.start();
    };

    /**
     * @param {jQuery} anchor
     */
    bindhq.loader.stop = function (anchor) {
        if (anchor) {
            anchor.css({ 'pointer-events': 'auto' });
        }

        progress.stop();
    };

    /**
     * Hide the loader
     */
    bindhq.loader.hide = function () {
        progress.stop();
    };

    /**
     * Show the loader
     */
    bindhq.loader.show = function () {
        progress.start();
    };

    /**
     * Show the loader
     */
    bindhq.loader.showPartial = function (container) {
        progress.start();
    };

    /**
     * Hide the loader
     */
    bindhq.loader.hidePartial = function (container) {
        progress.stop();
    };
})();
