/**
 * Custom jQuery functionality which is specific to BindHQ
 */
(function () {
    'use strict';

    /**
     * @return {Integer}
     */
    $.fn.size = function () {
        return this.length;
    };

    /**
     * @param {Object} options
     */
    $.fn.loadContent = function (options) {
        const source = this;
        const config = {
            url: options.url,
            data: options.data || {},
            dataType: options.dataType || 'json',
            type: 'GET',
            success: options.success,
            error: options.error,
            complete: function () {
                source.progress('stop');
            },
        };

        source.progress('start');

        $.ajax(config);
    };

    /**
     * Toggle the read-only status of a field
     */
    $.fn.toggleReadOnly = function () {
        const ro = 'readonly';
        const func = this.attr(ro) ? 'removeAttr' : 'attr';

        this[func](ro, ro);
    };

    /**
     * Fade an element out then remove it
     *
     * @param {Function} callback
     *
     * @return {jQuery}
     */
    $.fn.fadeAndRemove = function (callback) {
        return this.fadeOut(function () {
            $(this).remove();

            if (callback) {
                callback();
            }
        });
    };

    /**
     * Fade an element out then remove it after a certain time
     *
     * @return {jQuery}
     */
    $.fn.fadeAndRemoveTimed = function (time) {
        const self = $(this);
        return setTimeout(function () {
            self.fadeAndRemove();
        }, time);
    };

    /**
     * On element click toggles the class on and off
     *
     * @param {String} cls
     *
     * @return {jQuery}
     */
    $.fn.toggleClassOnClick = function (cls) {
        return this.click(_.bind($.fn.toggleClass, this, cls));
    };

    /**
     * On click, toggle the original html with this new string
     *
     * @param {String} html
     *
     * @return {jQuery}
     */
    $.fn.toggleHtmlOnClick = function (html) {
        const orig = this.html();
        const onClick = function () {
            const item = $(this);
            const curr = item.html();
            item.html(curr === orig ? html : orig);
        };

        return this.click(onClick);
    };

    /**
     * @param {String} x
     * @param {String} y
     *
     * @return {jQuery}
     */
    $.fn.textToggle = function (x, y) {
        if (this.text() === x) {
            return this.text(y);
        }

        return this.text(x);
    };

    /**
     * Transition one element out, and another in when its done
     *
     * @param {jQuery} other
     *
     * @return {jQuery}
     */
    $.fn.transitionTo = function (other) {
        return this.fadeOut(function () {
            other.fadeIn();
        });
    };

    /**
     * POST a form via ajax, options overrides $.ajax defaults
     *
     * @param {Object} options
     *
     * @return {jQuery}
     */
    $.fn.postForm = function (options) {
        const defaults = {
            url: this.attr('action'),
            type: 'POST',
            dataType: 'html',
            data: this.serialize(),
        };

        $.ajax($.extend(defaults, options));

        return this;
    };

    /**
     * @param {String} selector
     *
     * @return {jQuery}
     */
    $.fn.findOne = function (selector) {
        const results = this.find(selector);

        if (results.length !== 1) {
            throw new Error(
                'Expected exactly one element for ' +
                    selector +
                    ', got: ' +
                    results.length,
            );
        }

        return results;
    };

    /**
     * Create a bootstrap modal, using the specified config, and try and
     * size it vertically to best fit in the window.
     *
     * @param {Object} config
     *
     * @return {jQuery}
     */
    $.fn.sizedModal = function (config) {
        const winHeight = $(window).height();
        const heightProperty = this.hasClass('modal-panel')
            ? 'height'
            : 'max-height';

        return this.modal(config)
            .css({ top: '270px' })
            .find('.modal-body')
            .css(heightProperty, winHeight - 230 + 'px');
    };

    /**
     * Append the value to the element
     *
     * @param {String} data
     *
     * @return {jQuery}
     */
    $.fn.appendVal = function (data) {
        return this.val(this.val() + data);
    };

    /**
     * Toggle an attribute on an element
     *
     * @param {String} name
     * @param {String} value
     *
     * @return {jQuery}
     */
    $.fn.toggleAttr = function (name, value) {
        if (this.attr(name)) {
            this.removeAttr(name);
        } else {
            this.attr(name, value);
        }
        return this;
    };

    /**
     * Prevent click propogation from the element
     *
     * @return {jQuery}
     */
    $.fn.preventClicks = function () {
        return this.click(function (e) {
            e.stopPropagation();
        });
    };

    /**
     * Prevent enter submitting a form field
     *
     * @return {jQuery}
     */
    $.fn.disableEnterKey = function () {
        return this.keydown(function (evt) {
            if (evt.keyCode === 13) {
                evt.preventDefault();
                return false;
            }
        });
    };

    /**
     * Init each matched element as a container
     *
     * @param {Function} initer
     *
     * @return {jQuery}
     */
    $.fn.initEach = function (initer) {
        return this.each(function () {
            const container = $(this);
            initer(container);
        });
    };

    /**
     * @param {double} amount
     *
     * @return {jQuery}
     */
    $.fn.currency = function (amount) {
        return this.html('$' + amount);
    };

    /**
     * @param {String} className
     *
     * @return {jQuery}
     */
    $.fn.addTimedClass = function (className, timeoutMs) {
        const self = this;
        const hideClass = function () {
            self.removeClass(className);
        };

        setTimeout(hideClass, timeoutMs || 2000);

        return this.addClass(className);
    };

    /**
     * @param {Object} options
     *
     * Options:
     *
     *  - lockOnSuccess: bool (default: true) Should the form be locked while submitting?
     *  - success: Function (default: null) Callback on successful form submission
     *  - error: Function (default: null) Callback on failure to submit form
     *  - onBeforeSubmit: Function (default: null) Callback to run before form is submitted
     *
     * @return {jQuery}
     */
    $.fn.formAjax = function (options) {
        options = options || {};

        const container = this;
        const submit = $('input[type=submit]', container);

        const lock = function () {
            bindhq.modalAjax.helper.lockForm(container);
            submit.progress('start');
        };

        const unlock = function () {
            container.removeData('submitting');
            submit.progress('stop');
            bindhq.modalAjax.helper.unlockForm(container);
        };

        const onSuccess = function (response, _, xhr) {
            if (!options.lockOnSuccess) {
                unlock();
            }
            if (options.success) {
                options.success(response, xhr);
            }
        };

        const errorsFrom = function (info) {
            if (info) {
                if (info.message) {
                    return [info.message];
                }

                if (info.errors) {
                    return info.errors;
                }
            }

            return ['Server error'];
        };

        const onError = function (error) {
            const info = bindhq.util.jsonParse(error.responseText);

            unlock();

            if (info) {
                const errors = errorsFrom(info);

                $('.alert-placeholder', container).html(
                    '<div class="alert alert-error">' +
                        errors.join(', ') +
                        '</div>',
                );

                if (options.error) {
                    options.error(errors);
                }
            } else {
                if (options.error) {
                    options.error(error);
                }
            }
        };

        let onBeforeSubmit = function () {
            if (!container.valid()) {
                return false;
            }

            if (container.data('submitting') === true) {
                return false;
            } else {
                container.data('submitting', true);
            }

            lock();
        };

        if (options.onBeforeSubmit) {
            onBeforeSubmit = (function (onBeforeSubmit) {
                return function () {
                    const optionalSubmit = options.onBeforeSubmit();
                    if (optionalSubmit === false) {
                        return false;
                    }
                    const beforeSubmit = onBeforeSubmit();
                    if (beforeSubmit) {
                        return false;
                    }
                };
            })(onBeforeSubmit);
        }

        const config = {
            beforeSubmit: onBeforeSubmit,
            success: onSuccess,
            error: onError,
        };

        return container.ajaxForm(config);
    };

    /**
     * start/stop button progress meter
     *
     * @param {String} method
     *
     * @return {jQuery}
     */
    $.fn.progress = function (method, options) {
        return this.each(function () {
            bindhq.progress.apply($(this), [method, options]);
        });
    };

    /**
     * @param {Object} options
     *
     * @return {jQuery}
     */
    $.fn.modalAjax = function (options) {
        options = options || {};

        const container = this;

        const onSuccess = function (response, _, xhr) {
            if (!options.keepOpenOnSuccess) {
                container.closest('.modal').modal('hide');

                $('body')
                    .removeClass('modal-open')
                    .find('.modal-backdrop')
                    .remove();
            }

            if (options.success) {
                options.success(response, xhr);
            }
        };

        const onError = function (errors) {
            if (options.error) {
                options.error(errors);
            }
        };

        const config = {
            success: onSuccess,
            error: onError,
        };

        return container.formAjax(config);
    };
})();
