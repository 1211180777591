(function () {
    'use strict';

    bindhq.nsIn('social', {
        /**
         * @param {jQuery} wysiwyg
         */
        onPhotoClick: function (wysiwyg) {
            wysiwyg.redactor('imageShow');
        },

        /**
         * @param {jQuery} wysiwyg
         */
        onVideoClick: function (wysiwyg) {
            wysiwyg.redactor('videoShow');
        },

        /**
         * @param {jQuery.Event} evt
         */
        onLikeClick: function (evt) {
            const anchor = $(evt.currentTarget);
            const isLiked = anchor.hasClass('liked');
            const count = $('.count', anchor);
            const liked = parseInt(count.text(), 10);
            const url = isLiked
                ? anchor.data('like-remove-url')
                : anchor.data('like-add-url');

            count.text(isLiked ? liked - 1 : liked + 1);

            $.post(url);

            anchor.toggleClass('liked');
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            const wysiwyg = $('.wysiwyg', container);
            const onPhotoClick = _.partial(this.onPhotoClick, wysiwyg);
            const onVideoClick = _.partial(this.onVideoClick, wysiwyg);

            container.on(
                'click',
                '.actions .photo',
                bindhq.util.noDefault(onPhotoClick),
            );
            container.on(
                'click',
                '.actions .video',
                bindhq.util.noDefault(onVideoClick),
            );
            container.on(
                'click',
                '.likes a',
                bindhq.util.noDefault(this.onLikeClick),
            );
        },
    });
})();
