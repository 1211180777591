(function () {
    'use strict';

    /**
     * @param {jQuery} element
     *
     * @return {Object}
     */
    function makeItem(element) {
        return {
            id: element.data('transaction-id'),
            amount: {
                amount: element.data('transaction-amount'),
                currency: element.data('transaction-currency'),
            },
        };
    }

    bindhq.nsIn('abacus.achBatch', {
        /**
         * @param {ACHBatchChangeset} changeset
         * @param {jQuery} container
         */
        applyChangeset: function (changeset, container) {
            $('.pagination-item', container).each(function () {
                const input = $('.transaction-checkbox', this);
                const item = makeItem(input);

                if (changeset.isAdded(item)) {
                    input.prop('checked', true);
                } else if (changeset.isRemoved(item)) {
                    input.prop('checked', false);
                }
            });
        },

        /**
         * @param {ACHBatchChangeset} changeset
         * @param {Event} evt
         */
        onCheckboxClick: function (changeset, evt) {
            const input = $(evt.currentTarget);
            const item = makeItem(input);

            if (input.prop('checked')) {
                changeset.add(item);
            } else {
                changeset.remove(item);
            }
        },

        /**
         * @param {ACHBatchChangeset} changeset
         * @param {jQuery} container
         */
        onPageLoaded: function (changeset, container) {
            this.applyChangeset(changeset, container);
        },

        /**
         * @param {ACHBatchChangeset} changeset
         * @param {jQuery} container
         */
        onSubmit: function (changeset, container) {
            const append = function (index, name, value) {
                $('<input />')
                    .attr('type', 'hidden')
                    .attr('name', 'operations[' + index + '][' + name + ']')
                    .attr('value', value)
                    .appendTo(container);
            };

            _.forEach(changeset.getChanges(), function (change, index) {
                append(index, 'id', change.id);
                append(index, 'type', change.type);
                append(index, 'amount', change.amount.amount);
                append(index, 'currency', change.amount.currency);
            });

            return true;
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            const operations = container.data('operations');
            const changeset =
                bindhq.abacus.achBatchChangeset.create(operations);

            this.applyChangeset(changeset, container);

            container.on(
                'click',
                '.transaction-checkbox',
                _.partial(this.onCheckboxClick, changeset),
            );

            container.on(
                'pagination:dataLoaded',
                _.partial(this.onPageLoaded, changeset, container),
            );

            container.on(
                'submit',
                _.partial(this.onSubmit, changeset, container),
            );
        },
    });

    $('[data-js-hook="ach-batch"]').initEach(
        bindhq.abacus.achBatch.initContainer,
    );
})();
