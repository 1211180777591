(function () {
    'use strict';

    bindhq.ns('dnd');

    bindhq.dnd.hideHover = function () {
        $('body').removeClass('dnd-hover');
    };

    bindhq.dnd.showHover = function (evt) {
        const body = $('body');

        if (!body.hasClass('dnd-dragout')) {
            body.addClass('dnd-hover');
        }

        // prevent dnd source "moving" files, eg. Outlook
        evt.dataTransfer.dropEffect = 'copy';
    };

    bindhq.dnd.onDragInOver = bindhq.dnd.showHover;

    bindhq.dnd.onDragInOut = bindhq.dnd.hideHover;

    /**
     * @param {jQuery} dropzone
     *
     * @return {Function|Boolean}
     */
    bindhq.dnd.onComplete = function (dropzone) {
        const refreshTabs = dropzone.data('refreshtabs');

        if (refreshTabs) {
            return function () {
                const tabIds = refreshTabs.split(',');

                bindhq.loader.hide();
                bindhq.tabs.refreshAll(tabIds);
            };
        }

        return false;
    };

    /**
     * Files have been dropped on a dropzone, upload them.
     *
     * @param {Event} evt
     */
    bindhq.dnd.onDropIn = function (evt) {
        bindhq.dnd.hideHover();

        const files = evt.target.files || evt.dataTransfer.files;
        const dropzone = $(evt.currentTarget);
        const dropzoneWrapper = dropzone.closest('.dnd-dropzone-wrapper');
        const modalAttachFiles = $('.modal-attach-files', dropzoneWrapper);
        const modal = modalAttachFiles.closest('.modal');
        const container = dropzone.hasClass('files')
            ? dropzone
            : $('.files:first', dropzone);

        if (files.length === 1 && modal.length) {
            bindhq.forms.files.onFileDrop(container, dropzone, files[0], modal);
        } else {
            _.each(files, function (file) {
                const loader = bindhq.files
                    .loaderFor(container, file.name)
                    .appendTo(container);

                const config = {
                    action: dropzone.data('uploadurl'),
                    owners: dropzone.data('owners'),
                    tags: dropzone.data('tags'),
                    template: dropzone.data('template'),
                    complete: function (xhr) {
                        bindhq.files.uploadComplete(loader, xhr.responseText);
                    },
                };

                bindhq.files.uploadFile(config, file);
            });
        }
    };

    bindhq.dnd.initContainer = function (container) {
        if (bindhq.dnd.isSupported()) {
            const handler = bindhq.util.noDefault;

            $('.dnd-dropzone', container).each(function (index, dropzone) {
                dropzone.addEventListener(
                    'drop',
                    handler(bindhq.dnd.onDropIn),
                    false,
                );
            });
        }
    };

    /**
     * Initialize drop into dropzones
     *
     */
    bindhq.dnd.initDrop = function () {
        if (bindhq.dnd.isSupported()) {
            const handler = bindhq.util.noDefault;

            document.addEventListener(
                'dragover',
                handler(bindhq.dnd.onDragInOver),
                false,
            );
            document.addEventListener(
                'dragleave',
                handler(bindhq.dnd.onDragInOut),
                false,
            );
        }
    };

    /**
     * @return {boolean}
     */
    bindhq.dnd.isSupported = function () {
        if (window.File && window.FileList && window.FileReader) {
            const xhr = new XMLHttpRequest();
            if (xhr.upload) {
                return true;
            }
        }
    };

    /**
     * Drag-out to desktop has started
     *
     * @param {Event} evt
     */
    bindhq.dnd.onDragOutStart = function (evt) {
        const fileDetails = $(evt.currentTarget).data('downloadurl');

        $('body').addClass('dnd-dragout');

        evt.dataTransfer.setData('DownloadURL', fileDetails);
    };

    /**
     * Drag-out to desktop has stopped
     *
     */
    bindhq.dnd.onDragOutStop = function () {
        $('body').removeClass('dnd-dragout');
    };

    /**
     * Initialise an element for drag-out
     *
     * @param {Number} index
     * @param {DOMElement} element
     */
    bindhq.dnd.initFileDrag = function (index, element) {
        element.addEventListener('dragstart', bindhq.dnd.onDragOutStart);

        element.addEventListener('dragend', bindhq.dnd.onDragOutStop);
    };

    /**
     * Initialise drag-out to desktop
     */
    bindhq.dnd.initDrag = function () {
        $('.dnd-desktop').each(bindhq.dnd.initFileDrag);
    };

    /**
     * Initialise all drag in/out behaviour
     */
    bindhq.dnd.init = function () {
        bindhq.dnd.initDrop();
        bindhq.dnd.initDrag();
    };
})();
