const numeral = require('numeral');

(function () {
    'use strict';

    bindhq.nsIn('abacus.deposit', {
        /**
         * @param {String}
         */
        DISPLAY_FORMAT: '(0,0.00)',

        /**
         * @param {jQuery} container
         * @param {jQuery.Event} evt
         */
        onSelectAllClick: function (container, evt) {
            const checkbox = $(evt.currentTarget);

            $(
                'tbody tr:not(.hidden-user, .hidden-division) [type=checkbox]',
            ).prop('checked', checkbox.prop('checked'));

            this.updateSummary(container);
        },

        /**
         * @param {jQuery} container
         * @param {jQuery.Event} evt
         */
        onCheckboxClick: function (container, evt) {
            $('.select-all', container).prop('checked', false);

            this.updateSummary(container);
        },

        /**
         * @param {jQuery} container
         */
        onDivisionChange: function (container) {
            const divisionId = $('.division.select2', container).select2('val');

            $(
                '.deposit-payment[data-division="' + divisionId + '"]',
                container,
            ).each(function () {
                $(this)
                    .removeClass('hidden-division')
                    .find('input')
                    .prop('disabled', false);
            });

            $(
                '.deposit-payment[data-division!="' + divisionId + '"]',
                container,
            ).each(function () {
                $(this)
                    .addClass('hidden-division')
                    .find('input')
                    .prop('disabled', true);
            });

            this.updateSummary(container);
        },

        /**
         * @param {jQuery} container
         */
        onUserChange: function (container) {
            const userId = $('.user-filter.select2', container).select2('val');

            if (userId) {
                $(
                    '.deposit-payment[data-user="' + userId + '"]',
                    container,
                ).each(function () {
                    $(this).removeClass('hidden-user');
                });

                $(
                    '.deposit-payment[data-user!="' + userId + '"]',
                    container,
                ).each(function () {
                    $(this).addClass('hidden-user');
                });
            } else {
                $('.deposit-payment', container).each(function () {
                    $(this).removeClass('hidden-user');
                });
            }

            container.trigger($.Event('table-listing.filtered', {}));
        },

        /**
         * @param {jQuery} container
         */
        initTable: function (container) {
            $('.tablesorter', container).tablesorter({
                sortList: [[4, 0]],
            });
        },

        /**
         * @param {jQuery} container
         */
        updateSummary: function (container) {
            const inputTotal = $('.amount-applied', container);
            const inputSelectItems = $('.selected-items', container);

            const items = $('.deposit-payment:not(.hidden)', container);
            const selectedItems = _.filter(items, function (item) {
                return $('input', item).prop('checked');
            });
            const itemsNum = items.length;
            const selectedItemsNum = selectedItems.length;
            const totalApplied = _.reduce(
                selectedItems,
                function (acc, item) {
                    const value = $('.payment-amount', item).data('value');
                    const amount = numeral(value);

                    return acc.add(amount);
                },
                numeral(0),
            );

            inputTotal.text(totalApplied.format(this.DISPLAY_FORMAT));
            inputSelectItems.text(selectedItemsNum + ' of ' + itemsNum);
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            const onSelectAllClick = _.partial(
                this.onSelectAllClick,
                container,
            );
            const onCheckboxClick = _.partial(this.onCheckboxClick, container);
            const onDivisionChange = _.partial(
                this.onDivisionChange,
                container,
            );
            const onUserChange = _.partial(this.onUserChange, container);

            container.on('click', '.select-all', onSelectAllClick);
            container.on('click', 'tbody [type=checkbox]', onCheckboxClick);
            container.on('change', '.division', onDivisionChange);
            container.on('change', '.user-filter', onUserChange);

            onDivisionChange();

            this.initTable(container);
            this.updateSummary(container);
        },
    });
})();
