(function () {
    'use strict';

    bindhq.nsIn('checklist', {
        /**
         * @param {jQuery} item
         * @param {String} html
         */
        onSuccess: function (item, html) {
            item.html(html);
        },

        /**
         * @param {jQuery} item
         */
        onComplete: function (item) {
            item.removeClass('saving');
        },

        /**
         * @param {jQuery} container
         * @param {jQuery.Event} evt
         */
        onClick: function (container, evt) {
            const anchor = $(evt.currentTarget);
            const item = anchor.closest('.item');
            const config = {
                url: anchor.attr('href'),
                type: 'POST',
                dataType: 'html',
                data: {
                    forType: container.data('checklist-for-type'),
                    forId: container.data('checklist-for-id'),
                    value: anchor.data('checklist-value'),
                },
                success: _.partial(this.onSuccess, item),
                complete: _.partial(this.onComplete, item),
            };

            item.addClass('saving');

            $.ajax(config);
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            const onClick = _.partial(this.onClick, container);

            container.on(
                'click',
                '[data-checklist-value]',
                bindhq.util.noDefault(onClick),
            );
        },
    });
})();
