(function () {
    ('use strict');

    bindhq.ns('tasks');

    bindhq.tasks.bindCloseCurrentModal = function () {
        $(document).on('shown.bs.modal', '.modal', function () {
            if ($(this).children('.modal-task-new').length) {
                $('.modal-tasks').parent().modal('hide');
            }
        });
    };

    /**
     *
     */
    bindhq.tasks.init = function () {
        bindhq.tasks.bindCloseCurrentModal();
    };
})();
