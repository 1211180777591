(function () {
    'use strict';

    bindhq.nsIn('policies.formSet', {
        /**
         * @param {jQuery} container
         */
        onSelectedTemplateChange: function (container) {
            $('.formset-viewer-form', container).submit();

            bindhq.loader.show();
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (element) {
            const container = element.closest('.formset-viewer-container');
            const templateSelector = $(
                'select.formset-viewer-selected-template',
                container,
            );
            const onSelectedTemplateChange = _.partial(
                this.onSelectedTemplateChange,
                container,
            );

            if (templateSelector.length === 1) {
                templateSelector.change(onSelectedTemplateChange);

                $('[name="formset-viewer-display"]', container).on(
                    'load',
                    function () {
                        bindhq.loader.hide();
                    },
                );

                onSelectedTemplateChange();
            }
        },
    });
})();
