(function () {
    'use strict';

    /**
     * Data attributes on canvas:
     *
     *  // frame width/height can be used to scale frames to canvas size
     *  frame-width (defaults to canvas width)
     *  frame-height (defaults to canvas height)
     *
     *  // number of images in x/y axis
     *  x-count
     *  y-count
     *
     *  // maximum number of frames (if sprite sheet contains blanks at the end)
     *  max-frames (defaults to 0, drawing all frames)
     *
     *  // refresh interval to control speed of animation
     *  speed (defaults to 50ms)
     *
     */
    bindhq.nsIn('animator', {
        initContainer: function (canvas) {
            const ctx = canvas.get(0).getContext('2d');
            const sprite = new Image();
            let x = 0;
            let y = 0;

            const border = canvas.data('border');
            const width = canvas.attr('width');
            const height = canvas.attr('height');
            const frameWidth = canvas.data('frame-width') || width;
            const frameHeight = canvas.data('frame-height') || height;
            const xCount = canvas.data('x-count');
            const yCount = canvas.data('y-count');
            const maxFrames = canvas.data('max-frames') || 0;
            const speed = canvas.data('speed') || 50;

            function pos(size, factor) {
                return size * factor + border * (factor + 1);
            }

            function tick() {
                x++;

                if (x === xCount) {
                    x = 0;
                    y++;
                }

                if (y === yCount) {
                    y = 0;
                }
            }

            function paint() {
                const xPos = pos(frameWidth, x);
                const yPos = pos(frameHeight, y);
                const onFrame = x + y * xCount;

                if (!maxFrames || onFrame <= maxFrames) {
                    ctx.clearRect(0, 0, width, height);

                    ctx.drawImage(
                        sprite,
                        xPos,
                        yPos,
                        frameWidth,
                        frameHeight,
                        0,
                        0,
                        width,
                        height,
                    );
                }
            }

            function load() {
                sprite.onload = function () {
                    setInterval(paint, speed);
                    setInterval(tick, speed);
                };
                sprite.src = canvas.data('url');
            }

            load();
        },
    });
})();
