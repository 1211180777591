(function () {
    'use strict';

    bindhq.nsIn('forms.fileDelete', {
        /**
         * @param {jQuery} container
         */
        onFileDeleted: function (container) {
            $('body').trigger($.Event('file_deleted'));
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            const config = {
                success: _.partial(this.onFileDeleted, container),
            };

            container.modalAjax(config);
        },
    });
})();
