const numeral = require('numeral');

(function () {
    'use strict';

    bindhq.nsIn('abacus.journal', {
        /**
         * @param {String}
         */
        INPUT_FORMAT: '0.00',

        /**
         * @param {jQuery} container
         * @param {jQuery.Event} evt
         */
        onTypeAmount: function (container, evt) {
            const input = evt.target;
            const submitBtn = $('.row-submit .btn-primary', container);
            const submitMsg = $('.row-submit .submit-message', container);

            submitBtn.prop('disabled', false);
            submitMsg.text('');

            const complement = $(input)
                .closest('td')
                .siblings('.journal-amount')
                .find('input');
            const emptyValue = numeral(0);

            complement.val(emptyValue.format(this.INPUT_FORMAT));

            const credits = $('.journal-amount-credit input', container);
            const debits = $('.journal-amount-debit input', container);

            const totalCredit = this.calculateEntriesTotal(credits);
            const totalDebit = this.calculateEntriesTotal(debits);

            if (this.isDifferent(totalCredit, totalDebit)) {
                submitMsg.text('The amounts in a journal items must balance.');
                submitBtn.prop('disabled', true);
            }
        },

        /**
         * @param {Numeral} x
         * @param {Numeral} y
         *
         * @return {Boolean}
         */
        isDifferent: function (x, y) {
            return numeral(x.difference(y).toFixed(2)).value() !== 0;
        },

        /**
         * @param {jQuery} entries
         *
         * @return {Numeral}
         */
        calculateEntriesTotal: function (entries) {
            const toTotal = function (acc, field) {
                const amount = numeral(field.value);

                return acc.add(amount);
            };

            return _.reduce(entries, toTotal, numeral(0));
        },

        /**
         * @param {jQuery.Event} evt
         */
        onCustomerChange: function (evt) {
            const source = $(evt.target);
            const policy = source
                .closest('tr')
                .find('[data-schedule="policies"]');

            if (policy) {
                const customerId = source.select2('val') || '';
                const regex = /([^\d]+)(\d+)/g;
                const match = regex.exec(customerId);

                policy.select2('val', null, true).prop('disabled', true);

                if (match === null) {
                    return;
                }

                const entityType = match[1];
                const entityId = match[2];

                switch (entityType) {
                    case 'A':
                        policy
                            .data('filter', { 'agency-id': entityId })
                            .prop('disabled', false);
                        break;

                    case 'C':
                        policy
                            .data('filter', {
                                'marketing-company-id': entityId,
                            })
                            .prop('disabled', false);
                        break;

                    case 'INSURED':
                        policy
                            .data('filter', { 'insured-id': entityId })
                            .prop('disabled', false);
                        break;

                    case 'SUPPLIER':
                    case 'TAXAUTHORITY':
                        policy.data('filter', {}).prop('disabled', false);
                        break;

                    default:
                        policy.data('filter', {});
                }
            }
        },

        /**
         * @param {jQuery} container
         */
        initContainer: function (container) {
            const keyTab = 9;
            const onTypeAmount = _.partial(this.onTypeAmount, container);
            const onChange = _.debounce(function (evt) {
                if (keyTab !== evt.keyCode) {
                    onTypeAmount.apply(null, arguments);
                }
            }, 100);

            container.on('keyup', '.journal-amount', onChange);
            container.on('change', '.journal-amount', onChange);
            container.on(
                'change',
                '.abacus-customer-select',
                this.onCustomerChange,
            );
        },
    });
})();
